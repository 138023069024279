var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Pane, Button, Row, Col, PaneFooter, Selection, } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useMutation } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormattedMessage, useIntl } from "react-intl";
import { fetchCurrentUser, selectOrganization } from "../../api/usersService";
import { selectOrganizationSchema } from "../../utils/validationUtils";
import { RecekPaneSet } from "../../components/RecekPaneSet";
function SelectOrganization() {
    var _this = this;
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var _a = useState([]), organizations = _a[0], setOrganizations = _a[1];
    var _b = useState(false), shouldChangePassword = _b[0], setShouldChangePassword = _b[1];
    var _c = useForm({
        resolver: zodResolver(selectOrganizationSchema(intl)),
        defaultValues: { orgSelection: "" },
        mode: "onBlur",
    }), control = _c.control, handleSubmit = _c.handleSubmit, setValue = _c.setValue, watch = _c.watch;
    var orgSelection = watch("orgSelection");
    var hasFetched = useRef(false);
    useEffect(function () {
        if (hasFetched.current)
            return;
        var fetchActiveOrganizations = function () { return __awaiter(_this, void 0, void 0, function () {
            var fetchedUsers, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetchCurrentUser(ky)];
                    case 1:
                        fetchedUsers = _b.sent();
                        if (fetchedUsers.activeOrganization) {
                            setValue("orgSelection", fetchedUsers.activeOrganization.id);
                        }
                        if (fetchedUsers) {
                            setShouldChangePassword((_a = fetchedUsers.user) === null || _a === void 0 ? void 0 : _a.shouldChangePassword);
                            setOrganizations(fetchedUsers.userOrganizations || []);
                        }
                        hasFetched.current = true;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error("Error fetching organizations:", error_1);
                        setOrganizations([]);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchActiveOrganizations();
    }, [ky, setValue]);
    var createOrganizationLabel = function (org) {
        var source = intl.formatMessage({
            id: "ui-br-users.organization.source.".concat(org.source),
        });
        return "".concat(org.name, " | ").concat(source);
    };
    var mutateSelectOrganization = useMutation(function (data) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, selectOrganization(ky, data.orgSelection)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, {
        onSuccess: function () {
            if (shouldChangePassword) {
                history.push("/brusers/my-profile/password");
            }
            else {
                history.push("/brusers/my-profile");
            }
        },
        onError: function (error) {
            console.error("Failed to select organization:", error);
        },
    }).mutateAsync;
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    sessionStorage.removeItem("activeRoles");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, mutateSelectOrganization(data)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error("Error selecting organization:", error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Vybrat Organizaci", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", disabled: !orgSelection, marginBottom0: true, onClick: handleSubmit(onSubmit) },
                    React.createElement(FormattedMessage, { id: "stripes-core.button.save" })), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, fullWidth: true },
                    React.createElement(FormattedMessage, { id: "stripes-core.button.back" })) }) },
            React.createElement("form", { id: "organizationForm", onSubmit: handleSubmit(onSubmit) },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 },
                        React.createElement(Controller, { name: "orgSelection", control: control, render: function (_a) {
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(Selection, { label: React.createElement(FormattedMessage, { id: "ui-br-users.label.organization" }), name: "orgSelection", value: field.value, onChange: field.onChange, dataOptions: (organizations || []).map(function (org) { return ({
                                        value: org.id,
                                        label: createOrganizationLabel(org),
                                    }); }), error: error === null || error === void 0 ? void 0 : error.message }));
                            } })))))));
}
export default SelectOrganization;
