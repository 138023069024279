import { Row, Col } from "@folio/stripes/components";
import React from "react";
export var SourceAccordionContent = function (_a) {
    var source = _a.source;
    return (React.createElement(Row, null,
        React.createElement(Col, { xs: 12, md: 6 },
            React.createElement("div", { style: { marginBottom: "5px" } },
                React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, "Zdroj")),
            React.createElement("div", null, source || "N/A"))));
};
