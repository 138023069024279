var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, AccordionSet, Accordion, TextField, IconButton, Row, Col, PaneFooter, ErrorModal, Modal, MenuSection, ConfirmationModal, } from "@folio/stripes/components";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormattedMessage, useIntl } from "react-intl";
import { upperFirst } from "lodash";
import NavComponent from "../../components/NavComponent";
import { fetchCurrentUser, fetchMyProfile, updateMyProfile, } from "../../api/usersService";
import RecekTextField from "../../components/RecekTextField";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { profileSchema } from "../../utils/validationUtils";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { getRolesTranslations } from "../../utils/getRolesTranslations";
var MyProfileDetail = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var activeRoles = useActiveRoles(ky);
    var _a = useState(false), confirmModalOpen = _a[0], setConfirmModalOpen = _a[1];
    var _b = useState(false), errorModalOpen = _b[0], setErrorModalOpen = _b[1];
    var _c = useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(false), successModalOpen = _d[0], setSuccessModalOpen = _d[1];
    var _e = useState(""), successMessage = _e[0], setSuccessMessage = _e[1];
    var _f = useState(true), filterPaneIsVisible = _f[0], setFilterPaneIsVisible = _f[1];
    var _g = useState(true), expandAll = _g[0], setExpandAll = _g[1];
    var _h = useState({
        organization: true,
        profile: true,
    }), accordionStatus = _h[0], setAccordionStatus = _h[1];
    var _j = useState(null), userId = _j[0], setUserId = _j[1];
    var _k = useState(false), showSelect = _k[0], setShowSelect = _k[1];
    var isFetchingUserId = useRef(false);
    var _l = useForm({
        resolver: zodResolver(profileSchema(intl)),
        defaultValues: {
            version: 0,
            firstName: "",
            lastName: "",
            email: "",
            organization: "",
            roles: [],
        },
        mode: "onBlur",
    }), control = _l.control, handleSubmit = _l.handleSubmit, reset = _l.reset, errors = _l.formState.errors;
    useEffect(function () {
        var fetchUserDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
            var currentUserResponse, userDetailsResponse, error_1;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            return __generator(this, function (_r) {
                switch (_r.label) {
                    case 0:
                        if (isFetchingUserId.current)
                            return [2 /*return*/];
                        _r.label = 1;
                    case 1:
                        _r.trys.push([1, 5, 6, 7]);
                        isFetchingUserId.current = true;
                        return [4 /*yield*/, fetchCurrentUser(ky)];
                    case 2:
                        currentUserResponse = _r.sent();
                        if (!((_a = currentUserResponse === null || currentUserResponse === void 0 ? void 0 : currentUserResponse.user) === null || _a === void 0 ? void 0 : _a.id)) return [3 /*break*/, 4];
                        setUserId(currentUserResponse.user.id);
                        return [4 /*yield*/, fetchMyProfile(ky)];
                    case 3:
                        userDetailsResponse = _r.sent();
                        if (((_b = currentUserResponse.userOrganizations) === null || _b === void 0 ? void 0 : _b.length) > 1) {
                            setShowSelect(true);
                        }
                        reset({
                            version: (_c = userDetailsResponse === null || userDetailsResponse === void 0 ? void 0 : userDetailsResponse.version) !== null && _c !== void 0 ? _c : 0,
                            firstName: (_f = (_e = (_d = currentUserResponse === null || currentUserResponse === void 0 ? void 0 : currentUserResponse.user) === null || _d === void 0 ? void 0 : _d.personal) === null || _e === void 0 ? void 0 : _e.firstName) !== null && _f !== void 0 ? _f : "",
                            lastName: (_j = (_h = (_g = currentUserResponse === null || currentUserResponse === void 0 ? void 0 : currentUserResponse.user) === null || _g === void 0 ? void 0 : _g.personal) === null || _h === void 0 ? void 0 : _h.lastName) !== null && _j !== void 0 ? _j : "",
                            email: (_m = (_l = (_k = currentUserResponse === null || currentUserResponse === void 0 ? void 0 : currentUserResponse.user) === null || _k === void 0 ? void 0 : _k.personal) === null || _l === void 0 ? void 0 : _l.email) !== null && _m !== void 0 ? _m : "",
                            organization: (_p = (_o = currentUserResponse === null || currentUserResponse === void 0 ? void 0 : currentUserResponse.activeOrganization) === null || _o === void 0 ? void 0 : _o.name) !== null && _p !== void 0 ? _p : "",
                            roles: (_q = currentUserResponse === null || currentUserResponse === void 0 ? void 0 : currentUserResponse.activeRoles) !== null && _q !== void 0 ? _q : [],
                        });
                        _r.label = 4;
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        error_1 = _r.sent();
                        console.error("Error fetching user details:", error_1);
                        return [3 /*break*/, 7];
                    case 6:
                        isFetchingUserId.current = false;
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        if (!userId) {
            fetchUserDetails();
        }
    }, [ky, userId, reset]);
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var expandAllSections = function () {
        setAccordionStatus({
            organization: true,
            profile: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            organization: false,
            profile: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateMyProfile(ky, data)];
                case 1:
                    _b.sent();
                    setSuccessMessage("Uživatel byl úspěšně aktualizován.");
                    setSuccessModalOpen(true);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _b.sent();
                    if (((_a = error_2.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                        setErrorMessage("Záznam není možné uložit. Záznam byl mezitím upraven. Prosím, načtěte jej a znovu upravte.");
                        setConfirmModalOpen(true);
                    }
                    else {
                        setErrorMessage("Došlo k chybě při aktualizaci uživatele.");
                        setErrorModalOpen(true);
                    }
                    console.error("Error updating user:", error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(RecekPaneSet, null,
            filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace", lastMenu: React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters" }) },
                React.createElement(NavComponent, null))),
            React.createElement(Pane, { id: "contentPane", defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "M\u016Fj profil", firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": "Show filters" })) : null, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        !hasRole(activeRoles, RoleEnum.SUPERUSER) && showSelect && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/brusers/my-profile/select-organization");
                            } },
                            React.createElement(FormattedMessage, { id: "ui-br-users.my-profile.action.button.select.organization" }))),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/brusers/my-profile/password");
                            } }, "Zm\u011Bnit Heslo"),
                        hasRole(activeRoles, RoleEnum.SUPERUSER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/brusers/my-profile/import");
                            } }, "Import")),
                        hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/brusers/audit-logs");
                            } }, "Auditn\u00ED Protokoly"))));
                }, footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "danger", onClick: function () {
                            history.push("/logout");
                        } },
                        React.createElement(FormattedMessage, { id: "ui-br-users.myProfileDetail.button.logout" })) }) },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                        React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
                React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () {
                                return setAccordionStatus(function (prev) { return (__assign(__assign({}, prev), { organization: !prev.organization })); });
                            } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { name: "organization", control: control, render: function (_a) {
                                            var field = _a.field;
                                            return (React.createElement(TextField, __assign({ label: "Organizace" }, field, { disabled: true })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { name: "roles", control: control, render: function (_a) {
                                            var field = _a.field;
                                            return (React.createElement(TextField, __assign({ label: "Role" }, field, { value: Array.isArray(field.value)
                                                    ? field.value
                                                        .map(function (role) {
                                                        return getRolesTranslations(intl, upperFirst(role));
                                                    })
                                                        .join(", ")
                                                    : "", disabled: true })));
                                        } })))),
                        React.createElement(Accordion, { label: "M\u016Fj u\u017Eivatel", open: accordionStatus.profile, onToggle: function () {
                                return setAccordionStatus(function (prev) { return (__assign(__assign({}, prev), { profile: !prev.profile })); });
                            } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { name: "firstName", control: control, render: function (_a) {
                                            var _b;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({ label: "Jm\u00E9no" }, field, { required: true, error: (_b = errors.firstName) === null || _b === void 0 ? void 0 : _b.message })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { name: "lastName", control: control, render: function (_a) {
                                            var _b;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({ label: "P\u0159\u00EDjmen\u00ED" }, field, { required: true, error: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message })));
                                        } }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { name: "email", control: control, render: function (_a) {
                                            var _b;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({ label: "Email" }, field, { required: true, error: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message })));
                                        } })))))))),
        React.createElement(ConfirmationModal, { open: confirmModalOpen, heading: "Chyba", message: errorMessage, onConfirm: function () { return window.location.reload(); }, onCancel: function () { return setConfirmModalOpen(false); }, confirmLabel: "Znovu na\u010D\u00EDst", cancelLabel: "Zav\u0159\u00EDt", buttonStyle: "primary" }),
        React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
            React.createElement("div", null, errorMessage),
            React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
        React.createElement(Modal, { open: successModalOpen, label: "\u00DAsp\u011Bch", onClose: function () { return setSuccessModalOpen(false); }, dismissible: true },
            React.createElement("div", null, successMessage),
            React.createElement(Button, { onClick: function () { return setSuccessModalOpen(false); } }, "Zav\u0159\u00EDt"))));
};
export default MyProfileDetail;
