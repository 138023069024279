export var navigation = {
    agencyPrefixes: {
        path: "/bragency/agency-prefixes",
        label: "Prefix agentury",
        icon: "user",
    },
    internationalPrefixes: {
        path: "/bragency/international-prefixes",
        label: "Mezinárodní prefix",
        icon: "globe",
    },
    collections: {
        path: "/bragency/collections",
        label: "Řada",
        icon: "calendar",
    },
    onixCodetables: {
        path: "/bragency/onix-codetables",
        label: "Číselník ONIX",
        icon: "list",
    },
    onixCodetablesItems: {
        path: "/bragency/onix-codetables-items",
        label: "Položka číselníku ONIX",
        icon: "tag",
    },
    publisherPrefixRanges: {
        path: "/bragency/publisher-prefix-ranges",
        label: "Publisher prefix ranges",
        icon: "tag",
    },
};
