import { z } from "zod";
export var createUserSchema = function (intl) {
    return z.object({
        user: z.object({
            email: z
                .string()
                .min(1, {
                message: intl.formatMessage({
                    id: "ui-br-users.users.validation.email.required",
                }),
            })
                .email({
                message: intl.formatMessage({
                    id: "ui-br-users.users.validation.email.invalid",
                }),
            }),
            firstName: z.string().min(1, {
                message: intl.formatMessage({
                    id: "ui-br-users.users.validation.firstName",
                }),
            }),
            lastName: z.string().min(1, {
                message: intl.formatMessage({
                    id: "ui-br-users.users.validation.lastName",
                }),
            }),
            shouldChangePassword: z.boolean(),
        }),
        password: z
            .string({
            required_error: intl.formatMessage({
                id: "ui-br-users.users.validation.password.required",
            }),
        })
            .min(8, {
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.password.minimum",
            }),
        }),
        sendEmail: z.boolean(),
    });
};
export var createUserEditSchema = function (intl) {
    return z.object({
        email: z
            .string()
            .min(1, {
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.email.required",
            }),
        })
            .email({
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.email.invalid",
            }),
        }),
        firstName: z.string().min(1, {
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.firstName",
            }),
        }),
        lastName: z.string().min(1, {
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.lastName",
            }),
        }),
        shouldChangePassword: z.boolean().optional(),
        version: z.number().optional(),
    });
};
export var profileSchema = function (intl) {
    return z.object({
        firstName: z
            .string()
            .nonempty({
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.firstName",
            }),
        }),
        lastName: z
            .string()
            .nonempty({
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.lastName",
            }),
        }),
        email: z
            .string()
            .nonempty({
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.email.required",
            }),
        })
            .email({
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.email.invalid",
            }),
        }),
        version: z.number(),
        organization: z.string().optional(),
        roles: z.array(z.string()).optional(),
    });
};
export var createPasswordSchema = function (intl) {
    return z.object({
        currentPassword: z
            .string({
            required_error: intl.formatMessage({
                id: "ui-br-users.my-profile.validation.currentPassword.required",
            }),
        })
            .nonempty({
            message: intl.formatMessage({
                id: "ui-br-users.my-profile.validation.currentPassword.required",
            }),
        })
            .min(8, {
            message: intl.formatMessage({
                id: "ui-br-users.my-profile.validation.currentPassword.minimum",
            }),
        }),
        newPassword: z
            .string({
            required_error: intl.formatMessage({
                id: "ui-br-users.my-profile.validation.newPassword.required",
            }),
        })
            .nonempty({
            message: intl.formatMessage({
                id: "ui-br-users.my-profile.validation.newPassword.required",
            }),
        })
            .min(8, {
            message: intl.formatMessage({
                id: "ui-br-users.my-profile.validation.newPassword.minimum",
            }),
        }),
    });
};
export var organizationSchema = function (intl) {
    return z.object({
        name: z.string().nonempty({
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.firstName",
            }),
        }),
        version: z.number().optional(),
        source: z.string().optional(),
        sourceId: z.string().optional(),
        archived: z.boolean().optional(),
    });
};
export var selectOrganizationSchema = function (intl) {
    return z.object({
        orgSelection: z.string().nonempty({
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.organization",
            }),
        }),
    });
};
export var validateUser = function (data, intl) {
    return createUserSchema(intl).safeParse(data);
};
export var validateUserEdit = function (data, intl) {
    return createUserEditSchema(intl).safeParse(data);
};
export var validateProfile = function (data, intl) {
    return profileSchema(intl).safeParse(data);
};
export var validatePassword = function (data, intl) {
    return createPasswordSchema(intl).safeParse(data);
};
export var validateOrganization = function (data, intl) {
    return organizationSchema(intl).safeParse(data);
};
