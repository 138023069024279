import { Row, Col } from "@folio/stripes/components";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { getContactTypeTranslation } from "../../../utils/contactTypeTranslations";
export var ContactsAccordion = function (_a) {
    var contacts = _a.contacts;
    var hasContacts = contacts && contacts.length > 0;
    var intl = useIntl();
    var formatMessage = useIntl().formatMessage;
    return hasContacts ? (React.createElement(React.Fragment, null, contacts.map(function (contact, index) { return (React.createElement(Row, { key: index },
        React.createElement(Col, { xs: 12, md: 4 },
            React.createElement(RenderField, { label: formatMessage({
                    id: "ui-br-publishers.ares.field.label.type",
                }), value: getContactTypeTranslation(intl, contact.type) })),
        React.createElement(Col, { xs: 12, md: 4 },
            React.createElement(RenderField, { label: formatMessage({
                    id: "ui-br-publishers.publishers.general.field.label.value",
                }), value: contact.value })))); }))) : (React.createElement(Row, null,
        React.createElement(Col, { xs: 12 },
            React.createElement("div", null,
                React.createElement(FormattedMessage, { id: "ui-br-publishers.ares.text.content.noContactsToDisplay" })))));
};
