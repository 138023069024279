var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getHeaders } from "../utils/apiUtils";
var formatDate = function (date) {
    return new Date(date).toISOString().split("T")[0];
};
var handleResponse = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var text;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!response.ok) {
                    throw new Error("Error: ".concat(response.statusText));
                }
                return [4 /*yield*/, response.text()];
            case 1:
                text = _a.sent();
                return [2 /*return*/, text ? JSON.parse(text) : {}];
        }
    });
}); };
export var fetchRegions = function (kyInstance) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.get("regions", { headers: getHeaders() })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, handleResponse(response)];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.regions];
        }
    });
}); };
export var fetchAddress = function (kyInstance, query) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.get("publisher-address/suggest", {
                    searchParams: { address: query, limit: 10 },
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, handleResponse(response)];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.publisherAddresses];
        }
    });
}); };
export var fetchAddressCode = function (kyInstance, addressCode) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.get("publisher-address/".concat(addressCode), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
export var createPublisher = function (kyInstance, publisherData) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.post("publishers", {
                    json: publisherData,
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
export var fetchPublisherData = function (id, kyInstance) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.get("publishers/".concat(id), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
export var fetchPublishers = function (kyInstance, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var name, companyNumber, startDate, endDate, _a, status, _b, offset, _c, limit, queryParts, statusQuery, queryParam, limitParam, offsetParam, queryString, response, data;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                name = filters.name, companyNumber = filters.companyNumber, startDate = filters.startDate, endDate = filters.endDate, _a = filters.status, status = _a === void 0 ? [] : _a, _b = filters.offset, offset = _b === void 0 ? 0 : _b, _c = filters.limit, limit = _c === void 0 ? 50 : _c;
                queryParts = [];
                if (name) {
                    queryParts.push("name=\"".concat(name, "*\""));
                }
                if (companyNumber) {
                    queryParts.push("companyNumber=\"".concat(companyNumber, "\""));
                }
                if (startDate) {
                    queryParts.push("birthDate>=\"".concat(formatDate(startDate), "\""));
                }
                if (endDate) {
                    queryParts.push("birthDate<=\"".concat(formatDate(endDate), "\""));
                }
                if (Array.isArray(status) && status.length > 0) {
                    statusQuery = status
                        .map(function (s) { return "status==\"".concat(s, "\""); })
                        .join(" or ");
                    queryParts.push(statusQuery);
                }
                queryParam = queryParts.length > 0
                    ? "query=".concat(encodeURIComponent(queryParts.join(" and ")))
                    : "";
                limitParam = "limit=".concat(limit);
                offsetParam = "offset=".concat(offset);
                queryString = [queryParam, limitParam, offsetParam]
                    .filter(Boolean)
                    .join("&");
                return [4 /*yield*/, kyInstance.get("publishers?".concat(queryString), {
                        headers: getHeaders(),
                    })];
            case 1:
                response = _d.sent();
                if (!response.ok) {
                    throw new Error("Fetch error: ".concat(response.statusText));
                }
                return [4 /*yield*/, response.json()];
            case 2:
                data = _d.sent();
                return [2 /*return*/, {
                        publishers: data.publishers,
                        totalRecords: data.totalRecords,
                    }];
        }
    });
}); };
export var searchPublishers = function (kyInstance, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var search, name, companyNumber, startDate, endDate, status, registrationIntent, _a, offset, _b, limit, searchParams, response, data;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                search = filters.search, name = filters.name, companyNumber = filters.companyNumber, startDate = filters.startDate, endDate = filters.endDate, status = filters.status, registrationIntent = filters.registrationIntent, _a = filters.offset, offset = _a === void 0 ? 0 : _a, _b = filters.limit, limit = _b === void 0 ? 50 : _b;
                searchParams = new URLSearchParams();
                if (search)
                    searchParams.append("search", search);
                if (name)
                    searchParams.append("name", name);
                if (companyNumber)
                    searchParams.append("companyNumber", companyNumber);
                if (startDate)
                    searchParams.append("birthDateFrom", formatDate(startDate));
                if (endDate)
                    searchParams.append("birthDateTo", formatDate(endDate));
                if (registrationIntent) {
                    searchParams.append("registrationIntent", registrationIntent);
                }
                if (status && status.length > 0) {
                    status.forEach(function (s) { return searchParams.append("status", s); });
                }
                searchParams.append("limit", "".concat(limit));
                searchParams.append("offset", "".concat(offset));
                return [4 /*yield*/, kyInstance.get("publishers/search", {
                        headers: getHeaders(),
                        searchParams: searchParams,
                    })];
            case 1:
                response = _c.sent();
                if (!response.ok) {
                    throw new Error("Fetch error: ".concat(response.statusText));
                }
                return [4 /*yield*/, response.json()];
            case 2:
                data = _c.sent();
                return [2 /*return*/, {
                        publishers: data.publishers,
                        totalRecords: data.totalRecords,
                    }];
        }
    });
}); };
export var updatePublisher = function (kyInstance, id, formValues) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.put("publishers/".concat(id), {
                    json: formValues,
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
export var lookupPublisherByCompanyNumber = function (kyInstance, companyNumber) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.get("publishers/".concat(companyNumber, "/lookup"), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
export var closePublisher = function (kyInstance, publisherId, note) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.post("publishers/".concat(publisherId, "/close"), {
                    json: { internalComment: note },
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
export var updatePublisherAction = function (kyInstance, publisherId, action) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.post("publishers/".concat(publisherId, "/").concat(action), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
export var getDuplicities = function (kyInstance, formValues) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, kyInstance.post("publishers/duplicities", {
                    json: formValues,
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
        }
    });
}); };
