export var publicationFormTranslations = {
    "": { id: "ui-br-publishers.publishers.select.label.default" },
    PRINT: {
        id: "ui-br-publishers.publishers.workSpec.select.label.publicationForm.print",
    },
    EBOOK: {
        id: "ui-br-publishers.publishers.workSpec.select.label.publicationForm.ebook",
    },
};
export var getPublicationFormTranslation = function (intl, key) {
    var translation = publicationFormTranslations[key];
    if (!translation)
        return key;
    return intl.formatMessage(translation);
};
