var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, AccordionSet, Accordion, Select, MenuSection, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { useIntl } from "react-intl";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import { fetchCollections } from "../../api/collectionsService";
import { CollectionType } from "../../types/Collection";
import RecekTextField from "../../components/RecekTextField";
import { RecekPaneSet } from "../../components/RecekPaneSet";
var PAGE_SIZE = 20;
var CollectionsList = function () {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var formatMessage = useIntl().formatMessage;
    var _b = useState({
        type: "",
        name: "",
    }), filters = _b[0], setFilters = _b[1];
    // Track the current input values, without directly modifying filters until applied
    var _c = useState(""), tempTypeFilter = _c[0], setTempTypeFilter = _c[1];
    var _d = useState(""), tempNameFilter = _d[0], setTempNameFilter = _d[1];
    var _e = useState(0), pageOffset = _e[0], setPageOffset = _e[1];
    var _f = useState(0), totalRecords = _f[0], setTotalRecords = _f[1];
    var _g = useState(true), filterPaneIsVisible = _g[0], setFilterPaneIsVisible = _g[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        setFilters({
            type: queryParams.get("type") || "",
            name: queryParams.get("name") || "",
        });
        setTempTypeFilter(queryParams.get("type") || "");
        setTempNameFilter(queryParams.get("name") || "");
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.type) {
            params.set("type", filters.type);
        }
        if (filters.name) {
            params.set("name", filters.name);
        }
        history.push({ search: params.toString() });
    };
    var handleApplyFilters = function () {
        setFilters({
            type: tempTypeFilter,
            name: tempNameFilter,
        });
        updateUrlWithFilters({
            type: tempTypeFilter,
            name: tempNameFilter,
        });
    };
    var handleClearFilters = function () {
        setTempTypeFilter("");
        setTempNameFilter("");
        setFilters({
            type: "",
            name: "",
        });
        history.push({ search: "" });
    };
    var isAnyFilterActive = function () {
        return !!filters.type || !!filters.name;
    };
    var _h = useQuery(["collections", filters, pageOffset], function () {
        return fetchCollections(ky, __assign(__assign({}, filters), { offset: pageOffset, limit: PAGE_SIZE }));
    }, {
        enabled: true,
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _h.data, isLoading = _h.isLoading, isError = _h.isError, refetch = _h.refetch, isFetched = _h.isFetched;
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var collectionsData = (_a = data === null || data === void 0 ? void 0 : data.collections) !== null && _a !== void 0 ? _a : [];
    var columns = [
        {
            name: "Název",
            key: "mainName",
            clickable: true,
            onClick: function (item) {
                history.push("/bragency/collections/".concat(item.id));
            },
        },
        {
            name: "Typ",
            key: "type",
        },
        {
            name: "ISSN",
            key: "issn",
        },
    ];
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return collectionsData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isFetched; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.successfulMutations = function () { return []; };
    source.fetchMore = function () { return fetchMoreData; };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    return (React.createElement(RecekPaneSet, null,
        filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1n\u00ED" },
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(NavComponent, null),
                React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters }, "Resetovat v\u0161echno"),
                React.createElement(AccordionSet, null,
                    React.createElement("form", { onSubmit: function (e) {
                            e.preventDefault();
                            handleApplyFilters();
                        } },
                        React.createElement(Accordion, { label: formatMessage({
                                id: "ui-br-agency.collectionsList.accordion.label.filterByTypeAndName",
                            }), separator: false },
                            React.createElement(Select, { label: formatMessage({
                                    id: "ui-br-agency.collectionsList.select.label.type",
                                }), value: tempTypeFilter, onChange: function (e) { return setTempTypeFilter(e.target.value); }, fullWidth: true },
                                React.createElement("option", { value: "" }, formatMessage({
                                    id: "ui-br-agency.collectionsList.select.option.all",
                                })),
                                React.createElement("option", { value: CollectionType.PUBLISHER_SERIES }, CollectionType.PUBLISHER_SERIES),
                                React.createElement("option", { value: CollectionType.SERIES }, CollectionType.SERIES)),
                            React.createElement(RecekTextField, { label: formatMessage({
                                    id: "ui-br-agency.collectionList.textField.label.name",
                                }), value: tempNameFilter, onChange: function (e) { return setTempNameFilter(e.target.value); }, fullWidth: true }),
                            React.createElement(Button, { type: "submit", marginBottom0: true }, formatMessage({
                                id: "ui-br-agency.button.apply",
                            })))))))),
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Seznam kolekc\u00ED", actionMenu: function (_a) {
                var onToggle = _a.onToggle;
                return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                    React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            onToggle();
                            history.push("/bragency/collections/create");
                        } }, "Nov\u00FD")));
            } },
            React.createElement(TableComponent, { data: collectionsData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, onNeedMoreData: function (offset) {
                    fetchMoreData(offset);
                }, searchTerm: "", filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source }))));
};
export default CollectionsList;
