var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery } from "react-query";
import { Pane, Select, Button, Row, Col, TextArea, Datepicker, AccordionSet, Accordion, Checkbox, Selection, PaneFooter, ErrorModal, Paneset, Spinner, } from "@folio/stripes/components";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import debounce from "lodash/debounce";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormattedMessage, useIntl } from "react-intl";
import { isEqual } from "lodash";
import { createPublisherSchema } from "../../utils/validationUtils";
import { createPublisher, fetchAddress, fetchAddressCode, fetchRegions, getDuplicities, lookupPublisherByCompanyNumber, } from "../../api/publisherService";
import RecekTextField from "../../components/RecekTextField";
import DuplicateTable from "../../components/DuplicateTable";
import { ErrorType } from "../../types/ErrorType";
var PublisherCreate = function () {
    var _a;
    var history = useHistory();
    var ky = useOkapiKy();
    var formatMessage = useIntl().formatMessage;
    var _b = useState([]), potentialDuplicates = _b[0], setPotentialDuplicates = _b[1];
    var _c = useState(""), duplicateWarning = _c[0], setDuplicateWarning = _c[1];
    var skipDuplicateCheckRef = useRef(false);
    var updatedDataref = useRef();
    var _d = useState(null), icoError = _d[0], setIcoError = _d[1];
    var publisherSchema = createPublisherSchema(formatMessage);
    var _e = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
        guarantors: true,
        workSpec: true,
    }), accordionStatus = _e[0], setAccordionStatus = _e[1];
    var _f = useForm({
        resolver: zodResolver(publisherSchema),
        shouldFocusError: false,
        defaultValues: {
            registrationAgency: "",
            registrationIntent: "",
            companyNumber: "",
            name: "",
            nameSuffix: "",
            qualifier: "",
            legalForm: "LEGAL",
            taxNumber: "",
            birthDate: "",
            status: "ACTIVE",
            mainAddress: {
                addressCode: "",
                street: "",
                postalCode: "",
                municipality: "",
                region: { id: "", name: "" },
            },
            mailingAddress: {
                addressCode: "",
                street: "",
                postalCode: "",
                municipality: "",
                region: { id: "", name: "" },
            },
            contacts: [],
            alternativeNames: [],
            internalComment: "",
            guarantors: [
                {
                    id: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    contacts: [],
                    user: {
                        sourceId: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        roles: [],
                    },
                },
            ],
            workSpec: {
                title: "",
                subtitle: "",
                volumeNumber: "",
                partName: "",
                authors: [{ firstName: "", lastName: "" }],
                publicationDate: "",
                releaseOrder: "",
                publishedInConjunction: "",
                publicationForm: "",
                printedPublicationBinding: "",
                ebookFormats: [{ format: "", otherFormat: "", offerUrl: "" }],
                dataCarrierVersion: "",
                audioBook: "",
            },
        },
        mode: "onBlur",
    }), handleSubmit = _f.handleSubmit, register = _f.register, control = _f.control, setValue = _f.setValue, getValues = _f.getValues, watch = _f.watch, errors = _f.formState.errors, trigger = _f.trigger;
    var _g = useFieldArray({
        control: control,
        name: "alternativeNames",
    }), alternativeNameFields = _g.fields, appendAlternativeName = _g.append, removeAlternativeNameAtIndex = _g.remove;
    var _h = useFieldArray({
        control: control,
        name: "contacts",
    }), contactFields = _h.fields, appendContact = _h.append, removeContactAtIndex = _h.remove;
    var _j = useFieldArray({
        control: control,
        name: "guarantors",
    }), guarantorFields = _j.fields, appendGuarantor = _j.append, removeGuarantorAtIndex = _j.remove;
    var _k = useFieldArray({
        control: control,
        name: "workSpec.authors",
    }), authorFields = _k.fields, appendAuthor = _k.append, removeAuthor = _k.remove;
    var _l = useFieldArray({
        control: control,
        name: "workSpec.ebookFormats",
    }), ebookFormatFields = _l.fields, appendEbookFormat = _l.append, removeEbookFormat = _l.remove;
    var legalFormValue = watch("legalForm");
    var companyNumber = watch("companyNumber");
    var _m = useState(false), enableMainAddress = _m[0], setEnableMainAddress = _m[1];
    var _o = useState(false), enableMailingAddress = _o[0], setEnableMailingAddress = _o[1];
    var _p = useState(""), errorMessage = _p[0], setErrorMessage = _p[1];
    var _q = useState(false), errorModalOpen = _q[0], setErrorModalOpen = _q[1];
    var _r = useState(false), showMailingAddress = _r[0], setShowMailingAddress = _r[1];
    var _s = useState(true), expandAll = _s[0], setExpandAll = _s[1];
    var _t = useState([]), regions = _t[0], setRegions = _t[1];
    var _u = useState(""), addressQuery = _u[0], setAddressQuery = _u[1];
    var _v = useState([]), addressData = _v[0], setAddressData = _v[1];
    var _w = useState(false), addressLoading = _w[0], setAddressLoading = _w[1];
    var _x = useState(""), mailingAddressQuery = _x[0], setMailingAddressQuery = _x[1];
    var _y = useState([]), mailingAddressData = _y[0], setMailingAddressData = _y[1];
    var debouncedSetAddressQuery = useCallback(debounce(function (inputValue) {
        setAddressQuery(inputValue);
    }, 300), []);
    var handleAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressLoading(true);
                    return [4 /*yield*/, debouncedSetAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAddressSelection = function (selectedValue, type) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails = _a.sent();
                    setValue("".concat(type, ".addressCode"), selectedValue);
                    setValue("".concat(type, ".street"), addressDetails.street);
                    setValue("".concat(type, ".postalCode"), addressDetails.postalCode);
                    setValue("".concat(type, ".municipality"), addressDetails.municipality);
                    setValue("".concat(type, ".region"), addressDetails.region || { id: "", name: "" });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching ".concat(type, " details:"), error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchAddress(ky, addressQuery)
            .then(function (publisherAddresses) {
            setAddressData(publisherAddresses);
        })
            .catch(function (error) {
            console.error("Error fetching addresses:", error);
            setAddressData([]);
        });
    }, [addressQuery]);
    useEffect(function () {
        fetchAddress(ky, mailingAddressQuery)
            .then(function (addresses) {
            setMailingAddressData(addresses);
        })
            .catch(function (error) {
            console.error("Error fetching mailing addresses:", error);
            setMailingAddressData([]);
        });
    }, [mailingAddressQuery]);
    useQuery("regions", function () { return fetchRegions(ky); }, {
        onSuccess: function (data) {
            setRegions(data);
        },
    });
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name in getValues()) {
            setValue(name, value, { shouldValidate: true });
        }
    };
    var _z = useQuery(["publisher", companyNumber], function () { return lookupPublisherByCompanyNumber(ky, companyNumber !== null && companyNumber !== void 0 ? companyNumber : ""); }, {
        enabled: false,
        retry: false,
        onError: function (error) { return __awaiter(void 0, void 0, void 0, function () {
            var errors, currentError, translatedError;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.json())];
                    case 1:
                        errors = _d.sent();
                        currentError = (_b = errors === null || errors === void 0 ? void 0 : errors.errors) === null || _b === void 0 ? void 0 : _b[0];
                        translatedError = (_c = currentError === null || currentError === void 0 ? void 0 : currentError.message) !== null && _c !== void 0 ? _c : "";
                        if ((currentError === null || currentError === void 0 ? void 0 : currentError.type) === ErrorType.INTERNAL &&
                            (currentError === null || currentError === void 0 ? void 0 : currentError.code) === "NOT_FOUND_ERROR") {
                            translatedError = formatMessage({ id: "stripes-core.error.aresDataNotFound" }, { ico: companyNumber });
                        }
                        setIcoError(translatedError);
                        return [2 /*return*/];
                }
            });
        }); },
    }), isIcoFetching = _z.isFetching, refetchIco = _z.refetch;
    var handleIco = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, icoPublisher, isError, error_2;
        var _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    _h.trys.push([0, 4, , 5]);
                    if (!companyNumber || companyNumber.trim() === "") {
                        setIcoError(formatMessage({
                            id: "stripes-core.error.companyNumber.required",
                        }));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, refetchIco()];
                case 1:
                    _a = _h.sent(), icoPublisher = _a.data, isError = _a.isError;
                    if (isError)
                        return [2 /*return*/];
                    setValue("name", (_b = icoPublisher === null || icoPublisher === void 0 ? void 0 : icoPublisher.name) !== null && _b !== void 0 ? _b : "", { shouldValidate: true });
                    setValue("legalForm", (_c = icoPublisher === null || icoPublisher === void 0 ? void 0 : icoPublisher.legalForm) !== null && _c !== void 0 ? _c : getValues("legalForm"), {
                        shouldValidate: true,
                    });
                    setValue("taxNumber", (_d = icoPublisher === null || icoPublisher === void 0 ? void 0 : icoPublisher.taxNumber) !== null && _d !== void 0 ? _d : getValues("taxNumber"), {
                        shouldValidate: true,
                    });
                    setValue("contacts", (_f = (_e = icoPublisher === null || icoPublisher === void 0 ? void 0 : icoPublisher.contacts) !== null && _e !== void 0 ? _e : getValues("contacts")) !== null && _f !== void 0 ? _f : [], {
                        shouldValidate: true,
                    });
                    if (!((_g = icoPublisher === null || icoPublisher === void 0 ? void 0 : icoPublisher.mainAddress) === null || _g === void 0 ? void 0 : _g.addressCode)) return [3 /*break*/, 3];
                    return [4 /*yield*/, handleAddressSelection(icoPublisher === null || icoPublisher === void 0 ? void 0 : icoPublisher.mainAddress.addressCode, "mainAddress")];
                case 2:
                    _h.sent();
                    _h.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_2 = _h.sent();
                    console.error("Error fetching Ares data:", error_2);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var expandAllSections = function () {
        setAccordionStatus({
            general: true,
            address: true,
            contacts: true,
            alternativeNames: true,
            comments: true,
            guarantors: true,
            workSpec: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            general: false,
            address: false,
            contacts: false,
            alternativeNames: false,
            comments: false,
            guarantors: false,
            workSpec: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var workSpec, rest, initialData, contacts, guarantors, submissionData, publishers, duplicatesSection, error_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    workSpec = data.workSpec, rest = __rest(data, ["workSpec"]);
                    initialData = __assign(__assign({}, rest), { workSpecificationJsonData: JSON.stringify(workSpec) });
                    contacts = (_a = rest === null || rest === void 0 ? void 0 : rest.contacts) === null || _a === void 0 ? void 0 : _a.filter(function (contact) { return contact.type !== "" || contact.value !== ""; });
                    guarantors = ((_b = rest === null || rest === void 0 ? void 0 : rest.guarantors) === null || _b === void 0 ? void 0 : _b.map(function (guarantor) {
                        var _a;
                        return (__assign(__assign({}, guarantor), { contacts: ((_a = guarantor.contacts) === null || _a === void 0 ? void 0 : _a.filter(function (contact) { var _a, _b; return ((_a = contact.type) === null || _a === void 0 ? void 0 : _a.trim()) !== "" && ((_b = contact.value) === null || _b === void 0 ? void 0 : _b.trim()) !== ""; })) || [] }));
                    })) || [];
                    submissionData = __assign(__assign({}, initialData), { contacts: contacts, guarantors: guarantors });
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 5, , 6]);
                    if (!(!skipDuplicateCheckRef.current ||
                        !isEqual(submissionData, updatedDataref.current))) return [3 /*break*/, 3];
                    return [4 /*yield*/, getDuplicities(ky, submissionData)];
                case 2:
                    publishers = (_c.sent()).publishers;
                    if (publishers && publishers.length > 0) {
                        setPotentialDuplicates(publishers);
                        setDuplicateWarning(formatMessage({
                            id: "ui-br-publishers.publisher.warning.message.duplicatesFound",
                        }));
                        duplicatesSection = document.getElementById("duplicates-table");
                        if (duplicatesSection) {
                            duplicatesSection.scrollIntoView({ behavior: "smooth" });
                        }
                        skipDuplicateCheckRef.current = true;
                        updatedDataref.current = submissionData;
                        return [2 /*return*/];
                    }
                    _c.label = 3;
                case 3: return [4 /*yield*/, createPublisher(ky, submissionData)];
                case 4:
                    _c.sent();
                    skipDuplicateCheckRef.current = false;
                    setDuplicateWarning("");
                    history.goBack();
                    return [3 /*break*/, 6];
                case 5:
                    error_3 = _c.sent();
                    setErrorMessage("Do\u0161lo k chyb\u011B p\u0159i vytv\u00E1\u0159en\u00ED nakladatele: ".concat(error_3));
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Paneset, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: formatMessage({
                id: "ui-br-publishers.publishers.create.pane.title",
            }), footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", onClick: handleSubmit(onSubmit) },
                    React.createElement(FormattedMessage, { id: "ui-br-publishers.button.save" })), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); } },
                    React.createElement(FormattedMessage, { id: "ui-br-publishers.button.cancel" })) }) },
            React.createElement(ErrorModal, { open: errorModalOpen, label: formatMessage({
                    id: "ui-br-publishers.error.modal.title",
                }), content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } },
                    React.createElement(FormattedMessage, { id: "ui-br-publishers.button.close" }))),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? (React.createElement(FormattedMessage, { id: "ui-br-publishers.button.collapse.all" })) : (React.createElement(FormattedMessage, { id: "ui-br-publishers.button.expand.all" }))))),
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement(AccordionSet, null,
                    (potentialDuplicates === null || potentialDuplicates === void 0 ? void 0 : potentialDuplicates.length) > 0 ? (React.createElement(Accordion, { id: "duplicates-table", label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.potentialDuplicates",
                        }) },
                        duplicateWarning && (React.createElement("div", { style: { color: "red", marginBottom: "1rem" } }, duplicateWarning)),
                        React.createElement(DuplicateTable, { duplicates: potentialDuplicates }))) : null,
                    React.createElement(Accordion, { label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.general",
                        }), open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "registrationAgency", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.label.select.registration.agency",
                                            }), required: true, error: (_b = errors.registrationAgency) === null || _b === void 0 ? void 0 : _b.message, dataOptions: [
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.label.registration.agency.default",
                                                    }),
                                                },
                                                {
                                                    value: "ISBN",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.label.registration.agency.ISBN",
                                                    }),
                                                },
                                                {
                                                    value: "ISMN",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.label.registration.agency.ISMN",
                                                    }),
                                                },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "registrationIntent", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.label.select.registration.intent",
                                            }), required: true, error: (_b = errors.registrationIntent) === null || _b === void 0 ? void 0 : _b.message, dataOptions: [
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.label.registration.intent.default",
                                                    }),
                                                },
                                                {
                                                    value: "CONTINUOUS",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.label.registration.intent.CONTINUOUS",
                                                    }),
                                                },
                                                {
                                                    value: "ONE_OFF",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.label.registration.intent.ONE_OFF",
                                                    }),
                                                },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 6, style: {
                                    display: "flex",
                                    alignItems: Boolean((_a = errors.companyNumber) === null || _a === void 0 ? void 0 : _a.message) || icoError
                                        ? "center"
                                        : "flex-end",
                                } },
                                React.createElement(Controller, { control: control, name: "companyNumber", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(React.Fragment, null,
                                            React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.general.field.label.companyNumber",
                                                }), required: true, error: (_c = (_b = errors.companyNumber) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : icoError, onBlur: function () {
                                                    field.onBlur();
                                                    trigger([
                                                        "companyNumber",
                                                        "taxNumber",
                                                        "birthDate",
                                                    ]);
                                                }, onChange: function (e) {
                                                    field.onChange(e);
                                                    setIcoError(null);
                                                } })),
                                            React.createElement(Button, { buttonStyle: "primary", onClick: handleIco, disabled: isIcoFetching, style: {
                                                    marginLeft: "16px",
                                                    marginBottom: "1rem",
                                                } }, isIcoFetching ? (React.createElement(Spinner, null)) : (React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.general.button.companyNumber" })))));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "name", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.name",
                                            }) }, field, { required: true, error: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "nameSuffix", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.nameSuffix",
                                            }) }, field)));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "legalForm", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { onChange: function (e) {
                                                field.onChange(e);
                                                trigger(["companyNumber", "taxNumber", "birthDate"]);
                                            }, label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.select.label.legalForm",
                                            }) }),
                                            React.createElement("option", { value: "UNKNOWN" }, formatMessage({
                                                id: "ui-br-publishers.publishers.general.select.legal.form.unknown",
                                            })),
                                            React.createElement("option", { value: "LEGAL" }, formatMessage({
                                                id: "ui-br-publishers.publishers.general.select.label.legal",
                                            })),
                                            React.createElement("option", { value: "NATURAL" }, formatMessage({
                                                id: "ui-br-publishers.publishers.general.select.label.natural",
                                            }))));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "taxNumber", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.taxNumber",
                                            }) }, field, { required: true, error: (_b = errors.taxNumber) === null || _b === void 0 ? void 0 : _b.message, onBlur: function () {
                                                field.onBlur();
                                                trigger(["companyNumber", "taxNumber", "birthDate"]);
                                            } })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "birthDate", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(Datepicker, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.datePicker.label.birthDate",
                                            }) }, field, { error: (_b = errors.birthDate) === null || _b === void 0 ? void 0 : _b.message, exclude: function (day) { return day.isAfter(moment()); }, disabled: legalFormValue === "LEGAL", backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY", placeholder: "DD/MM/RRRR", onBlur: function () {
                                                field.onBlur();
                                                trigger(["companyNumber", "taxNumber", "birthDate"]);
                                            } })));
                                    } })))),
                    React.createElement(Accordion, { label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.address",
                        }), open: accordionStatus.address, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.addressCode", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Selection, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.mainAddress.selection.label.addressCode",
                                            }), disabled: addressLoading, asyncFilter: true, onFilter: handleAddressInput, dataOptions: addressData.map(function (address) { return ({
                                                value: address.addressCode,
                                                label: address.address,
                                            }); }), onChange: function (selectedValue) {
                                                field.onChange(selectedValue);
                                                handleAddressSelection(selectedValue, "mainAddress");
                                            } })));
                                    } })),
                            React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "center" } },
                                React.createElement(Checkbox, { label: formatMessage({
                                        id: "ui-br-publishers.publishers.mainAddress.checkbox.label.address",
                                    }), checked: enableMainAddress, onChange: function () {
                                        setEnableMainAddress(!enableMainAddress);
                                        if (!enableMainAddress) {
                                            setValue("mainAddress.addressCode", "");
                                            setValue("mainAddress.street", "");
                                            setValue("mainAddress.postalCode", "");
                                            setValue("mainAddress.municipality", "");
                                            setValue("mainAddress.region", { id: "", name: "" });
                                        }
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.region.id", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.mainAddress.select.label.region",
                                            }), required: true, error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMainAddress, dataOptions: __spreadArray([
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.mainAddress.select.label.default",
                                                    }),
                                                }
                                            ], regions.map(function (region) { return ({
                                                value: region.id,
                                                label: region.name,
                                            }); }), true), onChange: function (e) {
                                                var selectedRegion = regions.find(function (region) { return region.id === e.target.value; });
                                                setValue("mainAddress.region", selectedRegion || { id: "", name: "" });
                                            } })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.street", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.mainAddress.field.label.street",
                                            }), error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMainAddress })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.postalCode", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.postalCode",
                                            }), error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.postalCode) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMainAddress })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.municipality", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.municipality",
                                            }), error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.municipality) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMainAddress })));
                                    } }))),
                        React.createElement(Checkbox, { label: formatMessage({
                                id: "ui-br-publishers.publishers.mailingAddress.checkbox.label.show",
                            }), checked: showMailingAddress, onChange: function () { return setShowMailingAddress(!showMailingAddress); } }),
                        showMailingAddress && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.addressCode", render: function (_a) {
                                            var field = _a.field;
                                            return (React.createElement(Selection, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.mailingAddress.selection.label.addressCode",
                                                }), disabled: addressLoading, asyncFilter: true, onFilter: handleAddressInput, dataOptions: addressData.map(function (address) { return ({
                                                    value: address.addressCode,
                                                    label: address.address,
                                                }); }), onChange: function (selectedValue) {
                                                    field.onChange(selectedValue);
                                                    handleAddressSelection(selectedValue, "mailingAddress");
                                                } })));
                                        } })),
                                React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "center" } },
                                    React.createElement(Checkbox, { label: formatMessage({
                                            id: "ui-br-publishers.publishers.mailingAddress.checkbox.label.address",
                                        }), checked: enableMailingAddress, onChange: function () {
                                            setEnableMailingAddress(!enableMailingAddress);
                                            if (!enableMailingAddress) {
                                                setValue("mailingAddress.addressCode", "");
                                                setValue("mailingAddress.street", "");
                                                setValue("mailingAddress.postalCode", "");
                                                setValue("mailingAddress.municipality", "");
                                                setValue("mailingAddress.region", {
                                                    id: "",
                                                    name: "",
                                                });
                                            }
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.region.id", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.mailingAddress.select.label.region",
                                                }), error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMailingAddress, dataOptions: __spreadArray([
                                                    {
                                                        value: "",
                                                        label: formatMessage({
                                                            id: "ui-br-publishers.publishers.mailingAddress.select.label.default",
                                                        }),
                                                    }
                                                ], regions.map(function (region) { return ({
                                                    value: region.id,
                                                    label: region.name,
                                                }); }), true), onChange: function (e) {
                                                    var selectedRegion = regions.find(function (region) { return region.id === e.target.value; });
                                                    setValue("mailingAddress.region", selectedRegion || { id: "", name: "" });
                                                } })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.street", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.mailingAddress.field.label.street",
                                                }), error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMailingAddress })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.postalCode", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.general.field.label.postalCode",
                                                }), error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.postalCode) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMailingAddress })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.municipality", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.general.field.label.municipality",
                                                }), error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.municipality) === null || _c === void 0 ? void 0 : _c.message, disabled: !enableMailingAddress })));
                                        } })))))),
                    React.createElement(Accordion, { label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.contacts",
                        }), open: accordionStatus.contacts, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { contacts: !accordionStatus.contacts }));
                        } },
                        contactFields.map(function (field, index) { return (React.createElement(Row, { key: field.id },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "contacts.".concat(index, ".type"), render: function (_a) {
                                        var _b, _c, _d, _e, _f;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.contacts.select.label.type",
                                            }), error: typeof ((_c = (_b = errors.contacts) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.type) === "object"
                                                ? (_f = (_e = (_d = errors.contacts) === null || _d === void 0 ? void 0 : _d[index]) === null || _e === void 0 ? void 0 : _e.type) === null || _f === void 0 ? void 0 : _f.message
                                                : undefined, dataOptions: [
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.contacts.select.label.default",
                                                    }),
                                                },
                                                {
                                                    value: "EMAIL",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.contacts.select.label.email",
                                                    }),
                                                },
                                                {
                                                    value: "WEB",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.contacts.select.label.web",
                                                    }),
                                                },
                                                {
                                                    value: "PHONE",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.contacts.select.label.phone",
                                                    }),
                                                },
                                                {
                                                    value: "DATA_BOX",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.contacts.select.label.dataBox",
                                                    }),
                                                },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "contacts.".concat(index, ".value"), render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.contacts.field.label.contact",
                                            }), error: (_d = (_c = (_b = errors.contacts) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeContactAtIndex(index); }, style: { marginTop: "25px" } },
                                    React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.contacts.button.delete" }))))); }),
                        React.createElement(Button, { onClick: function () {
                                return appendContact({
                                    type: "",
                                    value: "",
                                }, { shouldFocus: false });
                            } },
                            React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.contacts.button.add" }))),
                    React.createElement(Accordion, { label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.alternativeNames",
                        }), open: accordionStatus.alternativeNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames }));
                        } },
                        alternativeNameFields.map(function (field, index) { return (React.createElement(Row, { key: field.id },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".name"), defaultValue: field.name, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.alternativeNames.field.label.name",
                                            }), required: true, error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nameSuffix"), defaultValue: field.nameSuffix, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.alternativeNames.field.label.nameSuffix",
                                            }), error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.nameSuffix) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".qualifier"), defaultValue: field.qualifier, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.qualifier",
                                            }), error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.qualifier) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".type"), defaultValue: field.type, render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.alternativeNames.select.label.type",
                                            }), dataOptions: [
                                                {
                                                    value: "PREVIOUS",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.alternativeNames.select.label.previous",
                                                    }),
                                                },
                                                {
                                                    value: "IMPRINT",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.alternativeNames.select.label.imprint",
                                                    }),
                                                },
                                                {
                                                    value: "ABBREVIATION",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.alternativeNames.select.label.abbreviation",
                                                    }),
                                                },
                                                {
                                                    value: "ORGANIZATION_UNIT",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.alternativeNames.select.label.organizationUnit",
                                                    }),
                                                },
                                                {
                                                    value: "FOREIGN_LANGUAGE",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.alternativeNames.select.label.foreignLanguage",
                                                    }),
                                                },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nonPublic"), defaultValue: field.nonPublic, render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Checkbox, { checked: field.value, onChange: function (e) { return field.onChange(e.target.checked); }, label: formatMessage({
                                                id: "ui-br-publishers.publishers.alternativeNames.checkbox.label.nonPublic",
                                            }) }));
                                    } })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeNameAtIndex(index); }, style: { marginTop: "10px" } },
                                    React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.alternativeNames.button.delete" }))))); }),
                        React.createElement(Button, { onClick: function () {
                                return appendAlternativeName({
                                    name: "",
                                    nameSuffix: "",
                                    qualifier: "",
                                    type: undefined,
                                    nonPublic: false,
                                }, { shouldFocus: false });
                            } },
                            React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.alternativeNames.button.add" }))),
                    React.createElement(Accordion, { label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.guarantors",
                        }), id: "guarantors", open: accordionStatus.guarantors, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { guarantors: !accordionStatus.guarantors }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                guarantorFields.map(function (guarantor, index) {
                                    var _a;
                                    return (React.createElement("div", { key: guarantor.id },
                                        React.createElement(Row, null,
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".titlesBefore"), render: function (_a) {
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                                id: "ui-br-publishers.publishers.guarantors.field.label.titlesBefore",
                                                            }) }, field)));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".firstName"), render: function (_a) {
                                                        var _b, _c, _d;
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                                id: "ui-br-publishers.publishers.guarantors.field.label.firstName",
                                                            }) }, field, { required: true, error: (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.firstName) === null || _d === void 0 ? void 0 : _d.message })));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".lastName"), render: function (_a) {
                                                        var _b, _c, _d;
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                                id: "ui-br-publishers.publishers.guarantors.field.label.lastName",
                                                            }) }, field, { required: true, error: (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.lastName) === null || _d === void 0 ? void 0 : _d.message })));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".titlesAfter"), render: function (_a) {
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                                id: "ui-br-publishers.publishers.guarantors.field.label.titlesAfter",
                                                            }) }, field)));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".email"), render: function (_a) {
                                                        var _b, _c, _d;
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                                id: "ui-br-publishers.publishers.guarantors.field.label.email",
                                                            }) }, field, { required: true, error: (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.message })));
                                                    } })),
                                            React.createElement(Col, { xs: 2 }, guarantorFields.length > 1 && (React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeGuarantorAtIndex(index); }, style: { marginTop: "25px" } },
                                                React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.guarantors.button.delete" })))),
                                            React.createElement(Col, { xs: 12 },
                                                React.createElement(Row, null,
                                                    React.createElement("h3", null, "Kontakty")), (_a = watch("guarantors.".concat(index, ".contacts"))) === null || _a === void 0 ? void 0 :
                                                _a.map(function (contact, contactIndex) { return (React.createElement(Row, { key: contactIndex, style: { marginBottom: "10px" } },
                                                    React.createElement(Col, { xs: 4 },
                                                        React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".contacts.").concat(contactIndex, ".type"), render: function (_a) {
                                                                var _b, _c, _d, _e, _f, _g, _h, _j;
                                                                var field = _a.field;
                                                                return (React.createElement(Select, __assign({ label: formatMessage({
                                                                        id: "ui-br-publishers.publishers.guarantors.contacts.select.label.type",
                                                                    }), error: typeof ((_e = (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.contacts) === null || _d === void 0 ? void 0 : _d[contactIndex]) === null || _e === void 0 ? void 0 : _e.type) ===
                                                                        "object"
                                                                        ? (_j = (_h = (_g = (_f = errors.guarantors) === null || _f === void 0 ? void 0 : _f[index]) === null || _g === void 0 ? void 0 : _g.contacts) === null || _h === void 0 ? void 0 : _h[contactIndex]) === null || _j === void 0 ? void 0 : _j.type.message
                                                                        : undefined }, field, { dataOptions: [
                                                                        {
                                                                            value: "",
                                                                            label: formatMessage({
                                                                                id: "ui-br-publishers.publishers.contacts.select.label.default",
                                                                            }),
                                                                        },
                                                                        {
                                                                            value: "EMAIL",
                                                                            label: formatMessage({
                                                                                id: "ui-br-publishers.publishers.guarantors.contacts.select.label.email",
                                                                            }),
                                                                        },
                                                                        {
                                                                            value: "WEB",
                                                                            label: formatMessage({
                                                                                id: "ui-br-publishers.publishers.guarantors.contacts.select.label.web",
                                                                            }),
                                                                        },
                                                                        {
                                                                            value: "PHONE",
                                                                            label: formatMessage({
                                                                                id: "ui-br-publishers.publishers.guarantors.contacts.select.label.phone",
                                                                            }),
                                                                        },
                                                                        {
                                                                            value: "DATA_BOX",
                                                                            label: formatMessage({
                                                                                id: "ui-br-publishers.publishers.guarantors.contacts.select.label.dataBox",
                                                                            }),
                                                                        },
                                                                    ] })));
                                                            } })),
                                                    React.createElement(Col, { xs: 6 },
                                                        React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".contacts.").concat(contactIndex, ".value"), render: function (_a) {
                                                                var _b, _c, _d, _e, _f;
                                                                var field = _a.field;
                                                                return (React.createElement(RecekTextField, __assign({ label: "Kontakt", error: (_f = (_e = (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.contacts) === null || _d === void 0 ? void 0 : _d[contactIndex]) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.message }, field)));
                                                            } })),
                                                    React.createElement(Col, { xs: 2 },
                                                        React.createElement(Button, { buttonStyle: "danger", onClick: function () {
                                                                var currentContacts = getValues("guarantors.".concat(index, ".contacts")) || [];
                                                                var updatedContacts = currentContacts.filter(function (_, i) { return i !== contactIndex; });
                                                                setValue("guarantors.".concat(index, ".contacts"), updatedContacts);
                                                            }, style: { marginTop: "25px" } },
                                                            React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.guarantors.contacts.button.delete" }))))); }),
                                                React.createElement(Button, { type: "button", onClick: function () {
                                                        var currentContacts = getValues("guarantors.".concat(index, ".contacts")) || [];
                                                        setValue("guarantors.".concat(index, ".contacts"), __spreadArray(__spreadArray([], currentContacts, true), [
                                                            { type: "", value: "" },
                                                        ], false));
                                                    } },
                                                    React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.guarantors.contacts.button.add" }))))));
                                }),
                                React.createElement(Button, { type: "button", onClick: function () {
                                        return appendGuarantor({
                                            id: "",
                                            firstName: "",
                                            lastName: "",
                                            email: "",
                                            contacts: [],
                                            user: {
                                                sourceId: "",
                                                firstName: "",
                                                lastName: "",
                                                email: "",
                                                roles: [],
                                            },
                                        });
                                    }, style: { marginTop: "20px" } },
                                    React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.guarantors.button.add" }))))),
                    watch("registrationIntent") === "ONE_OFF" && (React.createElement(Accordion, { label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.workSpec",
                        }), open: accordionStatus.workSpec, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { workSpec: !accordionStatus.workSpec }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.title", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.field.label.title",
                                            }), required: true, error: (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.title) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.subtitle", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.field.label.subTitle",
                                            }), error: (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.subtitle) === null || _c === void 0 ? void 0 : _c.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.volumeNumber", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.field.label.volumeNumber",
                                            }), error: (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.volumeNumber) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.partName", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.field.label.partName",
                                            }), error: (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.partName) === null || _c === void 0 ? void 0 : _c.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement("h3", null,
                                    React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.workSpec.title.authors" }))),
                            React.createElement(Col, { xs: 12 }, authorFields.map(function (author, index) { return (React.createElement(Row, { key: author.id },
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Controller, { control: control, name: "workSpec.authors.".concat(index, ".firstName"), render: function (_a) {
                                            var _b, _c, _d, _e;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.workSpec.field.label.authors.firstName",
                                                }), error: (_e = (_d = (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.authors) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.firstName) === null || _e === void 0 ? void 0 : _e.message })));
                                        } })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Controller, { control: control, name: "workSpec.authors.".concat(index, ".lastName"), render: function (_a) {
                                            var _b, _c, _d, _e;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                    id: "ui-br-publishers.publishers.workSpec.field.label.authors.lastName",
                                                }), error: (_e = (_d = (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.authors) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.lastName) === null || _e === void 0 ? void 0 : _e.message })));
                                        } })),
                                React.createElement(Col, { xs: 2 },
                                    React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAuthor(index); }, style: { marginTop: "25px" } },
                                        React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.workSpec.button.delete" }))))); })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Button, { onClick: function () {
                                        return appendAuthor({ firstName: "", lastName: "" });
                                    } },
                                    React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.workSpec.button.authors.add" })))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.publicationDate", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(Datepicker, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.datePicker.label.publicationDate",
                                            }), error: (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.publicationDate) === null || _c === void 0 ? void 0 : _c.message, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY", placeholder: "DD/MM/YYYY", required: true })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.releaseOrder", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.field.label.releaseOrder",
                                            }), error: (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.releaseOrder) === null || _c === void 0 ? void 0 : _c.message, required: true })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Controller, { control: control, name: "workSpec.publishedInConjunction", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.field.label.publishedInConjunction",
                                            }), error: (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.publishedInConjunction) === null || _c === void 0 ? void 0 : _c.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Controller, { control: control, name: "workSpec.publicationForm", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.select.label.publicationForm",
                                            }), dataOptions: [
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.select.label.default",
                                                    }),
                                                },
                                                {
                                                    value: "PRINT",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.publicationForm.print",
                                                    }),
                                                },
                                                {
                                                    value: "EBOOK",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.publicationForm.ebook",
                                                    }),
                                                },
                                            ] })));
                                    } }))),
                        watch("workSpec.publicationForm") === "PRINT" && (React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Controller, { control: control, name: "workSpec.printedPublicationBinding", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.select.label.printedPublicationBinding",
                                            }), dataOptions: [
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.select.label.default",
                                                    }),
                                                },
                                                {
                                                    value: "SOFT",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.printedPublicationBinding.soft",
                                                    }),
                                                },
                                                {
                                                    value: "HARD",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.printedPublicationBinding.hard",
                                                    }),
                                                },
                                            ] })));
                                    } })))),
                        watch("workSpec.publicationForm") === "EBOOK" && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 12 },
                                    React.createElement("h3", null,
                                        React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.workSpec.title.publicationForm.ebooks" }))),
                                React.createElement(Col, { xs: 12 }, ebookFormatFields.map(function (formatField, index) { return (React.createElement(Row, { key: formatField.id },
                                    React.createElement(Col, { xs: 4 },
                                        React.createElement(Controller, { control: control, name: "workSpec.ebookFormats.".concat(index, ".format"), render: function (_a) {
                                                var field = _a.field;
                                                return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats",
                                                    }), onChange: function (e) {
                                                        field.onChange(e);
                                                        if (e.target.value !== "OTHER") {
                                                            setValue("workSpec.ebookFormats.".concat(index, ".otherFormat"), "");
                                                        }
                                                    }, dataOptions: [
                                                        {
                                                            value: "",
                                                            label: formatMessage({
                                                                id: "ui-br-publishers.publishers.select.label.default",
                                                            }),
                                                        },
                                                        {
                                                            value: "pdf",
                                                            label: formatMessage({
                                                                id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.pdf",
                                                            }),
                                                        },
                                                        {
                                                            value: "epub",
                                                            label: formatMessage({
                                                                id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.epub",
                                                            }),
                                                        },
                                                        {
                                                            value: "mobi",
                                                            label: formatMessage({
                                                                id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.mobi",
                                                            }),
                                                        },
                                                        {
                                                            value: "OTHER",
                                                            label: formatMessage({
                                                                id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.other",
                                                            }),
                                                        },
                                                    ] })));
                                            } })),
                                    watch("workSpec.ebookFormats.".concat(index, ".format")) ===
                                        "OTHER" && (React.createElement(Col, { xs: 4 },
                                        React.createElement(Controller, { control: control, name: "workSpec.ebookFormats.".concat(index, ".otherFormat"), render: function (_a) {
                                                var _b, _c, _d, _e;
                                                var field = _a.field;
                                                return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.otherFormat",
                                                    }), error: (_e = (_d = (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.ebookFormats) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.otherFormat) === null || _e === void 0 ? void 0 : _e.message })));
                                            } }))),
                                    React.createElement(Col, { xs: 4 },
                                        React.createElement(Controller, { control: control, name: "workSpec.ebookFormats.".concat(index, ".offerUrl"), render: function (_a) {
                                                var _b, _c, _d, _e;
                                                var field = _a.field;
                                                return (React.createElement(RecekTextField, __assign({}, field, { label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.offerUrl",
                                                    }), error: (_e = (_d = (_c = (_b = errors.workSpec) === null || _b === void 0 ? void 0 : _b.ebookFormats) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.offerUrl) === null || _e === void 0 ? void 0 : _e.message })));
                                            } })),
                                    React.createElement(Col, { xs: 2 },
                                        React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeEbookFormat(index); }, style: { marginTop: "25px" } },
                                            React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.workSpec.button.delete" }))))); })),
                                React.createElement(Col, { xs: 12 },
                                    React.createElement(Button, { onClick: function () {
                                            return appendEbookFormat({
                                                format: "",
                                                otherFormat: "",
                                                offerUrl: "",
                                            }, { shouldFocus: false });
                                        } },
                                        React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.workSpec.button.ebookFormats.add" })))))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.dataCarrierVersion", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.select.label.dataCarrierVersion",
                                            }), dataOptions: [
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.select.label.default",
                                                    }),
                                                },
                                                {
                                                    value: "CD-ROM",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.dataCarrierVersion.cd",
                                                    }),
                                                },
                                                {
                                                    value: "DVD-ROM",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.dataCarrierVersion.dvd",
                                                    }),
                                                },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "workSpec.audioBook", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: formatMessage({
                                                id: "ui-br-publishers.publishers.workSpec.select.label.audioBook",
                                            }), dataOptions: [
                                                {
                                                    value: "",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.select.label.default",
                                                    }),
                                                },
                                                {
                                                    value: "ON_MEDIA",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.audioBook.on",
                                                    }),
                                                },
                                                {
                                                    value: "WITHOUT_MEDIA",
                                                    label: formatMessage({
                                                        id: "ui-br-publishers.publishers.workSpec.select.label.audioBook.off",
                                                    }),
                                                },
                                            ] })));
                                    } }))))),
                    React.createElement(Accordion, { label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.comments",
                        }), open: accordionStatus.comments, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { comments: !accordionStatus.comments }));
                        } },
                        React.createElement(TextArea, __assign({ label: formatMessage({
                                id: "ui-br-publishers.publishers.comments.textArea.label.internalComment",
                            }) }, register("internalComment"), { onChange: handleInputChange }))))))));
};
export default PublisherCreate;
