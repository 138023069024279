import React, { useMemo, useState } from "react";
import { Row, Col, Button } from "@folio/stripes/components";
import { FormattedMessage, useIntl } from "react-intl";
import CustomAccordionSet from "../../../components/CustomAccordionSet";
import { FieldRow } from "./FieldRow";
import { OrganizationDetails } from "./OrganizationDetails";
import { useFetchUserOrganizations } from "../../../api/useFetchUserOrganizations";
import { ExtendedInformationAccordion } from "../../../components/ExtendedInformationAccordion";
var UserDetail = function (_a) {
    var user = _a.user;
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    var userOrganizationsData = useFetchUserOrganizations(user.id).data;
    var _b = useState(true), expandAll = _b[0], setExpandAll = _b[1];
    var toggleExpandCollapseAll = function () { return setExpandAll(function (prev) { return !prev; }); };
    var handleToggleExpandAll = function (expanded) { return setExpandAll(expanded); };
    var infoFields = useMemo(function () { return [
        { labelId: "ui-br-users.users.field.label.email", value: user.email },
        {
            labelId: "ui-br-users.users.field.label.firstName",
            value: user.firstName,
        },
        {
            labelId: "ui-br-users.users.field.label.lastName",
            value: user.lastName,
        },
    ]; }, [user.email, user.firstName, user.lastName]);
    var accordions = useMemo(function () { return [
        {
            id: "info",
            label: formatMessage({
                id: "ui-br-users.users.detail.accordion.label.info",
            }),
            content: (React.createElement(Row, null, infoFields.map(function (field, index) { return (React.createElement(Col, { key: index, xs: 12, md: 6 },
                React.createElement(FieldRow, { label: formatMessage({ id: field.labelId }), value: field.value }))); }))),
        },
        {
            id: "organizations",
            label: formatMessage({
                id: "ui-br-users.users.detail.accordion.label.organizations",
            }),
            content: (React.createElement(React.Fragment, null, userOrganizationsData === null || userOrganizationsData === void 0 ? void 0 : userOrganizationsData.map(function (org) { return (React.createElement(OrganizationDetails, { key: org.organization.id, org: org, intl: intl })); }))),
        },
        {
            id: "extendedInformation",
            label: formatMessage({
                id: "ui-br-users.accordions.label.extendedInformation",
            }),
            content: React.createElement(ExtendedInformationAccordion, { id: user.id }),
        },
    ]; }, [formatMessage, infoFields, intl, user.id, userOrganizationsData]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            user.firstName,
            " ",
            user.lastName),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" },
                    React.createElement(FormattedMessage, { id: expandAll
                            ? "ui-br-users.button.collapse"
                            : "ui-br-users.button.expand" })))),
        React.createElement(CustomAccordionSet, { accordions: accordions, expandAll: expandAll, onToggleExpandAll: handleToggleExpandAll })));
};
export default UserDetail;
