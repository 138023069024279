var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Pane, Select, Button, Row, Col, PaneFooter, AccordionSet, Accordion, ErrorModal, } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { RegistrationAgency } from "../../types/RegistrationAgency";
import { InternationalPrefixFormat, } from "../../types/InternationalPrefix";
import { createInternationalPrefix } from "../../api/internationalPrefixesService";
import RecekTextField from "../../components/RecekTextField";
import { navigation } from "../../components/navigation";
import { RecekPaneSet } from "../../components/RecekPaneSet";
function InternationalPrefixesCreate() {
    var _this = this;
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState({
        agency: "",
        format: "",
        value: "",
    }), formValues = _a[0], setFormValues = _a[1];
    var _b = useState({
        general: true,
    }), accordionStatus = _b[0], setAccordionStatus = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState(false), errorModalOpen = _d[0], setErrorModalOpen = _d[1];
    var _e = useState(""), errorMessage = _e[0], setErrorMessage = _e[1];
    var validateForm = function () {
        var newErrors = {};
        if (!formValues.agency) {
            newErrors.agency = "Agentura je povinná.";
        }
        if (!formValues.format) {
            newErrors.format = "Formát je povinný.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var newInternationalPrefixPayload, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    newInternationalPrefixPayload = {
                        agency: formValues.agency,
                        format: formValues.format,
                        defaultAgency: false,
                        value: formValues.value,
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createInternationalPrefix(ky, newInternationalPrefixPayload)];
                case 2:
                    _b.sent();
                    history.push(navigation.internationalPrefixes.path);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    console.error("Error creating international prefix:", error_1);
                    if (((_a = error_1.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                        setErrorMessage("Záznam s touto předponou již existuje. Zkontrolujte prosím údaje a zkuste to znovu.");
                    }
                    else {
                        setErrorMessage("Došlo k chybě při vytváření mezinárodní předpony.");
                    }
                    setErrorModalOpen(true);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.push(navigation.internationalPrefixes.path); } }, "Zru\u0161it") }));
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Vytvo\u0159it mezin\u00E1rodn\u00ED p\u0159edponu", footer: footer },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12, md: 6 },
                                React.createElement(Select, { label: "Agentura", name: "agency", value: formValues.agency || "", onChange: handleInputChange, required: true, placeholder: "Vyberte agenturu", error: errors.agency },
                                    React.createElement("option", { value: RegistrationAgency.ISBN }, "ISBN"),
                                    React.createElement("option", { value: RegistrationAgency.ISMN }, "ISMN")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12, md: 6 },
                                React.createElement(Select, { label: "Form\u00E1t", name: "format", value: formValues.format || "", onChange: handleInputChange, required: true, placeholder: "Vyberte form\u00E1t", error: errors.format },
                                    React.createElement("option", { value: InternationalPrefixFormat.ISBN10 }, "ISBN10"),
                                    React.createElement("option", { value: InternationalPrefixFormat.ISMN10 }, "ISMN10"),
                                    React.createElement("option", { value: InternationalPrefixFormat.EAN13 }, "EAN13")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12, md: 6 },
                                React.createElement(RecekTextField, { label: "Hodnota", name: "value", value: formValues.value, onChange: handleInputChange, required: true, error: errors.value })))))))));
}
export default InternationalPrefixesCreate;
