export var audiobookTranslations = {
    "": { id: "ui-br-publishers.publishers.select.label.default" },
    ON_MEDIA: {
        id: "ui-br-publishers.publishers.workSpec.select.label.audioBook.on",
    },
    WITHOUT_MEDIA: {
        id: "ui-br-publishers.publishers.workSpec.select.label.audioBook.off",
    },
};
export var getAudiobookTranslations = function (intl, key) {
    var translation = audiobookTranslations[key];
    if (!translation)
        return key;
    return intl.formatMessage(translation);
};
