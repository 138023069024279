import AgencyPrefixes from "./routes/agency-worker/AgencyPrefixes.tsx";
import InternationalPrefixes from "./routes/agency-worker/InternationalPrefixes.tsx";
import OnixCodetables from "./routes/onix/OnixCodetables.tsx";
import OnixCodetablesItems from "./routes/onix/OnixCodetablesItems.tsx";
import Collections from "./routes/collections/Collections.tsx";
import PublisherPrefixRanges from "./routes/publisher-prefix-range/PublisherPrefixRanges.tsx";

export const routesConfig = {
    collections: {
        path: "/collections",
        component: Collections,
    },
    onixCodetables: {
        path: "/onix-codetables",
        component: OnixCodetables,
    },
    onixCodetablesItems: {
        path: "/onix-codetables-items",
        component: OnixCodetablesItems,
    },
    internationalPrefixes: {
        path: "/international-prefixes",
        component: InternationalPrefixes,
    },
    agencyPrefixes: {
        path: "/agency-prefixes",
        component: AgencyPrefixes,
    },
    publisherPrefixRanges: {
        path: "/publisher-prefix-ranges",
        component: PublisherPrefixRanges,
    },
};

