import { Row, Col } from "@folio/stripes/components";
import React from "react";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
export var GeneralAccordion = function (_a) {
    var publisher = _a.publisher;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.companyNumber",
                    }), value: publisher.companyNumber })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.taxNumber.short",
                    }), value: publisher.taxNumber }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.name",
                    }), value: publisher.name })),
            React.createElement(Col, { xs: 12, md: 6 }, publisher.legalForm === "NATURAL" && (React.createElement(RenderField, { label: formatMessage({
                    id: "ui-br-publishers.publishers.general.field.label.dateOfBirth",
                }), value: publisher.birthDate })))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.nameSuffix",
                    }), value: publisher.nameSuffix })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.qualifier",
                    }), value: publisher.qualifier }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.legalForm",
                    }), value: publisher.legalForm === "LEGAL"
                        ? formatMessage({
                            id: "ui-br-publishers.publishers.general.field.label.legal",
                        })
                        : formatMessage({
                            id: "ui-br-publishers.publishers.general.field.label.natural",
                        }) })))));
};
