import React from "react";
import { Button, Layout } from "@folio/stripes/components";
import { useHistory, useLocation } from "react-router-dom";
import { navigation } from "./navigation";
var NavigationComponent = function () {
    var history = useHistory();
    var location = useLocation();
    // Determine the active route
    var currentPath = location.pathname;
    return (React.createElement(Layout, { className: "padding-start-gutter padding-end-gutter" },
        React.createElement("div", { style: {
                backgroundColor: "#f5f5f5",
                padding: "1rem",
                borderRadius: "4px",
            } },
            React.createElement("h3", { style: { fontWeight: "bold", marginBottom: "1rem" } }, "Tabulky"),
            React.createElement("div", { style: { display: "flex", flexDirection: "column", gap: "1rem" } }, Object.values(navigation).map(function (route) {
                var isActive = currentPath === route.path; // Exact match instead of startsWith
                return (React.createElement(Button, { key: route.path, onClick: function () { return history.push(route.path); }, buttonStyle: isActive ? "primary" : "default", fullWidth: true, marginBottom0: true, style: { justifyContent: "flex-start" } },
                    React.createElement("span", { style: { marginLeft: "0.5rem" } }, route.label)));
            })))));
};
export default NavigationComponent;
