import React from "react";
import { Button, ButtonGroup, Layout } from "@folio/stripes/components";
import { useHistory, useLocation } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { useIntl } from "react-intl";
import { hasAtLeastOneRoleAndDontHaveOther, useActiveRoles, } from "../utils/permissionsUtils";
import { RoleEnum } from "../types/RoleEnum";
var NavigationComponent = function () {
    var history = useHistory();
    var location = useLocation();
    var activeRoles = useActiveRoles(useOkapiKy());
    var intl = useIntl();
    var routes = [
        {
            path: "/brpublishers/ares-updates",
            label: intl.formatMessage({ id: "ui-br-publishers.ares.title" }),
            requiredRole: [RoleEnum.AGENCY_WORKER],
            notRequired: "",
        },
        {
            path: "/brpublishers/publisher-registrations",
            label: intl.formatMessage({
                id: "ui-br-publishers.publisher-registrations.title",
            }),
            requiredRole: [RoleEnum.AGENCY_WORKER],
            notRequired: "",
        },
        {
            path: "/brpublishers/publisher-updates",
            label: intl.formatMessage({
                id: "ui-br-publishers.publisher-updates.title",
            }),
            requiredRole: [RoleEnum.AGENCY_WORKER],
            notRequired: "",
        },
        {
            path: "/brpublishers/publishers",
            label: intl.formatMessage({ id: "ui-br-publishers.publishers.title" }),
            requiredRole: [RoleEnum.AGENCY_WORKER],
            notRequired: "",
            defaultParams: { status: "ACTIVE" },
        },
        {
            path: "/brpublishers/my-publisher",
            label: intl.formatMessage({
                id: "ui-br-publishers.my-publisher.pane.title",
            }),
            requiredRole: [RoleEnum.PUBLISHER_ADMIN, RoleEnum.PUBLISHER_GUARANTOR],
            notRequired: RoleEnum.SUPERUSER,
        },
        {
            path: "/brpublishers/admin/environment-variables",
            label: intl.formatMessage({ id: "ui-br-publishers.admin.title" }),
            requiredRole: [RoleEnum.SUPERUSER],
            notRequired: "",
        },
    ];
    var currentPath = location.pathname;
    var handleRouteClick = function (route) {
        var path = route.path;
        if (route.defaultParams) {
            var params = new URLSearchParams(route.defaultParams).toString();
            path += "?".concat(params);
        }
        history.push(path);
    };
    return (React.createElement(Layout, { className: "padding-start-gutter padding-end-gutter" },
        React.createElement("style", null, "\n          .nav-container-outer {\n            container-type: inline-size;\n          }\n          .horizontal-layout {\n            display: block;\n          }\n          .vertical-layout {\n            display: none;\n          }\n          @container (max-width: 460px) {\n            .horizontal-layout {\n              display: none;\n            }\n            .vertical-layout {\n              display: block;\n            }\n          }\n        "),
        React.createElement("div", { className: "nav-container-outer" },
            React.createElement("div", { className: "horizontal-layout" },
                React.createElement(ButtonGroup, { style: { marginBottom: "1rem" }, fullWidth: true }, routes.map(function (route) {
                    var isActive = currentPath.startsWith(route.path);
                    return (hasAtLeastOneRoleAndDontHaveOther(activeRoles, route.requiredRole, route.notRequired) && (React.createElement(Button, { key: route.path, onClick: function () { return handleRouteClick(route); }, style: {
                            backgroundColor: isActive ? "#1960a4" : "",
                            color: isActive ? "#fff" : "",
                            fontWeight: isActive ? "bold" : "",
                        } }, route.label)));
                }))),
            React.createElement("div", { className: "vertical-layout" },
                React.createElement("div", { style: {
                        backgroundColor: "#f5f5f5",
                        padding: "1rem",
                        borderRadius: "4px",
                    } },
                    React.createElement("div", { style: { display: "flex", flexDirection: "column", gap: "1rem" } }, routes.map(function (route) {
                        var isActive = currentPath === route.path;
                        return (hasAtLeastOneRoleAndDontHaveOther(activeRoles, route.requiredRole, route.notRequired) && (React.createElement(Button, { key: route.path, onClick: function () { return history.push(route.path); }, buttonStyle: isActive ? "primary" : "default", fullWidth: true, marginBottom0: true, style: { justifyContent: "flex-start" } }, route.label)));
                    })))))));
};
export default NavigationComponent;
