import { Row, Col } from "@folio/stripes/components";
import React from "react";
import { useIntl } from "react-intl";
import RenderField from "./RenderField";
export var ExtendedInformationAccordion = function (_a) {
    var id = _a.id;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Row, null,
        React.createElement(Col, { xs: 12 },
            React.createElement(RenderField, { label: formatMessage({
                    id: "ui-br-publishers.accordions.label.id",
                }), value: id }))));
};
