import React from "react";
import { Row, Col } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { getAltNameTranslation } from "../../../utils/getAltNameTranslation";
export var AlternativeNamesSection = function (_a) {
    var alternativeNames = _a.alternativeNames;
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    if (!alternativeNames || alternativeNames.length === 0) {
        return (React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement("div", null, "\u017D\u00E1dn\u00E9 alternativn\u00ED n\u00E1zvy k zobrazen\u00ED"))));
    }
    return (React.createElement(React.Fragment, null, alternativeNames.map(function (altName, index) {
        var _a;
        return (React.createElement(Row, { key: index },
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.alternativeNames.field.label.name",
                    }), value: altName.name })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.nameSuffix",
                    }), value: altName.nameSuffix })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.qualifier",
                    }), value: altName.qualifier })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: "Typ", value: getAltNameTranslation(intl, (_a = altName.type) !== null && _a !== void 0 ? _a : "") })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.alternativeNames.checkbox.label.nonPublic",
                    }), value: altName.nonPublic ? "Ano" : "Ne" }))));
    })));
};
