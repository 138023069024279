var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, IconButton, Select, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { FormattedMessage, useIntl } from "react-intl";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import { fetchPublisherData, searchPublishers, } from "../../api/publisherService";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import PublisherDetail from "../publishers/PublisherDetail";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { formatDate } from "../../utils/dateTimeUtil";
import { DataListPane } from "../../components/DataListPane";
var PAGE_SIZE = 20;
var DEFAULT_FILTERS = {
    registrationIntent: "ONE_OFF",
    status: ["REQUEST"],
};
var PublisherRegistrationsList = function () {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var formatMessage = useIntl().formatMessage;
    var _b = useState(null), selectedPublisherRegister = _b[0], setSelectedPublisherRegister = _b[1];
    var _c = useState(true), filterPaneIsVisible = _c[0], setFilterPaneIsVisible = _c[1];
    var _d = useState(0), pageOffset = _d[0], setPageOffset = _d[1];
    var _e = useState(0), totalRecords = _e[0], setTotalRecords = _e[1];
    var _f = useState(DEFAULT_FILTERS), filters = _f[0], setFilters = _f[1];
    var _g = useState("ONE_OFF"), registrationIntentFilter = _g[0], setRegistrationIntentFilter = _g[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var publisherId = queryParams.get("detail");
        if (publisherId) {
            fetchPublisherData(publisherId, ky).then(function (publisher) {
                if (publisher) {
                    setSelectedPublisherRegister(publisher);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.registrationIntent)
            params.set("registrationIntent", filters.registrationIntent);
        history.push({ search: params.toString() });
    };
    var _h = useQuery(["publishers", filters, pageOffset], function () {
        return searchPublishers(ky, __assign(__assign({}, filters), { offset: pageOffset, limit: PAGE_SIZE }));
    }, {
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _h.data, isLoading = _h.isLoading, isError = _h.isError, refetch = _h.refetch, isFetched = _h.isFetched;
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var publisherRegistrationsData = (_a = data === null || data === void 0 ? void 0 : data.publishers) !== null && _a !== void 0 ? _a : [];
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: formatMessage({ id: "ui-br-publishers.registration.intent" }),
            key: "registrationIntent",
            cellRenderer: function (item) {
                var _a;
                return formatMessage({
                    id: "ui-br-publishers.registration.intent.".concat((_a = item.registrationIntent) === null || _a === void 0 ? void 0 : _a.toLowerCase()),
                });
            },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.name",
            }),
            key: "name",
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedPublisherRegister(item);
            },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publisher.registrations.table.dateOfRegistration",
            }),
            key: "createTime",
            cellRenderer: function (item) {
                return item.createTime ? formatDate(item.createTime) : "N/A";
            },
        },
    ];
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return publisherRegistrationsData; };
    source.resultCount = function () { return publisherRegistrationsData.length; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isFetched; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = fetchMoreData;
    return (React.createElement(RecekPaneSet, null,
        filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(NavComponent, null),
                React.createElement("form", { onSubmit: function (e) {
                        e.preventDefault();
                        var payload = __assign(__assign({}, filters), { registrationIntent: registrationIntentFilter });
                        setPageOffset(0);
                        setFilters(payload);
                        updateUrlWithFilters(payload);
                    } },
                    React.createElement(Select, { label: formatMessage({
                            id: "ui-br-publishers.registration.intent",
                        }), dataOptions: [
                            {
                                label: formatMessage({
                                    id: "ui-br-publishers.registration.intent.one_off",
                                }),
                                value: "ONE_OFF",
                            },
                            {
                                label: formatMessage({
                                    id: "ui-br-publishers.registration.intent.continuous",
                                }),
                                value: "CONTINUOUS",
                            },
                        ], value: registrationIntentFilter, onChange: function (e) {
                            return setRegistrationIntentFilter(e.target.value);
                        } }),
                    React.createElement(Button, { type: "submit", marginBottom0: true },
                        React.createElement(FormattedMessage, { id: "ui-br-publishers.button.apply" })))))),
        React.createElement(DataListPane, { paneId: "contentPane", paneTitle: formatMessage({
                id: "ui-br-publishers.publisher.registrations.pane.title",
            }), paneSub: formatMessage({ id: "ui-br-publishers.publishers.table.subtitle.records-found" }, { count: totalRecords }), filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane },
            React.createElement(TableComponent, { data: publisherRegistrationsData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, onNeedMoreData: function (offset) {
                    fetchMoreData(offset);
                }, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source, pagingType: "prev-next" })),
        selectedPublisherRegister && (React.createElement(Pane, { id: "detailPane", defaultWidth: "35%", paneTitle: "Detail Registrace nakladatele", onClose: function () {
                setSelectedPublisherRegister(null);
                var queryParams = new URLSearchParams(location.search);
                queryParams.delete("detail");
                history.replace({ search: queryParams.toString() });
            }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                        return history.push("/brpublishers/publisher-registrations/approval/".concat(selectedPublisherRegister.id));
                    }, fullWidth: true }, "Schv\u00E1lit / Zam\u00EDtnout")),
                hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                        return history.push("/brpublishers/publisher-registrations/restore/".concat(selectedPublisherRegister.id));
                    }, fullWidth: true }, "Obnovit")))); } }, selectedPublisherRegister ? (React.createElement(PublisherDetail, { publisher: selectedPublisherRegister })) : (React.createElement("div", null, "No details available"))))));
};
export default PublisherRegistrationsList;
