import { Row, Col, Checkbox } from "@folio/stripes/components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getRolesTranslations } from "../../../utils/getRolesTranslations";
import { FieldRow } from "./FieldRow";
export var OrganizationDetails = function (_a) {
    var org = _a.org, intl = _a.intl;
    var roles = ["PublisherAdmin", "PublisherGuarantor", "AgencyWorker"];
    return (React.createElement(Row, { key: org.organization.id, style: { marginBottom: "25px" } },
        React.createElement(Col, { xs: 12, md: 6 },
            React.createElement(FieldRow, { label: intl.formatMessage({
                    id: "ui-br-users.users.organization.field.label.name",
                }), value: org.organization.name })),
        React.createElement(Col, { xs: 12, md: 6 },
            React.createElement(Checkbox, { label: intl.formatMessage({
                    id: "ui-br-users.users.organization.checkbox.label.archived",
                }), name: "archived", disabled: true, checked: org.archived })),
        React.createElement(Col, { xs: 12 },
            React.createElement(Row, { style: { marginBottom: "25px", marginTop: "25px" } },
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", { style: { marginBottom: "5px" } },
                        React.createElement(FormattedMessage, { id: "ui-br-users.users.organization.title.roles" }, function (txt) { return (React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, txt)); })),
                    React.createElement("div", null, roles.map(function (role) {
                        var _a;
                        return (React.createElement(Checkbox, { key: role, label: getRolesTranslations(intl, role), name: role.charAt(0).toLowerCase() + role.slice(1), disabled: true, checked: (_a = org.roles) === null || _a === void 0 ? void 0 : _a.includes(role) }));
                    })))))));
};
