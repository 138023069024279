import React, { useEffect, useState } from "react";
import { useOkapiKy } from "@folio/stripes/core";
import { Pane } from "@folio/stripes/components";
import { FormattedMessage } from "react-intl";
import { fetchEnvironmentVariables } from "../../api/adminService";
import NavComponent from "../../components/NavComponent";
import { RecekPaneSet } from "../../components/RecekPaneSet";
function AdminEnvironmentVariables() {
    var ky = useOkapiKy();
    var _a = useState([]), environmentVariables = _a[0], setEnvironmentVariables = _a[1];
    useEffect(function () {
        fetchEnvironmentVariables(ky).then(function (response) {
            setEnvironmentVariables(response.environmentVariables);
        });
    }, []);
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace" },
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(NavComponent, null))),
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: React.createElement(FormattedMessage, { id: "ui-br-publishers.admin.title" }) },
            React.createElement(FormattedMessage, { id: "ui-br-publishers.admin.description" }),
            React.createElement(FormattedMessage, { id: "ui-br-publishers.admin.title.environment.variables", tagName: "h1" }),
            React.createElement("ul", null, environmentVariables.map(function (environmentVariable, index) { return (React.createElement("li", { key: index },
                React.createElement("strong", null,
                    environmentVariable.name,
                    ":"),
                " ",
                environmentVariable.value)); })))));
}
export default AdminEnvironmentVariables;
