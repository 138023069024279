import React from "react";
import { Row, Col } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { formatDateTime } from "../../../utils/dateTimeUtil";
export var TimestampsSection = function (_a) {
    var createTime = _a.createTime, activationTime = _a.activationTime, dataConfirmationTime = _a.dataConfirmationTime, closeTime = _a.closeTime, status = _a.status;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.timestamps.field.label.createTime",
                    }), value: formatDateTime(createTime) })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.timestamps.field.label.activationTime",
                    }), value: formatDateTime(activationTime) }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.timestamps.field.label.dataConfirmationTime",
                    }), value: formatDateTime(dataConfirmationTime) })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.timestamps.field.label.closeTime",
                    }), value: formatDateTime(closeTime) }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.timestamps.field.label.status",
                    }), value: formatMessage({
                        id: "ui-br-publishers.status.".concat(status.toLowerCase()),
                    }) })))));
};
