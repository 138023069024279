var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Pane, Button, Row, Col, PaneFooter, ErrorModal, Modal, } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useMutation } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useIntl } from "react-intl";
import { createPasswordSchema } from "../../utils/validationUtils";
import { changeUserPassword, fetchCurrentUser } from "../../api/usersService";
import { ErrorType } from "../../types/ErrorType";
import { RecekPaneSet } from "../../components/RecekPaneSet";
function MyProfilePassword() {
    var _this = this;
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var _a = useForm({
        resolver: zodResolver(createPasswordSchema(intl)),
        mode: "onBlur",
    }), control = _a.control, handleSubmit = _a.handleSubmit, errors = _a.formState.errors;
    var _b = useState(null), userId = _b[0], setUserId = _b[1];
    var _c = useState(false), shouldChangePassword = _c[0], setShouldChangePassword = _c[1];
    var _d = useState(false), errorModalOpen = _d[0], setErrorModalOpen = _d[1];
    var _e = useState(""), errorMessage = _e[0], setErrorMessage = _e[1];
    var isFetchingUserId = useRef(false);
    var _f = useState(false), showCurrentPassword = _f[0], setShowCurrentPassword = _f[1];
    var _g = useState(false), showNewPassword = _g[0], setShowNewPassword = _g[1];
    useEffect(function () {
        var fetchUserId = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (isFetchingUserId.current)
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        isFetchingUserId.current = true;
                        return [4 /*yield*/, fetchCurrentUser(ky)];
                    case 2:
                        response = _b.sent();
                        if ((_a = response === null || response === void 0 ? void 0 : response.user) === null || _a === void 0 ? void 0 : _a.id) {
                            setUserId(response.user.id);
                            setShouldChangePassword(response.user.shouldChangePassword);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        console.error("Error fetching user ID:", error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        isFetchingUserId.current = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        if (!userId) {
            fetchUserId();
        }
    }, [ky, userId]);
    var changePassword = useMutation(function (data) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changeUserPassword(ky, userId !== null && userId !== void 0 ? userId : "", {
                        currentPassword: data.currentPassword,
                        newPassword: data.newPassword,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, {
        onSuccess: function () {
            history.push("/brusers/my-profile");
        },
        onError: function (error) { return __awaiter(_this, void 0, void 0, function () {
            var errMsg, errorWithResponse, response, _i, _a, err;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.error("Failed to change password:", error);
                        errMsg = "";
                        if (!(error && typeof error === "object" && "response" in error)) return [3 /*break*/, 2];
                        errorWithResponse = error;
                        if (!errorWithResponse.response) return [3 /*break*/, 2];
                        return [4 /*yield*/, errorWithResponse.response.json()];
                    case 1:
                        response = _b.sent();
                        if (Array.isArray(response.errors)) {
                            for (_i = 0, _a = response.errors; _i < _a.length; _i++) {
                                err = _a[_i];
                                console.log("Response error message:", err.message);
                                if (err.type === ErrorType.AUTHENTICATION_PASSWORD_NOT_VALID) {
                                    errMsg = intl.formatMessage({
                                        id: "ui-br-users.my-profile.password.incorrect",
                                    });
                                    break;
                                }
                            }
                        }
                        _b.label = 2;
                    case 2:
                        if (!errMsg) {
                            errMsg = "Do\u0161lo k chyb\u011B p\u0159i aktualizaci hesla: ".concat(error);
                        }
                        setErrorMessage(errMsg);
                        setErrorModalOpen(true);
                        return [2 /*return*/];
                }
            });
        }); },
    }).mutateAsync;
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, changePassword(data)];
                case 1:
                    _a.sent();
                    console.log("Password changed successfully");
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error changing password:", error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var renderForm = function () { return (React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { marginBottom: "20px" } },
                React.createElement("strong", null, "Sou\u010Dasn\u00E9 Heslo"),
                React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                    React.createElement(Controller, { name: "currentPassword", control: control, defaultValue: "", render: function (_a) {
                            var field = _a.field;
                            return (React.createElement("input", __assign({ type: showCurrentPassword ? "text" : "password" }, field, { style: {
                                    width: "100%",
                                    padding: "3px",
                                    marginTop: "5px",
                                    borderColor: errors.currentPassword ? "red" : "",
                                } })));
                        } }),
                    React.createElement(Button, { onClick: function () { return setShowCurrentPassword(function (prev) { return !prev; }); }, style: { marginLeft: "10px" } }, showCurrentPassword ? "Skrýt" : "Zobrazit")),
                errors.currentPassword && (React.createElement("div", { style: { color: "red", marginTop: "5px" } }, errors.currentPassword.message))),
            React.createElement(Col, { xs: 12, style: { marginBottom: "20px" } },
                React.createElement("strong", null, "Nov\u00E9 Heslo"),
                React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                    React.createElement(Controller, { name: "newPassword", control: control, defaultValue: "", render: function (_a) {
                            var field = _a.field;
                            return (React.createElement("input", __assign({ type: showNewPassword ? "text" : "password" }, field, { style: {
                                    width: "100%",
                                    padding: "3px",
                                    marginTop: "5px",
                                    borderColor: errors.newPassword ? "red" : "",
                                } })));
                        } }),
                    React.createElement(Button, { onClick: function () { return setShowNewPassword(function (prev) { return !prev; }); }, style: { marginLeft: "10px" } }, showNewPassword ? "Skrýt" : "Zobrazit")),
                errors.newPassword && (React.createElement("div", { style: { color: "red", marginTop: "5px" } }, errors.newPassword.message)))))); };
    var renderErrorModal = function () { return (React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
        React.createElement("div", null, errorMessage),
        React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt"))); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", disabled: shouldChangePassword, onClick: function () { return history.goBack(); }, fullWidth: true }, "Zp\u011Bt") }));
    if (!shouldChangePassword) {
        return (React.createElement(RecekPaneSet, null,
            renderErrorModal(),
            React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Zm\u011Bnit Heslo", footer: footer }, renderForm())));
    }
    else {
        return (React.createElement(RecekPaneSet, null,
            renderErrorModal(),
            React.createElement(Modal, { open: true, label: "Zm\u011Bnit Heslo", footer: React.createElement(React.Fragment, null,
                    React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit")) }, renderForm())));
    }
}
export default MyProfilePassword;
