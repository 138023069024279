var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback } from "react";
import { Pane, Button, Row, Col, Select, AccordionSet, Accordion, TextArea, Checkbox, Selection, ErrorModal, PaneFooter, } from "@folio/stripes/components";
import { useQuery } from "react-query";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useHistory, useParams } from "react-router";
import { useOkapiKy } from "@folio/stripes/core";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import { createAresSchema } from "../../utils/validationUtils";
import { fetchAddress, fetchAddressCode, fetchRegions, } from "../../api/publisherService";
import { fetchAresUpdatesData, updateAres } from "../../api/aresService";
import RecekTextField from "../../components/RecekTextField";
import { RecekPaneSet } from "../../components/RecekPaneSet";
var AresUpdateEdit = function () {
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var formatMessage = useIntl().formatMessage;
    var aresSchema = createAresSchema(formatMessage);
    var _a = useForm({
        resolver: zodResolver(aresSchema),
        shouldFocusError: false,
        defaultValues: {
            companyNumber: "",
            name: "",
            nameSuffix: "",
            qualifier: "",
            legalForm: "LEGAL",
            taxNumber: "",
            birthDate: "",
            mainAddress: {
                addressCode: "",
                street: "",
                postalCode: "",
                municipality: "",
                region: { id: "", name: "" },
            },
            mailingAddress: {
                addressCode: "",
                street: "",
                postalCode: "",
                municipality: "",
                region: { id: "", name: "" },
            },
            guarantors: [],
            contacts: [],
            alternativeNames: [],
            internalComment: "",
            version: 0,
        },
        mode: "onBlur",
    }), handleSubmit = _a.handleSubmit, register = _a.register, control = _a.control, setValue = _a.setValue, getValues = _a.getValues, reset = _a.reset, errors = _a.formState.errors;
    var _b = useState({
        companyNumber: "",
        name: "",
        taxNumber: "",
        addressCode: "",
    }), aresFormValues = _b[0], setAresFormValues = _b[1];
    var _c = useState(false), registration = _c[0], setRegistration = _c[1];
    var _d = useState(false), expandAll = _d[0], setExpandAll = _d[1];
    var _e = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
    }), accordionStatus = _e[0], setAccordionStatus = _e[1];
    var _f = useState(false), errorModalOpen = _f[0], setErrorModalOpen = _f[1];
    var _g = useState(""), errorMessage = _g[0], setErrorMessage = _g[1];
    var _h = useState([]), regions = _h[0], setRegions = _h[1];
    var _j = useState({
        addressCode: "",
        municipality: "",
        postalCode: "",
        region: {
            id: "",
            name: "",
        },
        street: "",
    }), aresAddress = _j[0], setAresAddress = _j[1];
    var _k = useState([]), addressData = _k[0], setAddressData = _k[1];
    var _l = useState(false), addressLoading = _l[0], setAddressLoading = _l[1];
    var _m = useState(""), addressQuery = _m[0], setAddressQuery = _m[1];
    var _o = useFieldArray({
        control: control,
        name: "alternativeNames",
    }), alternativeNameFields = _o.fields, appendAlternativeName = _o.append, removeAlternativeNameAtIndex = _o.remove;
    var aresData = useQuery(["ares-update-requests", id], function () { return fetchAresUpdatesData(id, ky); }, { enabled: !!id }).data;
    useQuery("regions", function () { return fetchRegions(ky); }, {
        onSuccess: function (data) {
            setRegions(data);
        },
    });
    useEffect(function () {
        if (aresData) {
            reset(aresData.publisher);
            setAresFormValues(aresData.data);
            if (aresData.publisher.status === "REQUEST") {
                setRegistration(true);
            }
        }
    }, [aresData, reset]);
    useEffect(function () {
        var _a;
        if (aresFormValues) {
            fetchAddressCode(ky, (_a = aresFormValues.addressCode) !== null && _a !== void 0 ? _a : "")
                .then(function (publisherAddress) {
                setAresAddress(publisherAddress);
            })
                .catch(function (error) {
                console.error("Error fetching ares address:", error);
                setAddressData([]);
            });
        }
    });
    useEffect(function () {
        fetchAddress(ky, addressQuery)
            .then(function (publisherAddresses) {
            setAddressData(publisherAddresses);
        })
            .catch(function (error) {
            console.error("Error fetching addresses:", error);
            setAddressData([]);
        });
    }, [addressQuery]);
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        // Ensure name is a valid form key
        if (name in getValues()) {
            setValue(name, value, { shouldValidate: true });
        }
    };
    var handleAddressSelection = function (selectedValue, type) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails = _a.sent();
                    setValue("".concat(type, ".addressCode"), selectedValue);
                    setValue("".concat(type, ".street"), addressDetails.street);
                    setValue("".concat(type, ".postalCode"), addressDetails.postalCode);
                    setValue("".concat(type, ".municipality"), addressDetails.municipality);
                    setValue("".concat(type, ".region"), addressDetails.region || { id: "", name: "" });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching ".concat(type, " details:"), error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var debouncedSetAddressQuery = useCallback(debounce(function (inputValue) {
        setAddressQuery(inputValue);
    }, 300), []);
    var handleAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressLoading(true);
                    return [4 /*yield*/, debouncedSetAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var toggleExpandCollapseAll = function () {
        setExpandAll(!expandAll);
        setAccordionStatus({
            general: !expandAll,
            address: !expandAll,
            contacts: !expandAll,
            alternativeNames: !expandAll,
            comments: !expandAll,
        });
    };
    var onSubmit = function (data, status) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateAres(ky, id, __assign(__assign({}, data), { status: status }))];
                case 1:
                    _a.sent();
                    history.goBack();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error updating publisher:", error_2);
                    setErrorMessage("Došlo k chybě při editaci nakladatele. Chyba ze serveru: " + error_2);
                    setErrorModalOpen(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "\u00DAprava nakladatele", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit(function (data) { return onSubmit(data, "ACTIVE"); }) }, registration ? "Schválit" : "Uložit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: registration
                        ? handleSubmit(function (data) { return onSubmit(data, "REJECTED"); })
                        : function () { return history.goBack(); } }, registration ? "Zamítnout" : "Zrušit") }) },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { onSubmit: function (e) { return e.preventDefault(); } },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "companyNumber", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "I\u010CO", required: true }, field, { error: (_b = errors.companyNumber) === null || _b === void 0 ? void 0 : _b.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "I\u010CO z registru", name: "companyNumber", disabled: true, value: aresFormValues.companyNumber, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "name", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "N\u00E1zev" }, field, { required: true, error: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "N\u00E1zev z registru", name: "name", disabled: true, value: aresFormValues.name, onChange: handleInputChange }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "nameSuffix", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.nameSuffix",
                                            }) }, field)));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "legalForm", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Forma" }),
                                            React.createElement("option", { value: "UNKNOWN" }, formatMessage({
                                                id: "ui-br-publishers.publishers.general.select.legal.form.unknown",
                                            })),
                                            React.createElement("option", { value: "LEGAL" }, formatMessage({
                                                id: "ui-br-publishers.publishers.general.select.label.legal",
                                            })),
                                            React.createElement("option", { value: "NATURAL" }, formatMessage({
                                                id: "ui-br-publishers.publishers.general.select.label.natural",
                                            }))));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Forma z registru", name: "legalForm", disabled: true, value: aresFormValues.legalForm, onChange: handleInputChange },
                                    React.createElement("option", { value: "LEGAL" }, "Pr\u00E1vnick\u00E1 osoba"),
                                    React.createElement("option", { value: "PHYSICAL" }, "Fyzick\u00E1 osoba")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "taxNumber", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "\u010C\u00EDslo DI\u010C" }, field, { required: true, error: (_b = errors.taxNumber) === null || _b === void 0 ? void 0 : _b.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "\u010C\u00EDslo DI\u010C z registru", name: "taxNumber", disabled: true, value: aresFormValues.taxNumber, onChange: handleInputChange })))),
                    React.createElement(Accordion, { label: "Adresa S\u00EDdla", open: accordionStatus.address, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.addressCode", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Selection, __assign({}, field, { label: "Adresa", disabled: addressLoading, asyncFilter: true, onFilter: handleAddressInput, dataOptions: addressData.map(function (address) { return ({
                                                value: address.addressCode,
                                                label: address.address,
                                            }); }), onChange: function (selectedValue) {
                                                field.onChange(selectedValue);
                                                handleAddressSelection(selectedValue, "mainAddress");
                                            } })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Selection, { label: "Adresa z registru", name: "publisherAddress", value: aresData === null || aresData === void 0 ? void 0 : aresData.data.addressCode, onChange: handleAddressSelection, asyncFilter: true, required: true, disabled: true, onFilter: handleAddressInput, dataOptions: __spreadArray([], addressData.map(function (address) { return ({
                                        value: address.addressCode,
                                        label: address.address,
                                    }); }), true) })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.region.id", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Kraj", required: true, error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.message, dataOptions: __spreadArray([
                                                { value: "", label: "--- Vyberte kraj ---" }
                                            ], regions.map(function (region) { return ({
                                                value: region.id,
                                                label: region.name,
                                            }); }), true), onChange: function (e) {
                                                var selectedRegion = regions.find(function (region) { return region.id === e.target.value; });
                                                setValue("mainAddress.region", selectedRegion || { id: "", name: "" });
                                            } })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Kraj z registru", name: "mainAddressRegion", required: true, disabled: true, value: aresAddress.region.id, dataOptions: __spreadArray([
                                        { value: "", label: "--- Vyberte kraj ---" }
                                    ], regions.map(function (region) { return ({
                                        value: region.id,
                                        label: region.name,
                                    }); }), true) })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Controller, { control: control, name: "mainAddress.street", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Ulice", error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(RecekTextField, { label: "Ulice z registru", name: "street", value: aresAddress.street, onChange: function () { }, disabled: true, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.postalCode", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "PS\u010C", error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.postalCode) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "PS\u010C z registru", name: "postalCode", value: aresAddress.postalCode, disabled: true, onChange: function () { }, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.municipality", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Obec", error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.municipality) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "Obec z registru", name: "municipality", value: aresAddress.municipality, disabled: true, onChange: function () { }, required: true })))),
                    React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames }));
                        } },
                        alternativeNameFields.map(function (field, index) { return (React.createElement(Row, { key: field.id },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".name"), defaultValue: field.name, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Alternativn\u00ED n\u00E1zev", required: true, error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nameSuffix"), defaultValue: field.nameSuffix, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "P\u0159\u00EDpona n\u00E1zvu", error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.nameSuffix) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".qualifier"), defaultValue: field.qualifier, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Kvalifik\u00E1tor", error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.qualifier) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".type"), defaultValue: field.type, render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Typ", dataOptions: [
                                                { value: "PREVIOUS", label: "Předchozí název" },
                                                { value: "IMPRINT", label: "Imprint" },
                                                { value: "ABBREVIATION", label: "Zkratka" },
                                                {
                                                    value: "ORGANIZATION_UNIT",
                                                    label: "Organizační jednotka",
                                                },
                                                {
                                                    value: "FOREIGN_LANGUAGE",
                                                    label: "Cizojazyčný ekvivalent",
                                                },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nonPublic"), defaultValue: field.nonPublic, render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Checkbox, { checked: field.value, onChange: function (e) { return field.onChange(e.target.checked); }, label: "Neve\u0159ejn\u00E9" }));
                                    } })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeNameAtIndex(index); }, style: { marginTop: "10px" } }, "Odstranit alternativn\u00ED n\u00E1zev")))); }),
                        React.createElement(Button, { onClick: function () {
                                return appendAlternativeName({
                                    name: "",
                                    nameSuffix: "",
                                    qualifier: "",
                                    type: undefined,
                                    nonPublic: false,
                                }, { shouldFocus: false });
                            } }, "P\u0159idat alternativn\u00ED n\u00E1zev")),
                    React.createElement(Accordion, { label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { comments: !accordionStatus.comments }));
                        } },
                        React.createElement(TextArea, __assign({ label: "Pozn\u00E1mka" }, register("internalComment"), { onChange: handleInputChange }))))))));
};
export default AresUpdateEdit;
