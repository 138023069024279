export var contentOnlyPane = {
    contentPane: "fill",
};
export var contentAndMenuPane = {
    menuPane: "27%",
    contentPane: "fill",
};
export var allPanes = {
    menuPane: "27%",
    contentPane: "fill",
    detailPane: "35%",
};
export var contentAndDetailPane = {
    contentPane: "fill",
    detailPane: "35%",
};
export var panes = [
    contentOnlyPane,
    contentAndMenuPane,
    allPanes,
    contentAndDetailPane,
];
