var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Button } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useIntl } from "react-intl";
import { fetchAddressCode } from "../../../api/publisherService";
import CustomAccordionSet from "../../../components/CustomAccordionSet";
import { RegistryDataAccordion } from "./RegistryDataAccordion";
import { GeneralAccordion } from "./GeneralAccordion";
import { AddressAccordion } from "./AddressAccordion";
import { ContactsAccordion } from "./ContactsAccordion";
import { CommentsAccordion } from "./CommentsAccordion";
import { TimestampsAccordion } from "./TimestampsAccordion";
import { ExtendedInformationAccordion } from "../../../components/ExtendedInformationAccordion";
var AresUpdatesDetail = function (_a) {
    var _b;
    var ares = _a.ares;
    var publisher = ares.publisher, data = ares.data, id = ares.id;
    var ky = useOkapiKy();
    var formatMessage = useIntl().formatMessage;
    var _c = useState(true), expandAll = _c[0], setExpandAll = _c[1];
    var _d = useState(null), addressData = _d[0], setAddressData = _d[1];
    useEffect(function () {
        var loadAddress = function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetchedAddress, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetchAddressCode(ky, (_a = data.addressCode) !== null && _a !== void 0 ? _a : "")];
                    case 1:
                        fetchedAddress = _b.sent();
                        setAddressData(fetchedAddress);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error("Failed to fetch address data:", error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        loadAddress();
    }, [data.addressCode, ky]);
    var toggleExpandCollapseAll = function () { return setExpandAll(function (prev) { return !prev; }); };
    var handleToggleExpandAll = function (expanded) { return setExpandAll(expanded); };
    var accordions = [
        {
            id: "ares",
            label: formatMessage({
                id: "ui-br-publishers.ares.accordions.label.registryData",
            }),
            content: (React.createElement(RegistryDataAccordion, { data: data, publisher: publisher, addressData: addressData })),
        },
        {
            id: "general",
            label: formatMessage({
                id: "ui-br-publishers.publishers.general.accordions.label.general",
            }),
            content: React.createElement(GeneralAccordion, { publisher: publisher }),
        },
        {
            id: "address",
            label: formatMessage({
                id: "ui-br-publishers.publishers.general.accordions.label.address",
            }),
            content: React.createElement(AddressAccordion, { publisher: publisher }),
        },
        {
            id: "contacts",
            label: formatMessage({
                id: "ui-br-publishers.publishers.general.accordions.label.contacts",
            }),
            content: React.createElement(ContactsAccordion, { contacts: publisher.contacts }),
        },
        {
            id: "comments",
            label: formatMessage({
                id: "ui-br-publishers.ares.accordions.label.comments",
            }),
            content: React.createElement(CommentsAccordion, { comment: (_b = publisher.internalComment) !== null && _b !== void 0 ? _b : "" }),
        },
        {
            id: "timestamps",
            label: formatMessage({
                id: "ui-br-publishers.ares.accordions.label.timestamps",
            }),
            content: React.createElement(TimestampsAccordion, { publisher: publisher }),
        },
        {
            id: "extendedInformation",
            label: formatMessage({
                id: "ui-br-publishers.accordions.label.extendedInformation",
            }),
            content: React.createElement(ExtendedInformationAccordion, { id: id }),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            publisher.name,
            publisher.nameSuffix ? ", ".concat(publisher.nameSuffix) : ""),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll
                    ? formatMessage({ id: "ui-br-publishers.button.collapse.all" })
                    : formatMessage({ id: "ui-br-publishers.button.expand.all" })))),
        React.createElement(CustomAccordionSet, { accordions: accordions, expandAll: expandAll, onToggleExpandAll: handleToggleExpandAll }),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(Checkbox, { label: formatMessage({
                        id: "ui-br-publishers.ares.checkbox.label.consentToPublic",
                    }), checked: false, disabled: true })))));
};
export default AresUpdatesDetail;
