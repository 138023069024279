import { Row, Col } from "@folio/stripes/components";
import React from "react";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
export var CommentsAccordion = function (_a) {
    var comment = _a.comment;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Row, null,
        React.createElement(Col, { xs: 12 },
            React.createElement(RenderField, { label: formatMessage({
                    id: "ui-br-publishers.ares.accordions.label.comments",
                }), value: comment }))));
};
