import { Row, Col } from "@folio/stripes/components";
import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import RenderField from "../../../components/RenderField";
export var AddressAccordion = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var publisher = _a.publisher;
    var hasMailingAddress = ((_b = publisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) ||
        ((_c = publisher.mailingAddress) === null || _c === void 0 ? void 0 : _c.postalCode) ||
        ((_d = publisher.mailingAddress) === null || _d === void 0 ? void 0 : _d.municipality);
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null,
            React.createElement(FormattedMessage, { id: "ui-br-publisher.ares.header.title.registeredOfficeAddress" })),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.streetAndNumber",
                    }), value: (_e = publisher.mainAddress) === null || _e === void 0 ? void 0 : _e.street })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.postalCode",
                    }), value: (_f = publisher.mainAddress) === null || _f === void 0 ? void 0 : _f.postalCode })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.municipality",
                    }), value: (_g = publisher.mainAddress) === null || _g === void 0 ? void 0 : _g.municipality }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.regionOfResidence",
                    }), value: (_j = (_h = publisher.mainAddress) === null || _h === void 0 ? void 0 : _h.region) === null || _j === void 0 ? void 0 : _j.name }))),
        hasMailingAddress && (React.createElement(React.Fragment, null,
            React.createElement("h3", null,
                React.createElement(FormattedMessage, { id: "ui-br-publishers.ares.header.title.mailingAddress" })),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.ares.field.label.streetAndNumber",
                        }), value: (_k = publisher.mailingAddress) === null || _k === void 0 ? void 0 : _k.street })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.general.field.label.postalCode",
                        }), value: (_l = publisher.mailingAddress) === null || _l === void 0 ? void 0 : _l.postalCode })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.general.field.label.municipality",
                        }), value: (_m = publisher.mailingAddress) === null || _m === void 0 ? void 0 : _m.municipality }))),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.ares.field.label.regionOfResidence",
                        }), value: (_p = (_o = publisher.mailingAddress) === null || _o === void 0 ? void 0 : _o.region) === null || _p === void 0 ? void 0 : _p.name })))))));
};
