import React from "react";
import { Row, Col } from "@folio/stripes/components";
import { FormattedMessage, useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { getEbookTranslations } from "../../../utils/getEbookTranslations";
import { getAudiobookTranslations } from "../../../utils/getAudiobookTranslations";
import { getPublicationFormTranslation } from "../../../utils/getPublicationFormTranslation";
export var WorkSpecSection = function (_a) {
    var _b, _c;
    var workSpec = _a.workSpec;
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    if (!workSpec)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.field.label.title",
                    }), value: workSpec.title })),
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.field.label.subTitle",
                    }), value: workSpec.subtitle }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.field.label.volumeNumber",
                    }), value: workSpec.volumeNumber })),
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.field.label.partName",
                    }), value: workSpec.partName }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement("h3", null,
                    React.createElement(FormattedMessage, { id: "stripes-core.publishers.workSpec.title.authors" }))),
            React.createElement(Col, { xs: 12 }, (_b = workSpec.authors) === null || _b === void 0 ? void 0 : _b.map(function (author, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "stripes-core.publishers.workSpec.field.label.authors.firstName",
                        }), value: author.firstName })),
                React.createElement(Col, { xs: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "stripes-core.publishers.workSpec.field.label.authors.lastName",
                        }), value: author.lastName })))); }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.datePicker.label.publicationDate",
                    }), value: workSpec.publicationDate })),
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.field.label.releaseOrder",
                    }), value: workSpec.releaseOrder }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.field.label.publishedInConjunction",
                    }), value: workSpec.publishedInConjunction }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.select.label.publicationForm",
                    }), value: getPublicationFormTranslation(intl, workSpec.publicationForm) }))),
        workSpec.publicationForm === "PRINT" && (React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.select.label.printedPublicationBinding",
                    }), value: workSpec.printedPublicationBinding })))),
        workSpec.publicationForm === "EBOOK" && (React.createElement(React.Fragment, null,
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("h3", null,
                        React.createElement(FormattedMessage, { id: "stripes-core.publishers.workSpec.title.publicationForm.ebooks" }))),
                React.createElement(Col, { xs: 12 }, (_c = workSpec.ebookFormats) === null || _c === void 0 ? void 0 : _c.map(function (ebook, index) { return (React.createElement(Row, { key: index },
                    React.createElement(Col, { xs: 4 },
                        React.createElement(RenderField, { label: formatMessage({
                                id: "stripes-core.publishers.workSpec.select.label.ebookFormats",
                            }), value: getEbookTranslations(intl, ebook.format) })),
                    ebook.format === "OTHER" && (React.createElement(Col, { xs: 4 },
                        React.createElement(RenderField, { label: formatMessage({
                                id: "stripes-core.publishers.workSpec.select.label.ebookFormats.otherFormat",
                            }), value: ebook.otherFormat }))),
                    React.createElement(Col, { xs: 4 },
                        React.createElement(RenderField, { label: formatMessage({
                                id: "stripes-core.publishers.workSpec.select.label.ebookFormats.offerUrl",
                            }), value: ebook.offerUrl })))); }))))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.select.label.dataCarrierVersion",
                    }), value: workSpec.dataCarrierVersion })),
            React.createElement(Col, { xs: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "stripes-core.publishers.workSpec.select.label.audioBook",
                    }), value: getAudiobookTranslations(intl, workSpec.audioBook) })))));
};
