import React from "react";
import { Row, Col, Checkbox } from "@folio/stripes/components";
import { FormattedMessage, useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
export var AddressSection = function (_a) {
    var _b, _c;
    var mainAddress = _a.mainAddress, mailingAddress = _a.mailingAddress, hasMailingAddress = _a.hasMailingAddress, displayAddressPublishConsent = _a.displayAddressPublishConsent;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", null,
            React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.mainAddress.name" })),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.mainAddress.field.label.street",
                    }), value: mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.street })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.mainAddress.field.label.postalCode",
                    }), value: mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.postalCode })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.mainAddress.field.label.municipality",
                    }), value: mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.municipality }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.mainAddress.select.label.region",
                    }), value: (_b = mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.region) === null || _b === void 0 ? void 0 : _b.name })),
            displayAddressPublishConsent ? (React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(Row, { style: { marginBottom: "25px" } },
                    React.createElement(Col, { xs: 12 },
                        React.createElement("div", { style: { marginBottom: "5px" } },
                            React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, formatMessage({
                                id: "ui-br-publishers.publishers.checkbox.label.consentToPublic",
                            }))),
                        React.createElement(Checkbox, { checked: mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.addressPublishConsent, disabled: true }))))) : null),
        hasMailingAddress && (React.createElement(React.Fragment, null,
            React.createElement("h3", null,
                React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.mailingAddress.name" })),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.mailingAddress.field.label.street",
                        }), value: mailingAddress === null || mailingAddress === void 0 ? void 0 : mailingAddress.street })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.mailingAddress.field.label.postalCode",
                        }), value: mailingAddress === null || mailingAddress === void 0 ? void 0 : mailingAddress.postalCode })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.mailingAddress.field.label.municipality",
                        }), value: mailingAddress === null || mailingAddress === void 0 ? void 0 : mailingAddress.municipality }))),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.mailingAddress.select.label.region",
                        }), value: (_c = mailingAddress === null || mailingAddress === void 0 ? void 0 : mailingAddress.region) === null || _c === void 0 ? void 0 : _c.name })),
                displayAddressPublishConsent ? (React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement(Row, { style: { marginBottom: "25px" } },
                        React.createElement(Col, { xs: 12 },
                            React.createElement("div", { style: { marginBottom: "5px" } },
                                React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, formatMessage({
                                    id: "ui-br-publishers.publishers.checkbox.label.consentToPublic",
                                }))),
                            React.createElement(Checkbox, { checked: mailingAddress === null || mailingAddress === void 0 ? void 0 : mailingAddress.addressPublishConsent, disabled: true }))))) : null)))));
};
