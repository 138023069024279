import { z } from "zod";
import moment from "moment";
var createContactSchema = function (formatMessage) {
    return z
        .object({
        type: z.union([
            z.literal(""),
            z.enum(["EMAIL", "WEB", "PHONE", "DATA_BOX"]),
        ]),
        value: z.string(),
    })
        .superRefine(function (contact, ctx) {
        if (contact.type === "" && contact.value === "")
            return;
        if (contact.type !== "" && contact.value === "") {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.contact.value",
                }),
                path: ["value"],
            });
            return;
        }
        if (contact.type === "" && contact.value !== "") {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.contact.type",
                }),
                path: ["type"],
            });
            return;
        }
        if (contact.type === "EMAIL") {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.value)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.contact.email",
                    }),
                    path: ["value"],
                });
            }
        }
        else if (contact.type === "WEB") {
            if (!/^https?:\/\/[^\s$.?#].[^\s]*$/.test(contact.value)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.contact.web",
                    }),
                    path: ["value"],
                });
            }
        }
        else if (contact.type === "PHONE") {
            if (!/^\+?[0-9\s-]{7,15}$/.test(contact.value)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.contact.phone",
                    }),
                    path: ["value"],
                });
            }
        }
        else if (contact.type === "DATA_BOX") {
            if (contact.value.length < 6 || contact.value.length > 12) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.contact.dataBox",
                    }),
                    path: ["value"],
                });
            }
        }
    });
};
var createAddressSchema = function (formatMessage) {
    return z.object({
        addressCode: z.preprocess(function (val) { return (typeof val === "string" && val.trim() === "" ? "" : val); }, z.string().optional().default("")),
        addressPublishConsent: z.boolean().default(false).optional(),
        street: z.string().min(1, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.address.street",
            }),
        }),
        municipality: z.string().min(1, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.address.municipality",
            }),
        }),
        postalCode: z.string().min(1, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.address.postalCode",
            }),
        }),
        region: z.object({
            id: z.string().min(1, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.address.region",
                }),
            }),
            name: z.string().optional(),
        }),
    });
};
export var createPublisherSchema = function (formatMessage) {
    return z
        .object({
        legalForm: z.enum(["LEGAL", "NATURAL", "UNKNOWN"], {
            required_error: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.legalForm",
            }),
        }),
        registrationAgency: z
            .enum(["ISBN", "ISMN", ""], {
            required_error: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.registrationAgency",
            }),
        })
            .refine(function (val) { return val !== ""; }, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.registrationAgency",
            }),
        }),
        registrationIntent: z
            .enum(["ONE_OFF", "CONTINUOUS", ""], {
            required_error: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.registrationIntent",
            }),
        })
            .refine(function (val) { return val !== ""; }, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.registrationIntent",
            }),
        }),
        version: z.number().optional(),
        id: z.string().optional(),
        internalComment: z.string().optional(),
        nameSuffix: z.string().optional(),
        name: z.string().min(1, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.name",
            }),
        }),
        status: z.enum(["REQUEST", "REJECTED", "ACTIVE", "CLOSING", "CLOSED"], {
            required_error: "Status is required",
        }),
        companyNumber: z.preprocess(function (arg) { return (typeof arg === "string" ? arg.trim() : arg); }, z
            .union([
            z.literal(""),
            z
                .string()
                .min(8, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.general.companyNumber",
                }),
            })
                .max(8, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.general.companyNumber",
                }),
            }),
        ])
            .optional()),
        taxNumber: z
            .union([
            z.literal(""),
            z.string().refine(function (val) { return /^[A-Za-z]{2}\d{2,13}$/.test(val); }, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.general.taxNumber",
                }),
            }),
        ])
            .optional(),
        birthDate: z
            .union([
            z.literal(""),
            z
                .string()
                .refine(function (val) { return moment(val, "DD/MM/YYYY", true).isValid(); }, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.general.birthDate.format",
                }),
            }),
        ])
            .optional()
            .transform(function (val) {
            return val === "" ? val : moment(val, "DD/MM/YYYY").format("YYYY-MM-DD");
        }),
        mainAddress: createAddressSchema(formatMessage),
        mailingAddress: z
            .object({
            addressPublishConsent: z.boolean().default(false).optional(),
            street: z.string().optional(),
            postalCode: z.string().optional(),
            municipality: z.string().optional(),
            region: z
                .object({
                id: z.string().optional(),
                name: z.string().optional(),
            })
                .optional(),
        })
            .optional()
            .transform(function (value) {
            var _a;
            if (!value)
                return undefined;
            var street = value.street, postalCode = value.postalCode, municipality = value.municipality, region = value.region;
            var regionId = (region === null || region === void 0 ? void 0 : region.id) || "";
            if ((!street || !street.trim()) &&
                (!postalCode || !postalCode.trim()) &&
                (!municipality || !municipality.trim()) &&
                (!regionId || !regionId.trim())) {
                return undefined;
            }
            return {
                addressPublishConsent: (_a = value.addressPublishConsent) !== null && _a !== void 0 ? _a : false,
                street: street || "",
                postalCode: postalCode || "",
                municipality: municipality || "",
                region: {
                    id: (region === null || region === void 0 ? void 0 : region.id) || "",
                    name: (region === null || region === void 0 ? void 0 : region.name) || "",
                },
            };
        }),
        contacts: z.array(createContactSchema(formatMessage)).optional(),
        alternativeNames: z
            .array(z.object({
            name: z.string().min(1, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.alternativeNames.name",
                }),
            }),
            nameSuffix: z
                .string()
                .max(50, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.alternativeNames.nameSuffix",
                }),
            })
                .optional(),
            qualifier: z
                .string()
                .max(50, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.alternativeNames.qualifier",
                }),
            })
                .optional(),
            type: z
                .enum([
                "PREVIOUS",
                "IMPRINT",
                "ABBREVIATION",
                "ORGANIZATION_UNIT",
                "FOREIGN_LANGUAGE",
            ])
                .optional(),
            nonPublic: z.boolean().default(false),
        }))
            .optional(),
        guarantors: z
            .array(z.object({
            firstName: z.string().min(1, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.guarantors.firstName",
                }),
            }),
            lastName: z.string().min(1, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.guarantors.lastName",
                }),
            }),
            email: z
                .string()
                .min(1, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.guarantors.email",
                }),
            })
                .refine(function (val) { return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val); }, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.guarantors.email.format",
                }),
            }),
            contacts: z.array(createContactSchema(formatMessage)).optional(),
            titlesAfter: z.string().optional(),
            titlesBefore: z.string().optional(),
        }))
            .optional(),
        workSpec: z
            .object({
            title: z.string().optional(),
            subtitle: z.string().optional(),
            volumeNumber: z.string().optional(),
            partName: z.string().optional(),
            authors: z
                .array(z.object({
                firstName: z.string(),
                lastName: z.string(),
            }))
                .optional(),
            publicationDate: z.string().optional(),
            releaseOrder: z.string().optional(),
            publishedInConjunction: z.string().optional(),
            publicationForm: z.string().optional(),
            printedPublicationBinding: z.string().optional(),
            ebookFormats: z
                .array(z.object({
                format: z.string(),
                otherFormat: z.string().optional(),
                offerUrl: z.string().optional(),
            }))
                .optional(),
            dataCarrierVersion: z.string().optional(),
            audioBook: z.string().optional(),
        })
            .optional(),
    })
        .superRefine(function (data, ctx) {
        var _a, _b, _c, _d, _e;
        if (data.mailingAddress) {
            var _f = data.mailingAddress, street = _f.street, postalCode = _f.postalCode, municipality = _f.municipality, region = _f.region;
            if (!street.trim()) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.address.street",
                    }),
                    path: ["mailingAddress", "street"],
                });
            }
            if (!/^\d{3} ?\d{2}$/.test(postalCode)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.address.postalCode",
                    }),
                    path: ["mailingAddress", "postalCode"],
                });
            }
            if (!municipality.trim()) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.address.municipality",
                    }),
                    path: ["mailingAddress", "municipality"],
                });
            }
            if (!(region === null || region === void 0 ? void 0 : region.id.trim())) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.address.region",
                    }),
                    path: ["mailingAddress", "region", "id"],
                });
            }
        }
        if (data.legalForm === "NATURAL") {
            var hasBirthDate = ((_a = data.birthDate) === null || _a === void 0 ? void 0 : _a.trim()) !== "";
            var hasIco = ((_b = data.companyNumber) === null || _b === void 0 ? void 0 : _b.trim()) !== "";
            var hasDic = ((_c = data.taxNumber) === null || _c === void 0 ? void 0 : _c.trim()) !== "";
            if (!hasBirthDate && !hasIco && !hasDic) {
                var message = formatMessage({
                    id: "ui-br-publishers.publishers.validation.general.legalForm.natural",
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: message,
                    path: ["birthDate"],
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: message,
                    path: ["companyNumber"],
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: message,
                    path: ["taxNumber"],
                });
            }
        }
        else if (data.legalForm === "LEGAL") {
            var hasIco = ((_d = data.companyNumber) === null || _d === void 0 ? void 0 : _d.trim()) !== "";
            var hasDic = ((_e = data.taxNumber) === null || _e === void 0 ? void 0 : _e.trim()) !== "";
            if (!hasIco && !hasDic) {
                var message = formatMessage({
                    id: "ui-br-publishers.publishers.validation.general.legalForm.legal",
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: message,
                    path: ["companyNumber"],
                });
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: message,
                    path: ["taxNumber"],
                });
            }
        }
        if (data.legalForm === "NATURAL" &&
            data.birthDate &&
            data.birthDate.trim() !== "") {
            var parts = data.birthDate.split("-");
            if (parts.length === 3) {
                var year = parseInt(parts[0], 10);
                var month = parseInt(parts[1], 10) - 1;
                var day = parseInt(parts[2], 10);
                var birthDateObj = new Date(year, month, day);
                var today = new Date();
                var age = today.getFullYear() - birthDateObj.getFullYear();
                var m = today.getMonth() - birthDateObj.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDateObj.getDate())) {
                    age--;
                }
                if (age < 18) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: formatMessage({
                            id: "ui-br-publishers.publishers.validation.general.birthDate.age",
                        }, { age: age }),
                        path: ["birthDate"],
                    });
                }
            }
        }
        if (data.registrationIntent === "ONE_OFF") {
            var ws = data.workSpec;
            if (!ws) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage({
                        id: "ui-br-publishers.publishers.validation.workSpec.required",
                        defaultMessage: "Work specification is required for one-time assignment.",
                    }),
                    path: ["workSpec"],
                });
            }
            else {
                if (!ws.title || ws.title.trim() === "") {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: formatMessage({
                            id: "ui-br-publishers.publishers.validation.workSpec.title",
                        }),
                        path: ["workSpec", "title"],
                    });
                }
                if (!ws.publicationDate || ws.publicationDate.trim() === "") {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: formatMessage({
                            id: "ui-br-publishers.publishers.validation.workSpec.publicationDate",
                        }),
                        path: ["workSpec", "publicationDate"],
                    });
                }
                if (!ws.releaseOrder || ws.releaseOrder.trim() === "") {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: formatMessage({
                            id: "ui-br-publishers.publishers.validation.workSpec.releaseOrder",
                        }),
                        path: ["workSpec", "releaseOrder"],
                    });
                }
            }
        }
    });
};
export var createAresSchema = function (formatMessage) {
    return z.object({
        name: z.string().min(1, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.name",
            }),
        }),
        mainAddress: createAddressSchema(formatMessage),
        companyNumber: z
            .string()
            .optional()
            .refine(function (val) { return !val || /^\d{8}$/.test(val); }, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.companyNumber",
            }),
        }),
        taxNumber: z
            .string()
            .optional()
            .refine(function (val) {
            return !val || (val.length >= 2 && val.length <= 13 && /^\d+$/.test(val));
        }, {
            message: formatMessage({
                id: "ui-br-publishers.publishers.validation.general.taxNumber",
            }),
        }),
        alternativeNames: z
            .array(z.object({
            name: z.string().min(1, {
                message: formatMessage({
                    id: "ui-br-publishers.publishers.validation.alternativeNames.name",
                }),
            }),
        }))
            .optional(),
    });
};
export var validatePublisher = function (data, formatMessage) { return createPublisherSchema(formatMessage).safeParse(data); };
export var validateAres = function (data, formatMessage) {
    return createAresSchema(formatMessage).safeParse(data);
};
