var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { useHistory } from "react-router-dom";
import { Pane, Button, Row, Col, PaneFooter } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useIntl } from "react-intl";
import RecekTextField from "../../components/RecekTextField";
import { useCreateOrganization } from "../../api/organizationService";
import { organizationSchema } from "../../utils/validationUtils";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { ErrorType } from "../../types/ErrorType";
function OrganizationCreate() {
    var _this = this;
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    var _a = React.useState(null), error = _a[0], setError = _a[1];
    var _b = useForm({
        resolver: zodResolver(organizationSchema(intl)),
        defaultValues: { name: "" },
        mode: "onBlur",
    }), control = _b.control, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var mutate = useCreateOrganization().mutate;
    var getErrorMessage = function (error) { return __awaiter(_this, void 0, void 0, function () {
        var response, type;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.json())];
                case 1:
                    response = _c.sent();
                    type = ((_b = response === null || response === void 0 ? void 0 : response.errors) === null || _b === void 0 ? void 0 : _b[0]).type;
                    switch (type) {
                        case ErrorType.VALIDATION_ERROR:
                            setError(formatMessage({
                                id: "ui-br-users.organizationCreate.error.organization.exists",
                            }));
                            break;
                        default:
                            setError(formatMessage({
                                id: "ui-br-users.organizationCreate.error.default",
                            }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
        var name = _b.name;
        return __generator(this, function (_c) {
            mutate({ ky: ky, name: name }, {
                onSuccess: function () { return history.goBack(); },
                onError: getErrorMessage,
            });
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Vytvo\u0159it organizaci", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 },
                        React.createElement(Controller, { name: "name", control: control, render: function (_a) {
                                var _b, _c;
                                var field = _a.field;
                                return (React.createElement(RecekTextField, __assign({ label: "Jm\u00E9no" }, field, { required: true, error: (_c = (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : error })));
                            } })))))));
}
export default OrganizationCreate;
