var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { Pane, Button, Col, PaneFooter, ErrorModal, ConfirmationModal, } from "@folio/stripes/components";
import { useOkapiKy } from "@folio/stripes/core";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useIntl } from "react-intl";
import { fetchOrganizationData, updateOrganization, } from "../../api/organizationService";
import RecekTextField from "../../components/RecekTextField";
import { organizationSchema } from "../../utils/validationUtils";
import { RecekPaneSet } from "../../components/RecekPaneSet";
var OrganizationEdit = function () {
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var _a = useForm({
        resolver: zodResolver(organizationSchema(intl)),
        defaultValues: {
            id: "",
            version: 0,
            name: "",
            source: "PUBLISHERS",
            sourceId: "",
            archived: false,
        },
        mode: "onBlur",
    }), control = _a.control, handleSubmit = _a.handleSubmit, reset = _a.reset, errors = _a.formState.errors;
    var _b = useQuery(["organizations", id], function () { return fetchOrganizationData(id, ky); }, { enabled: !!id }), organizationData = _b.data, isLoading = _b.isLoading;
    useEffect(function () {
        if (organizationData) {
            reset({
                version: organizationData.version,
                name: organizationData.name,
                source: organizationData.source,
                sourceId: organizationData.sourceId,
                archived: organizationData.archived,
            });
        }
    }, [organizationData, reset]);
    var _c = useState(false), confirmModalOpen = _c[0], setConfirmModalOpen = _c[1];
    var _d = useState(false), errorModalOpen = _d[0], setErrorModalOpen = _d[1];
    var _e = useState(""), errorMessage = _e[0], setErrorMessage = _e[1];
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateOrganization(ky, id, data)];
                case 1:
                    _d.sent();
                    history.goBack();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _d.sent();
                    if (((_a = error_1.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                        setErrorMessage("Záznam není možné uložit. Záznam byl mezitím upraven. Prosím, načtěte jej a znovu upravte.");
                        setConfirmModalOpen(true);
                    }
                    else if (((_b = error_1.response) === null || _b === void 0 ? void 0 : _b.status) === 422 &&
                        ((_c = error_1.response) === null || _c === void 0 ? void 0 : _c.type) === -8) {
                        setErrorMessage("Tato organizace již existuje.");
                        setErrorModalOpen(true);
                    }
                    else {
                        setErrorMessage("Došlo k chybě při aktualizaci organizace.");
                        setErrorModalOpen(true);
                    }
                    console.error("Error updating organization:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (isLoading) {
        return React.createElement("div", null, "Loading...");
    }
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Upravit organizaci", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
            React.createElement(ConfirmationModal, { open: confirmModalOpen, heading: "Chyba", message: errorMessage, onConfirm: function () { return window.location.reload(); }, onCancel: function () { return setConfirmModalOpen(false); }, confirmLabel: "Znovu na\u010D\u00EDst", cancelLabel: "Zav\u0159\u00EDt", buttonStyle: "primary" }),
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement(Col, { xs: 12 },
                    React.createElement(Controller, { name: "name", control: control, render: function (_a) {
                            var _b;
                            var field = _a.field;
                            return (React.createElement(RecekTextField, __assign({ label: "Jm\u00E9no" }, field, { required: true, error: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message })));
                        } }))))));
};
export default OrganizationEdit;
