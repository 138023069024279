// DuplicateTable.tsx
import React, { useMemo } from "react";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { useOkapiKy } from "@folio/stripes/core";
import { useIntl } from "react-intl";
import TableComponent from "./TableComponent";
var DuplicateTable = function (_a) {
    var duplicates = _a.duplicates;
    var ky = useOkapiKy();
    var formatMessage = useIntl().formatMessage;
    var columns = useMemo(function () { return [
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.companyNumberOrTaxNumberOrBirthDate",
            }),
            key: "companyNumberOrTaxNumberOrBirthDate",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.name",
            }),
            key: "name",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.nameSuffix",
            }),
            key: "nameSuffix",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.qualifier",
            }),
            key: "qualifier",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.phone",
            }),
            key: "phone",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.email",
            }),
            key: "email",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.municipality",
            }),
            key: "municipality",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.duplicatesTable.columns.street",
            }),
            key: "street",
        },
    ]; }, [formatMessage]);
    var source = new StripesConnectedSource({}, ky);
    return (React.createElement(TableComponent, { data: duplicates, columns: columns, pageSize: duplicates.length, totalCount: duplicates.length, onNeedMoreData: function () { }, source: source, toggleFilterPane: function () { }, filterPaneIsVisible: false }));
};
export default DuplicateTable;
