var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, AccordionSet, Accordion, Selection, } from "@folio/stripes/components";
import { StripesConnectedSource } from "@folio/stripes/smart-components";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import { fetchOnixCodetableItems, fetchOnixCodetables, } from "../../api/onixService";
import { RecekPaneSet } from "../../components/RecekPaneSet";
var PAGE_SIZE = 20;
var OnixCodetablesItemsList = function () {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var formatMessage = useIntl().formatMessage;
    var _b = useState({
        value: "",
        description: "",
    }), filters = _b[0], setFilters = _b[1];
    var _c = useState(""), tempValueFilter = _c[0], setTempValueFilter = _c[1];
    var _d = useState(""), tempDescriptionFilter = _d[0], setTempDescriptionFilter = _d[1];
    var _e = useState(true), filterPaneIsVisible = _e[0], setFilterPaneIsVisible = _e[1];
    var _f = useState(""), selectedOnix = _f[0], setSelectedOnix = _f[1];
    var _g = useState(false), onixLoading = _g[0], setOnixLoading = _g[1];
    var _h = useState([]), onixCodetables = _h[0], setOnixCodetables = _h[1];
    var _j = useState(0), pageOffset = _j[0], setPageOffset = _j[1];
    var _k = useState(0), totalRecords = _k[0], setTotalRecords = _k[1];
    // Debounced fetching of Onix Codetables
    var debouncedFetchOnixCodetables = useCallback(debounce(function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOnixLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, fetchOnixCodetables(ky, { name: inputValue })];
                case 2:
                    data_1 = _a.sent();
                    setOnixCodetables(data_1.onixCodetables);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error fetching Onix codetables:", error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setOnixLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, 300), [ky]);
    // Handle input changes in the Selection component for OnixCodetables
    var handleOnixInput = function (inputValue) {
        debouncedFetchOnixCodetables(inputValue);
    };
    var handleOnixSelection = function (selectedValue) {
        setSelectedOnix(selectedValue);
    };
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        setFilters({
            value: queryParams.get("value") || "",
            description: queryParams.get("description") || "",
        });
        setTempValueFilter(queryParams.get("value") || "");
        setTempDescriptionFilter(queryParams.get("description") || "");
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.value) {
            params.set("value", filters.value);
        }
        if (filters.description) {
            params.set("description", filters.description);
        }
        if (selectedOnix) {
            params.set("onixCodetableId", selectedOnix);
        }
        history.push({ search: params.toString() });
    };
    var handleApplyFilters = function () {
        setFilters({
            value: tempValueFilter,
            description: tempDescriptionFilter,
        });
        updateUrlWithFilters({
            value: tempValueFilter,
            description: tempDescriptionFilter,
        });
    };
    var handleClearFilters = function () {
        setTempValueFilter("");
        setTempDescriptionFilter("");
        setFilters({
            value: "",
            description: "",
        });
        setSelectedOnix("");
        history.push({ search: "" });
    };
    var isAnyFilterActive = function () {
        return !!filters.value || !!filters.description || !!selectedOnix;
    };
    var _l = useQuery(["onix-codetable-items", filters, pageOffset], function () {
        return fetchOnixCodetableItems(ky, __assign(__assign({}, filters), { offset: pageOffset, limit: PAGE_SIZE }));
    }, {
        enabled: isAnyFilterActive(), // Enable the query only if a filter is active
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _l.data, isLoading = _l.isLoading, isError = _l.isError, refetch = _l.refetch, isFetched = _l.isFetched;
    var onixCodetableItemsData = (_a = data === null || data === void 0 ? void 0 : data.onixCodetableItems) !== null && _a !== void 0 ? _a : [];
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var columns = [
        {
            name: "Hodnota",
            key: "value",
        },
        {
            name: "Popis",
            key: "description",
        },
        {
            name: "Verze",
            key: "version",
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return onixCodetableItemsData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isFetched; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.successfulMutations = function () { return []; };
    source.fetchMore = function () { return fetchMoreData; };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    return (React.createElement(RecekPaneSet, null,
        filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1n\u00ED" },
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(NavComponent, null),
                React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters }, "Resetovat v\u0161echno"),
                React.createElement(AccordionSet, null,
                    React.createElement("form", { onSubmit: function (e) {
                            e.preventDefault();
                            handleApplyFilters();
                        } },
                        React.createElement(Accordion, { label: formatMessage({
                                id: "ui-br-agency.onixCodetablesItemsList.accordion.label.filterByName",
                            }), separator: false },
                            React.createElement(Selection, { name: "onixSelection", value: selectedOnix, onChange: handleOnixSelection, asyncFilter: true, onFilter: handleOnixInput, dataOptions: onixCodetables
                                    ? onixCodetables.map(function (onix) { return ({
                                        value: onix.id,
                                        label: onix.name,
                                    }); })
                                    : [] }),
                            React.createElement(Button, { type: "submit", marginBottom0: true }, formatMessage({
                                id: "ui-br-agency.button.apply",
                            })))))))),
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Seznam ONIX k\u00F3dov\u00FDch polo\u017Eek" },
            React.createElement(TableComponent, { data: onixCodetableItemsData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, onNeedMoreData: function (offset) {
                    fetchMoreData(offset);
                }, searchTerm: "", filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source }))));
};
export default OnixCodetablesItemsList;
