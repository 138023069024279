export var contactTypeTranslations = {
    EMAIL: {
        id: "ui-br-publishers.publishers.contacts.select.label.email",
    },
    WEB: {
        id: "ui-br-publishers.publishers.contacts.select.label.web",
    },
    PHONE: {
        id: "ui-br-publishers.publishers.contacts.select.label.phone",
    },
    DATA_BOX: {
        id: "ui-br-publishers.publishers.contacts.select.label.dataBox",
    },
};
export var getContactTypeTranslation = function (intl, type) {
    var translation = contactTypeTranslations[type];
    if (!translation)
        return type;
    return intl.formatMessage(translation);
};
