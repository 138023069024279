var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useMemo, useState } from "react";
import { MultiColumnList, TextLink } from "@folio/stripes/components";
import { SearchAndSortNoResultsMessage as NoResultsMessage, } from "@folio/stripes/smart-components";
var statusTranslations = {
    REQUEST: "Žádost",
    REJECTED: "Odmítnuto",
    ACTIVE: "Aktivní",
    CLOSING: "V likvidaci",
    CLOSED: "Ukončený",
};
function TableComponent(_a) {
    var _this = this;
    var data = _a.data, columns = _a.columns, _b = _a.pageSize, pageSize = _b === void 0 ? 20 : _b, _c = _a.totalCount, totalCount = _c === void 0 ? 0 : _c, onNeedMoreData = _a.onNeedMoreData, searchTerm = _a.searchTerm, filterPaneIsVisible = _a.filterPaneIsVisible, toggleFilterPane = _a.toggleFilterPane, source = _a.source, pagingType = _a.pagingType;
    var _d = useState(0), offset = _d[0], setOffset = _d[1];
    // Function to request more data when needed
    var handleNeedMoreData = function (amount, index) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (onNeedMoreData) {
                onNeedMoreData(index);
            }
            setOffset(index);
            return [2 /*return*/];
        });
    }); };
    var resultsStatusMessage = source ? (React.createElement("div", { "data-test-user-search-no-results-message": true },
        React.createElement(NoResultsMessage, { source: source, searchTerm: searchTerm !== null && searchTerm !== void 0 ? searchTerm : "", filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane }))) : ("no source yet");
    var columnMapping = useMemo(function () {
        return columns.reduce(function (acc, col) {
            acc[col.key] = col.name;
            return acc;
        }, {});
    }, [columns]);
    var formatter = useMemo(function () {
        return columns.reduce(function (acc, col) {
            if (col.key === "status") {
                acc[col.key] = function (item) {
                    var _a;
                    var statusKey = item[col.key];
                    return (_a = statusTranslations[statusKey]) !== null && _a !== void 0 ? _a : String(item[col.key]);
                };
            }
            else if (col.cellRenderer) {
                acc[col.key] = function (item) {
                    var content = col.cellRenderer(item);
                    return col.clickable && col.onClick ? (React.createElement(TextLink, { onClick: function () { return col.onClick(item); } }, content)) : (content);
                };
            }
            else if (col.clickable && col.onClick) {
                acc[col.key] = function (item) {
                    var content = item[col.key];
                    if (typeof content !== "string" && typeof content !== "number") {
                        return null;
                    }
                    return (React.createElement(TextLink, { onClick: function () { return col.onClick(item); } }, content));
                };
            }
            else {
                acc[col.key] = function (item) {
                    var value = item[col.key];
                    if (typeof value === "string" || typeof value === "number") {
                        return value;
                    }
                    if (React.isValidElement(value)) {
                        return value;
                    }
                    return String(value !== null && value !== void 0 ? value : "");
                };
            }
            return acc;
        }, {});
    }, [columns]);
    var visibleColumns = useMemo(function () {
        return columns.map(function (col) { return col.key; });
    }, [columns]);
    return (React.createElement("div", null,
        React.createElement(MultiColumnList, { contentData: data, columnMapping: columnMapping, formatter: formatter, visibleColumns: visibleColumns, pagingType: pagingType, isEmptyMessage: resultsStatusMessage, pagingOffset: offset, pageAmount: pageSize, totalCount: totalCount, onNeedMoreData: function (pageAmount, newOffset) {
                handleNeedMoreData(pageAmount, newOffset);
            } })));
}
export default TableComponent;
