import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, MenuSection, Pane } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { useIntl } from "react-intl";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import { fetchAresUpdates, fetchAresUpdatesData } from "../../api/aresService";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import AresUpdatesDetail from "./AresUpdatesDetail";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { formatDate } from "../../utils/dateTimeUtil";
import { DataListPane } from "../../components/DataListPane";
var PAGE_SIZE = 20;
var AresUpdatesList = function () {
    var _a;
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var formatMessage = useIntl().formatMessage;
    var _b = useState(null), selectedAresUpdate = _b[0], setSelectedAresUpdate = _b[1];
    var _c = useState(true), filterPaneIsVisible = _c[0], setFilterPaneIsVisible = _c[1];
    var _d = useState(0), totalRecords = _d[0], setTotalRecords = _d[1];
    var _e = useState(0), pageOffset = _e[0], setPageOffset = _e[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var aresId = queryParams.get("detail");
        if (aresId) {
            fetchAresUpdatesData(aresId, ky).then(function (ares) {
                if (ares) {
                    setSelectedAresUpdate(ares);
                    // Ensure filters are preserved when opening detail
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var _f = useQuery(["ares-update-requests", pageOffset], function () {
        return fetchAresUpdates(ky, {
            offset: pageOffset,
            limit: PAGE_SIZE,
        });
    }, {
        enabled: true,
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _f.data, isLoading = _f.isLoading, isError = _f.isError, refetch = _f.refetch;
    var aresUpdatesData = (_a = data === null || data === void 0 ? void 0 : data.aresUpdateRequests) !== null && _a !== void 0 ? _a : [];
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var columns = useMemo(function () { return [
        {
            name: "Nakladatel",
            key: "publisher",
            cellRenderer: function (item) { var _a; return ((_a = item.publisher) === null || _a === void 0 ? void 0 : _a.name) || "N/A"; },
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedAresUpdate(item);
            },
        },
        {
            name: "Čas vytvoření",
            key: "createTime",
            clickable: true,
            cellRenderer: function (item) {
                return item.createTime ? formatDate(item.createTime) : "";
            },
        },
    ]; }, [history]);
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return aresUpdatesData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return true; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.successfulMutations = function () { return []; };
    source.fetchMore = function () { return fetchMoreData; };
    return (React.createElement(RecekPaneSet, null,
        filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace" },
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(NavComponent, null)))),
        React.createElement(DataListPane, { paneId: "contentPane", paneTitle: formatMessage({
                id: "ui-br-publishers.ares.update.pane.title",
            }), paneSub: formatMessage({ id: "ui-br-publishers.publishers.table.subtitle.records-found" }, { count: totalRecords }), filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane },
            React.createElement(TableComponent, { data: aresUpdatesData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, filterPaneIsVisible: filterPaneIsVisible, onNeedMoreData: function (offset) {
                    fetchMoreData(offset);
                }, toggleFilterPane: function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); }, source: source, pagingType: "prev-next" })),
        selectedAresUpdate && (React.createElement(Pane, { id: "detailPane", defaultWidth: "35%", paneTitle: "Detail nakladatele", onClose: function () {
                setSelectedAresUpdate(null);
                var queryParams = new URLSearchParams(location.search);
                queryParams.delete("detail");
                history.replace({ search: queryParams.toString() });
            }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" }, hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                    return history.push("/brpublishers/ares-updates/edit/".concat(selectedAresUpdate.id));
                }, fullWidth: true }, "Zm\u011Bnit")))); } }, selectedAresUpdate.publisher ? (React.createElement(AresUpdatesDetail, { ares: selectedAresUpdate })) : (React.createElement("div", null, "No details available"))))));
};
export default AresUpdatesList;
