var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, MessageBanner, IconButton, ConfirmationModal, ErrorModal, } from "@folio/stripes/components";
import { FormattedMessage, useIntl } from "react-intl";
import PublisherDetail from "../publishers/PublisherDetail";
import { fetchPublisherData, updatePublisherAction, } from "../../api/publisherService";
import { hasAtLeastOneRole, useActiveRoles, } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { fetchCurrentUser } from "../../api/userService";
import NavComponent from "../../components/NavComponent";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { fetchPublisherUpdateRequests } from "../../api/publisherUpdateRequestService";
var MyPublisherDetail = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var formatMessage = useIntl().formatMessage;
    var queryClientContext = useQueryClient();
    var _a = useState(true), filterPaneIsVisible = _a[0], setFilterPaneIsVisible = _a[1];
    var _b = useState(false), errorModalOpen = _b[0], setErrorModalOpen = _b[1];
    var _c = useState(false), showConfirmModal = _c[0], setShowConfirmModal = _c[1];
    var _d = useState(null), confirmAction = _d[0], setConfirmAction = _d[1];
    var _e = useState(null), confirmMessage = _e[0], setConfirmMessage = _e[1];
    var _f = useState(), warningMessage = _f[0], setWarningMessage = _f[1];
    var _g = useState(false), warningMessageShow = _g[0], setWarningMessageShow = _g[1];
    var selectedPublisher = useQuery("publisher", function () { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchCurrentUser(ky)];
                case 1:
                    user = _a.sent();
                    if (user && user.activeOrganization) {
                        return [2 /*return*/, fetchPublisherData(user.activeOrganization.sourceId, ky)];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }).data;
    var checkDateAndCallFunction = function () {
        var oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        if (selectedPublisher && selectedPublisher.dataConfirmationTime) {
            var objectDate = new Date(selectedPublisher.dataConfirmationTime);
            if (objectDate < oneYearAgo) {
                setWarningMessage(formatMessage({
                    id: "ui-br-publishers.my-publisher.warning.message",
                }));
                setWarningMessageShow(true);
                setErrorModalOpen(true);
            }
            else if (warningMessageShow) {
                setWarningMessageShow(false);
            }
        }
    };
    useEffect(function () {
        checkDateAndCallFunction();
    }, [selectedPublisher]);
    var handleConfirmData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updated, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedPublisher) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, updatePublisherAction(ky, selectedPublisher.id, "confirmData")];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, fetchPublisherData(selectedPublisher.id, ky)];
                case 3:
                    updated = _a.sent();
                    console.log("Updated publisher from API:", updated);
                    queryClientContext.invalidateQueries("publisher");
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error("Error confirming publisher:", error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onChangeButton = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchPublisherUpdateRequests(ky, {
                            status: "SUBMITTED",
                            publisherId: selectedPublisher === null || selectedPublisher === void 0 ? void 0 : selectedPublisher.id,
                        })];
                case 1:
                    data = _a.sent();
                    if (data.totalRecords > 0) {
                        setWarningMessage(formatMessage({
                            id: "ui-br-publishers.publishers.update.request.exists.message",
                        }));
                        setWarningMessageShow(true);
                    }
                    else {
                        history.push("/brpublishers/my-publisher/edit/".concat(selectedPublisher === null || selectedPublisher === void 0 ? void 0 : selectedPublisher.id));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error fetching publisher update requests:", error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var hasRoleAndIsNotClosed = function (roles) {
        return (hasAtLeastOneRole(activeRoles, roles) &&
            (selectedPublisher === null || selectedPublisher === void 0 ? void 0 : selectedPublisher.status) !== "CLOSED");
    };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(RecekPaneSet, null,
            filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: formatMessage({
                    id: "ui-br-publishers.my-publisher.pane.title.navigation",
                }), lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible
                        ? formatMessage({
                            id: "ui-br-publishers.my-publisher.iconButton.ariaLabel.hideFilters",
                        })
                        : formatMessage({
                            id: "ui-br-publishers.my-publisher.iconButton.ariaLabel.showFilters",
                        }), style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: React.createElement(FormattedMessage, { id: "ui-br-publishers.my-publisher.pane.title" }), actionMenu: function () { return (React.createElement(MenuSection, { label: formatMessage({
                        id: "ui-br-publishers.my-publisher.pane.actionMenu.menuSelection.label.action",
                    }), id: "actions-menu" },
                    hasAtLeastOneRole(activeRoles, [
                        RoleEnum.PUBLISHER_ADMIN,
                        RoleEnum.PUBLISHER_GUARANTOR,
                    ]) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return onChangeButton(); } },
                        React.createElement(FormattedMessage, { id: "stripes-core.button.edit" }))),
                    hasAtLeastOneRole(activeRoles, [
                        RoleEnum.PUBLISHER_ADMIN,
                        RoleEnum.PUBLISHER_GUARANTOR,
                    ]) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            setConfirmAction(function () { return handleConfirmData; });
                            setShowConfirmModal(true);
                        } },
                        React.createElement(FormattedMessage, { id: "ui-br-publishers.my-publisher.button.confirm.data" }))),
                    hasRoleAndIsNotClosed([RoleEnum.PUBLISHER_ADMIN]) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return history.push("/brpublishers/my-publisher/close/".concat(selectedPublisher === null || selectedPublisher === void 0 ? void 0 : selectedPublisher.id));
                        } },
                        React.createElement(FormattedMessage, { id: "ui-br-publishers.my-publisher.button.close.publisher" }))))); }, firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible
                        ? formatMessage({
                            id: "ui-br-publishers.my-publisher.iconButton.ariaLabel.hideFilters",
                        })
                        : formatMessage({
                            id: "ui-br-publishers.my-publisher.iconButton.ariaLabel.showFilters",
                        }), style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement(MessageBanner, { autoFocusDismissButton: true, dismissible: true, onExited: function () { return setWarningMessageShow(false); }, show: warningMessageShow, type: "warning" }, warningMessage),
                selectedPublisher && (React.createElement(PublisherDetail, { key: selectedPublisher.id, publisher: selectedPublisher })))),
        React.createElement(ErrorModal, { open: errorModalOpen, label: formatMessage({
                id: "ui-br-publishers.my-publisher.warning.title",
            }), content: formatMessage({
                id: "ui-br-publishers.my-publisher.warning.message",
            }), onClose: function () { return setErrorModalOpen(false); } }),
        showConfirmModal && (React.createElement(ConfirmationModal, { open: showConfirmModal, heading: formatMessage({
                id: "ui-br-publishers.my-publisher.button.confirm.data",
            }), message: confirmMessage ||
                formatMessage({
                    id: "ui-br-publishers.my-publisher.modal.confirm.message",
                }), onConfirm: confirmMessage
                ? function () { return setShowConfirmModal(false); }
                : function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!confirmAction) return [3 /*break*/, 4];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, confirmAction()];
                            case 2:
                                _a.sent();
                                setConfirmMessage(formatMessage({
                                    id: "ui-br-publishers.messages.action.success",
                                }));
                                return [3 /*break*/, 4];
                            case 3:
                                error_3 = _a.sent();
                                console.error("Error performing action:", error_3);
                                setConfirmMessage(formatMessage({
                                    id: "ui-br-publishers.messages.action.error",
                                }));
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () { return setShowConfirmModal(false); }, confirmLabel: confirmMessage
                ? formatMessage({ id: "ui-br-publishers.button.ok" })
                : formatMessage({ id: "ui-br-publishers.button.yes" }), cancelLabel: !confirmMessage &&
                formatMessage({ id: "ui-br-publishers.button.no" }), buttonStyle: "primary" }))));
};
export default MyPublisherDetail;
