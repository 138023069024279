var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getHeaders } from "../utils/apiUtils";
export var fetchPublisherUpdateRequests = function (kyInstance, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var offset, limit, status, publisherId, searchParams, query, response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                offset = filters.offset, limit = filters.limit, status = filters.status, publisherId = filters.publisherId;
                searchParams = new URLSearchParams();
                query = [];
                if (status)
                    query.push("status=".concat(status));
                if (publisherId)
                    query.push("publisher.id=".concat(publisherId));
                if (query.length)
                    searchParams.append("query", query.join(" and "));
                if (limit !== undefined) {
                    searchParams.append("limit", "".concat(limit));
                }
                if (offset !== undefined) {
                    searchParams.append("offset", "".concat(offset));
                }
                return [4 /*yield*/, kyInstance.get("publisher-update-requests", {
                        headers: getHeaders(),
                        searchParams: searchParams,
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Error fetching update requests: ".concat(response.statusText));
                }
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, {
                        publisherUpdateRequests: data.publisherUpdateRequests,
                        totalRecords: data.totalRecords,
                    }];
        }
    });
}); };
export var fetchPublisherUpdateRequestById = function (ky, id) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("publisher-update-requests/".concat(id), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Error fetching update request by ID: ".concat(response.statusText));
                return [2 /*return*/, response.json()];
        }
    });
}); };
export var fetchOpenSubmittedUpdateRequest = function (ky, publisherId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("publisher-update-requests/".concat(publisherId, "/submitted"), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error("No open submitted requests found");
                    }
                    throw new Error("Error fetching open submitted request: ".concat(response.statusText));
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
export var resolvePublisherUpdateRequest = function (ky, id, resolveData) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.post("publisher-update-requests/".concat(id, "/resolve"), {
                    body: resolveData.resolution,
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Error resolving update request: ".concat(response.statusText));
                return [2 /*return*/, response];
        }
    });
}); };
