export var altNamesTranslations = {
    PREVIOUS: {
        id: "ui-br-publishers.publishers.alternativeNames.select.label.previous.short",
    },
    IMPRINT: {
        id: "ui-br-publishers.publishers.alternativeNames.select.label.imprint",
    },
    ABBREVIATION: {
        id: "ui-br-publishers.publishers.alternativeNames.select.label.abbreviation",
    },
    ORGANIZATION_UNIT: {
        id: "ui-br-publishers.publishers.alternativeNames.select.label.organizationUnit",
    },
    FOREIGN_LANGUAGE: {
        id: "ui-br-publishers.publishers.alternativeNames.select.label.foreignLanguage.short",
    },
};
export var getAltNameTranslation = function (intl, key) {
    var translation = altNamesTranslations[key];
    if (!translation)
        return key;
    return intl.formatMessage(translation);
};
