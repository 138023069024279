import { Row, Col } from "@folio/stripes/components";
import React from "react";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { formatDate } from "../../../utils/dateTimeUtil";
export var RegistryDataAccordion = function (_a) {
    var _b, _c, _d;
    var data = _a.data, publisher = _a.publisher, addressData = _a.addressData;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.companyNumber",
                    }), value: data.companyNumber })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.firstName",
                    }), value: data.name }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.taxNumber.short",
                    }), value: data.taxNumber })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.legalForm",
                    }), value: data.legalForm }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.streetAndNumber",
                    }), value: addressData === null || addressData === void 0 ? void 0 : addressData.street })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.postalCode",
                    }), value: addressData === null || addressData === void 0 ? void 0 : addressData.postalCode })),
            React.createElement(Col, { xs: 12, md: 4 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.publishers.general.field.label.municipality",
                    }), value: addressData === null || addressData === void 0 ? void 0 : addressData.municipality }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.regionOfResidence",
                    }), value: (_c = (_b = publisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.region) === null || _c === void 0 ? void 0 : _c.name }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.editingTime",
                    }), value: formatDate((_d = data.updateTime) !== null && _d !== void 0 ? _d : "") })))));
};
