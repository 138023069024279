import React from "react";
import { Row, Col } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { getContactTypeTranslation } from "../../../utils/contactTypeTranslations";
export var GuarantorsSection = function (_a) {
    var guarantors = _a.guarantors;
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    if (!guarantors || guarantors.length === 0) {
        return (React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement("div", null, "\u017D\u00E1dn\u00ED garanti k zobrazen\u00ED"))));
    }
    return (React.createElement(React.Fragment, null, guarantors.map(function (guarantor, index) {
        var _a;
        return (React.createElement(React.Fragment, { key: index },
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.guarantors.field.label.firstName",
                        }), value: guarantor.firstName })),
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement(RenderField, { label: formatMessage({
                            id: "ui-br-publishers.publishers.guarantors.field.label.lastName",
                        }), value: guarantor.lastName }))), (_a = guarantor.contacts) === null || _a === void 0 ? void 0 :
            _a.map(function (contact, contactIndex) { return (React.createElement(Row, { key: "contact-".concat(index, "-").concat(contactIndex) },
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Typ", value: getContactTypeTranslation(intl, contact.type) })),
                React.createElement(Col, { xs: 12, md: 4 },
                    React.createElement(RenderField, { label: "Hodnota", value: contact.value })))); })));
    })));
};
