import { Row, Col } from "@folio/stripes/components";
import React from "react";
export var FieldRow = function (_a) {
    var label = _a.label, value = _a.value;
    return (React.createElement(Row, { style: { marginBottom: "25px" } },
        React.createElement(Col, { xs: 12 },
            React.createElement("div", { style: { marginBottom: "5px" } },
                React.createElement("strong", { style: { fontSize: "1.1em", paddingRight: "5px" } }, label)),
            React.createElement("div", null, value || "N/A"))));
};
