import React, { useMemo, useState } from "react";
import { Row, Col, Button } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import CustomAccordionSet from "../../components/CustomAccordionSet";
import { formatDateTime } from "../../utils/dateTimeUtil";
import { ExtendedInformationAccordion } from "../../components/ExtendedInformationAccordion";
var AuditLogDetail = function (_a) {
    var auditLog = _a.auditLog;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(true), expandAll = _b[0], setExpandAll = _b[1];
    var toggleExpandCollapseAll = function () { return setExpandAll(function (prev) { return !prev; }); };
    var handleToggleExpandAll = function (expanded) { return setExpandAll(expanded); };
    var formatJsonData = function (jsonString) {
        if (!jsonString)
            return "N/A";
        try {
            var jsonData = JSON.parse(jsonString);
            return JSON.stringify(jsonData, null, 2);
        }
        catch (error) {
            console.error("Failed to parse JSON:", error);
            return "Invalid JSON data";
        }
    };
    var accordions = useMemo(function () { return [
        {
            id: "action",
            label: "Akce",
            content: (React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement("strong", null, "Akce:"),
                    React.createElement("div", null, auditLog.entityAction || "N/A")))),
        },
        {
            id: "id",
            label: "ID",
            content: (React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement("strong", null, "ID:"),
                    React.createElement("div", null, auditLog.entityId || "N/A")))),
        },
        {
            id: "time",
            label: "Čas",
            content: (React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement("strong", null, "\u010Cas:"),
                    React.createElement("div", null, formatDateTime(auditLog.createTime))))),
        },
        {
            id: "user",
            label: "Uživatel",
            content: (React.createElement(React.Fragment, null,
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement("strong", null, "E-mail:"),
                        React.createElement("div", null, auditLog.user.email || "N/A"))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement("strong", null, "Jm\u00E9no:"),
                        React.createElement("div", null, auditLog.user.firstName || "N/A"))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement("strong", null, "P\u0159\u00EDjmen\u00ED:"),
                        React.createElement("div", null, auditLog.user.lastName || "N/A"))))),
        },
        {
            id: "data",
            label: "Data",
            content: (React.createElement(Row, null,
                React.createElement(Col, { xs: 12, md: 6 },
                    React.createElement("strong", null, "Data:"),
                    React.createElement("pre", { style: { whiteSpace: "pre-wrap", wordBreak: "break-word" } }, formatJsonData(auditLog.data))))),
        },
        {
            id: "extendedInformation",
            label: formatMessage({
                id: "ui-br-users.accordions.label.extendedInformation",
            }),
            content: React.createElement(ExtendedInformationAccordion, { id: auditLog.id }),
        },
    ]; }, [
        auditLog.createTime,
        auditLog.data,
        auditLog.entityAction,
        auditLog.entityId,
        auditLog.id,
        auditLog.user.email,
        auditLog.user.firstName,
        auditLog.user.lastName,
        formatMessage,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, auditLog.entityName),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll
                    ? formatMessage({ id: "ui-br-publishers.button.collapse.all" })
                    : formatMessage({ id: "ui-br-publishers.button.expand.all" })))),
        React.createElement(CustomAccordionSet, { accordions: accordions, expandAll: expandAll, onToggleExpandAll: handleToggleExpandAll })));
};
export default AuditLogDetail;
