import React from "react";
import { Row, Col } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { getContactTypeTranslation } from "../../../utils/contactTypeTranslations";
export var ContactsSection = function (_a) {
    var contacts = _a.contacts;
    var intl = useIntl();
    if (!contacts || contacts.length === 0) {
        return (React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement("div", null, "\u017D\u00E1dn\u00E9 kontakty k zobrazen\u00ED"))));
    }
    return (React.createElement(React.Fragment, null, contacts.map(function (contact, index) { return (React.createElement(Row, { key: index },
        React.createElement(Col, { xs: 12, md: 4 },
            React.createElement(RenderField, { label: "Typ", value: getContactTypeTranslation(intl, contact.type) })),
        React.createElement(Col, { xs: 12, md: 4 },
            React.createElement(RenderField, { label: "Hodnota", value: contact.value })))); })));
};
