var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useIntl } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, Row, Col, PaneFooter, AccordionSet, Accordion, Checkbox, Selection, ErrorModal, } from "@folio/stripes/components";
import { fetchOrganizations } from "../../api/organizationService";
import { assignUserToOrganization, createUser } from "../../api/usersService";
import RecekTextField from "../../components/RecekTextField";
import { createUserSchema } from "../../utils/validationUtils";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { getRolesTranslations } from "../../utils/getRolesTranslations";
var extendedUserSchema = function (intl) {
    return createUserSchema(intl).extend({
        organization: z.string().min(1, {
            message: intl.formatMessage({
                id: "ui-br-users.users.validation.organization",
            }),
        }),
        selectedRoles: z.array(z.string()).optional(),
    });
};
var availableRoles = ["PublisherAdmin", "PublisherGuarantor", "AgencyWorker"];
var UserCreate = function () {
    var _a, _b;
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    var _c = useForm({
        resolver: zodResolver(extendedUserSchema(intl)),
        defaultValues: {
            user: {
                email: "",
                firstName: "",
                lastName: "",
                shouldChangePassword: true,
            },
            password: "",
            sendEmail: true,
            organization: "",
            selectedRoles: [],
        },
        mode: "onBlur",
    }), handleSubmit = _c.handleSubmit, register = _c.register, setValue = _c.setValue, getValues = _c.getValues, control = _c.control, errors = _c.formState.errors;
    var _d = useState(true), expandAll = _d[0], setExpandAll = _d[1];
    var _e = useState({
        organization: true,
        profile: true,
        role: true,
    }), accordionStatus = _e[0], setAccordionStatus = _e[1];
    var _f = useState({ name: "" }), filters = _f[0], setFilters = _f[1];
    var _g = useQuery(["organizations", filters], function () { return fetchOrganizations(ky, filters); }, {
        refetchOnWindowFocus: false,
        enabled: filters.name !== "",
    }), organizations = _g.data, orgLoading = _g.isLoading, isFetching = _g.isFetching;
    var _h = useState(false), errorModalOpen = _h[0], setErrorModalOpen = _h[1];
    var _j = useState(""), errorMessage = _j[0], setErrorMessage = _j[1];
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, type = _a.type, value = _a.value;
        if (type === "checkbox") {
            var checked = e.target.checked;
            if (name === "sendEmail" || name === "user.shouldChangePassword") {
                setValue(name, checked, {
                    shouldValidate: true,
                });
                return;
            }
            if (name.startsWith("role_")) {
                var roleCode_1 = name.replace("role_", "");
                var currentRoles = getValues("selectedRoles") || [];
                var updatedRoles = checked
                    ? __spreadArray(__spreadArray([], currentRoles, true), [roleCode_1], false) : currentRoles.filter(function (role) { return role !== roleCode_1; });
                setValue("selectedRoles", updatedRoles, { shouldValidate: true });
                return;
            }
        }
        setValue(name, value, { shouldValidate: true });
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var organization, selectedRoles, userData, newUser, error_1, errMsg, response, emailExistsError;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    organization = data.organization, selectedRoles = data.selectedRoles, userData = __rest(data, ["organization", "selectedRoles"]);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 8]);
                    return [4 /*yield*/, createUser(ky, userData)];
                case 2:
                    newUser = _a.sent();
                    if (!organization) return [3 /*break*/, 4];
                    return [4 /*yield*/, assignUserToOrganization(ky, newUser.id, organization, selectedRoles)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    history.goBack();
                    return [3 /*break*/, 8];
                case 5:
                    error_1 = _a.sent();
                    console.error("Error creating user or assigning roles:", error_1);
                    errMsg = "Došlo k chybě při vytváření uživatele. Zkuste to prosím znovu.";
                    if (!error_1.response) return [3 /*break*/, 7];
                    return [4 /*yield*/, error_1.response.json()];
                case 6:
                    response = _a.sent();
                    if (Array.isArray(response.errors)) {
                        response.errors.forEach(function (err) {
                            console.log("Response error message:", err.message);
                        });
                        emailExistsError = response.errors.find(function (err) { return err.message.includes("Key (email)"); });
                        if (emailExistsError) {
                            errMsg =
                                "Došlo k chybě při vytváření uživatele. Tento email již existuje.";
                        }
                    }
                    _a.label = 7;
                case 7:
                    setErrorMessage(errMsg);
                    setErrorModalOpen(true);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var toggleExpandCollapseAll = function () {
        setExpandAll(!expandAll);
        setAccordionStatus({
            organization: !expandAll,
            profile: !expandAll,
            role: !expandAll,
        });
    };
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Vytvo\u0159it u\u017Eivatele", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); } }, "Zru\u0161it") }) },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { noValidate: true, onSubmit: handleSubmit(onSubmit) },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { organization: !accordionStatus.organization }));
                        } },
                        React.createElement(Selection, { asyncFilter: true, label: formatMessage({
                                id: "ui-br-users.label.organization",
                            }), name: "organization", value: getValues("organization"), error: (_a = errors.organization) === null || _a === void 0 ? void 0 : _a.message, disabled: orgLoading, loading: isFetching, onChange: function (value) {
                                return setValue("organization", value, { shouldValidate: true });
                            }, onFilter: function (value) {
                                setFilters({ name: value });
                            }, dataOptions: organizations
                                ? ((_b = organizations === null || organizations === void 0 ? void 0 : organizations.organizations) !== null && _b !== void 0 ? _b : []).map(function (org) { return ({
                                    value: org.id,
                                    label: org.name,
                                }); })
                                : [] })),
                    React.createElement(Accordion, { label: "U\u017Eivatelsk\u00E9 informace", open: accordionStatus.profile, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { profile: !accordionStatus.profile }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "user.firstName", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "Jm\u00E9no", required: true }, field, { error: (_c = (_b = errors.user) === null || _b === void 0 ? void 0 : _b.firstName) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "user.lastName", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "P\u0159\u00EDjmen\u00ED", required: true }, field, { error: (_c = (_b = errors === null || errors === void 0 ? void 0 : errors.user) === null || _b === void 0 ? void 0 : _b.lastName) === null || _c === void 0 ? void 0 : _c.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "user.email", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "E-mail", required: true }, field, { error: (_c = (_b = errors === null || errors === void 0 ? void 0 : errors.user) === null || _b === void 0 ? void 0 : _b.email) === null || _c === void 0 ? void 0 : _c.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "password", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "Heslo", required: true }, field, { error: (_b = errors === null || errors === void 0 ? void 0 : errors.password) === null || _b === void 0 ? void 0 : _b.message })));
                                    } }),
                                React.createElement("p", null, "Heslo mus\u00ED m\u00EDt alespo\u0148 8 znak\u016F."))),
                        React.createElement(Checkbox, __assign({ label: "Odeslat heslo emailem" }, register("sendEmail"), { checked: getValues("sendEmail"), onChange: handleChange }))),
                    React.createElement(Accordion, { label: "Role", open: accordionStatus.role, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { role: !accordionStatus.role }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 }, availableRoles.map(function (role) {
                                var _a;
                                return (React.createElement(Checkbox, { key: role, label: getRolesTranslations(intl, role), name: "role_".concat(role), checked: (_a = getValues("selectedRoles")) === null || _a === void 0 ? void 0 : _a.includes(role), onChange: handleChange }));
                            })))))))));
};
export default UserCreate;
