export var ebookTranslations = {
    "": { id: "ui-br-publishers.publishers.select.label.default" },
    pdf: {
        id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.pdf",
    },
    epub: {
        id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.epub",
    },
    mobi: {
        id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.mobi",
    },
    OTHER: {
        id: "ui-br-publishers.publishers.workSpec.select.label.ebookFormats.other",
    },
};
export var getEbookTranslations = function (intl, key) {
    var translation = ebookTranslations[key];
    if (!translation)
        return key;
    return intl.formatMessage(translation);
};
