import { Row, Col } from "@folio/stripes/components";
import React from "react";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
import { formatDate } from "../../../utils/dateTimeUtil";
export var TimestampsAccordion = function (_a) {
    var _b, _c, _d, _e;
    var publisher = _a.publisher;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.timeOfRegistration",
                    }), value: formatDate((_b = publisher.createTime) !== null && _b !== void 0 ? _b : "") })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.timeOfActivation",
                    }), value: formatDate((_c = publisher.activationTime) !== null && _c !== void 0 ? _c : "") }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.timeOfConfirmation",
                    }), value: formatDate((_d = publisher.dataConfirmationTime) !== null && _d !== void 0 ? _d : "") })),
            React.createElement(Col, { xs: 12, md: 6 },
                React.createElement(RenderField, { label: formatMessage({
                        id: "ui-br-publishers.ares.field.label.timeOfClosure",
                    }), value: formatDate((_e = publisher.closeTime) !== null && _e !== void 0 ? _e : "") })))));
};
