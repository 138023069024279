import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Redirect } from "react-router";
import { useOkapiKy } from "@folio/stripes/core";
import MyProfileDetail from "./MyProfileDetail";
import MyProfilePassword from "./MyProfilePassword";
import SelectOrganization from "./SelectOrganization";
import ImportPublishers from "../import/ImportPublishers";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
function MyProfile() {
    var path = useRouteMatch().path;
    var activeRoles = useActiveRoles(useOkapiKy());
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: MyProfileDetail }),
        React.createElement(Route, { path: "".concat(cleanPath, "/password"), render: function () { return React.createElement(MyProfilePassword, null); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/select-organization"), render: function () {
                return hasRole(activeRoles, RoleEnum.NONE) ? (React.createElement(SelectOrganization, null)) : (React.createElement(Redirect, { to: "".concat(cleanPath) }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/import"), render: function () {
                return hasRole(activeRoles, RoleEnum.SUPERUSER) ? (React.createElement(ImportPublishers, null)) : (React.createElement(Redirect, { to: "".concat(cleanPath) }));
            } })));
}
export default MyProfile;
