var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useRef } from "react";
import { Pane, Button, Row, Col, Select, AccordionSet, Accordion, Checkbox, ErrorModal, PaneFooter, ConfirmationModal, Datepicker, } from "@folio/stripes/components";
import { useQuery } from "react-query";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { useOkapiKy } from "@folio/stripes/core";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";
import { fetchAddress, fetchAddressCode, getDuplicities, lookupPublisherByCompanyNumber, updatePublisher, } from "../../api/publisherService";
import RecekTextField from "../../components/RecekTextField";
import { fetchPublisherUpdateRequestById, resolvePublisherUpdateRequest, } from "../../api/publisherUpdateRequestService";
import { createPublisherSchema } from "../../utils/validationUtils";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import DuplicateTable from "../../components/DuplicateTable";
var PublisherEdit = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var formatMessage = useIntl().formatMessage;
    var _k = useState([]), potentialDuplicates = _k[0], setPotentialDuplicates = _k[1];
    var _l = useState(""), duplicateWarning = _l[0], setDuplicateWarning = _l[1];
    var skipDuplicateCheckRef = useRef(false);
    var updatedDataref = useRef();
    var publisherSchema = createPublisherSchema(formatMessage);
    var _m = useForm({
        resolver: zodResolver(publisherSchema),
        shouldFocusError: false,
        defaultValues: {
            companyNumber: "",
            name: "",
            nameSuffix: "",
            qualifier: "",
            legalForm: "LEGAL",
            taxNumber: "",
            birthDate: "",
            mainAddress: {
                addressCode: "",
                street: "",
                postalCode: "",
                municipality: "",
                region: { id: "", name: "" },
            },
            guarantors: [],
            contacts: [],
            alternativeNames: [],
            internalComment: "",
            version: 0,
        },
        mode: "onBlur",
    }), handleSubmit = _m.handleSubmit, control = _m.control, setValue = _m.setValue, getValues = _m.getValues, reset = _m.reset, watch = _m.watch, _o = _m.formState, errors = _o.errors, dirtyFields = _o.dirtyFields;
    var _p = useState({
        version: 0,
        id: "",
        companyNumber: "",
        name: "",
        nameSuffix: "",
        qualifier: "",
        legalForm: "LEGAL",
        taxNumber: "",
        birthDate: "",
        mainAddress: {
            addressCode: "",
            street: "",
            postalCode: "",
            municipality: "",
            region: { id: "", name: "" },
        },
        mailingAddress: {
            addressCode: "",
            street: "",
            postalCode: "",
            municipality: "",
            region: { id: "", name: "" },
        },
        guarantors: [],
        contacts: [],
        alternativeNames: [],
        internalComment: "",
        origin: "PUBLISHER",
        status: "ACTIVE",
        createTime: new Date().toISOString(),
        dataConfirmationTime: new Date().toISOString(),
    }), publisherValues = _p[0], setPublisherValues = _p[1];
    var _q = useFieldArray({
        control: control,
        name: "alternativeNames",
    }), alternativeNameFields = _q.fields, appendAlternativeName = _q.append, removeAlternativeNameAtIndex = _q.remove;
    var _r = useFieldArray({
        control: control,
        name: "guarantors",
    }), guarantorFields = _r.fields, appendGuarantor = _r.append, removeGuarantorAtIndex = _r.remove;
    var _s = useState(false), expandAll = _s[0], setExpandAll = _s[1];
    var _t = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
        guarantors: true,
    }), accordionStatus = _t[0], setAccordionStatus = _t[1];
    var _u = useState(false), errorModalOpen = _u[0], setErrorModalOpen = _u[1];
    var _v = useState(false), confirmModalOpen = _v[0], setConfirmModalOpen = _v[1];
    var _w = useState(""), errorMessage = _w[0], setErrorMessage = _w[1];
    var _x = useState([]), setAddressData = _x[1];
    var _y = useState([]), setMailingAddressData = _y[1];
    var mailingAddressQuery = useState("")[0];
    var _z = useState(false), showMailingAddress = _z[0], setShowMailingAddress = _z[1];
    var _0 = useState(""), addressQuery = _0[0], setAddressQuery = _0[1];
    var publisherUpdatesData = useQuery(["publisher", id], function () { return fetchPublisherUpdateRequestById(ky, id); }, {
        enabled: !!id,
        onSuccess: function (_a) {
            var _b;
            var data = _a.data, publisher = _a.publisher;
            var updatedPublisher = __assign(__assign(__assign({}, publisher), data), { mailingAddress: ((_b = publisher === null || publisher === void 0 ? void 0 : publisher.mailingAddress) !== null && _b !== void 0 ? _b : data === null || data === void 0 ? void 0 : data.mailingAddress)
                    ? __assign(__assign({}, publisher === null || publisher === void 0 ? void 0 : publisher.mailingAddress), data === null || data === void 0 ? void 0 : data.mailingAddress)
                    : undefined, mainAddress: __assign(__assign({}, publisher.mainAddress), ((data === null || data === void 0 ? void 0 : data.mainAddress) || {})), contacts: (data === null || data === void 0 ? void 0 : data.contacts) !== undefined ? data.contacts : publisher.contacts, guarantors: (data === null || data === void 0 ? void 0 : data.guarantors) !== undefined
                    ? data.guarantors
                    : publisher.guarantors, alternativeNames: (data === null || data === void 0 ? void 0 : data.alternativeNames) !== undefined
                    ? data.alternativeNames
                    : publisher.alternativeNames });
            reset(updatedPublisher);
            setValue("version", updatedPublisher.version);
            setPublisherValues(publisher);
        },
    }).data;
    useEffect(function () {
        fetchAddress(ky, mailingAddressQuery)
            .then(function (addresses) {
            setMailingAddressData(addresses);
        })
            .catch(function (error) {
            console.error("Error fetching mailing addresses:", error);
            setMailingAddressData([]);
        });
    }, [mailingAddressQuery]);
    useEffect(function () {
        fetchAddress(ky, addressQuery)
            .then(function (publisherAddresses) {
            setAddressData(publisherAddresses);
        })
            .catch(function (error) {
            console.error("Error fetching addresses:", error);
            setAddressData([]);
        });
    }, [addressQuery]);
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name in getValues()) {
            setValue(name, value, { shouldValidate: true });
        }
    };
    var handleAddressSelection = function (selectedValue, type) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails = _a.sent();
                    setValue("".concat(type, ".addressCode"), selectedValue);
                    setValue("".concat(type, ".street"), addressDetails.street);
                    setValue("".concat(type, ".postalCode"), addressDetails.postalCode);
                    setValue("".concat(type, ".municipality"), addressDetails.municipality);
                    setValue("".concat(type, ".region"), addressDetails.region || { id: "", name: "" });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching ".concat(type, " details:"), error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var hasPublisherPermission = function (role) {
        if (status === "MY_PUBLISHER") {
            hasRole(activeRoles, role);
        }
        return false;
    };
    var addContact = function () {
        var contacts = __spreadArray([], (getValues("contacts") || []), true);
        contacts.push({ type: "EMAIL", value: "" });
        setValue("contacts", contacts, { shouldValidate: true });
    };
    var removeContact = function (index) {
        var contacts = __spreadArray([], (getValues("contacts") || []), true);
        contacts.splice(index, 1);
        setValue("contacts", contacts, { shouldValidate: true });
    };
    var toggleExpandCollapseAll = function () {
        setExpandAll(!expandAll);
        setAccordionStatus({
            general: !expandAll,
            address: !expandAll,
            contacts: !expandAll,
            alternativeNames: !expandAll,
            comments: !expandAll,
            guarantors: !expandAll,
        });
    };
    var onSubmit = function (data, status) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedData, publishers, filteredDuplicates, duplicatesSection, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    updatedData = __assign(__assign({}, publisherUpdatesData === null || publisherUpdatesData === void 0 ? void 0 : publisherUpdatesData.publisher), data);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 15, , 16]);
                    if (!(Object.keys(dirtyFields).length === 0)) return [3 /*break*/, 7];
                    if (!(status === "APPROVE")) return [3 /*break*/, 4];
                    return [4 /*yield*/, resolvePublisherUpdateRequest(ky, id, {
                            resolution: "APPROVED",
                        })];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, updatePublisher(ky, data.id, updatedData)];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 6];
                case 4:
                    if (!(status === "REJECT")) return [3 /*break*/, 6];
                    return [4 /*yield*/, resolvePublisherUpdateRequest(ky, id, {
                            resolution: "REJECTED",
                        })];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    history.push("/brpublishers/publisher-updates");
                    return [2 /*return*/];
                case 7:
                    if (!(!skipDuplicateCheckRef.current ||
                        !isEqual(updatedData, updatedDataref.current))) return [3 /*break*/, 9];
                    return [4 /*yield*/, getDuplicities(ky, updatedData)];
                case 8:
                    publishers = (_b.sent()).publishers;
                    if (publishers && publishers.length > 0) {
                        filteredDuplicates = publishers.filter(function (pub) { return pub.id !== id; });
                        if (filteredDuplicates.length > 0) {
                            setPotentialDuplicates(filteredDuplicates);
                            setDuplicateWarning(formatMessage({
                                id: "ui-br-publishers.publisher.warning.message.duplicatesFound",
                            }));
                            duplicatesSection = document.getElementById("duplicates-table");
                            if (duplicatesSection) {
                                duplicatesSection.scrollIntoView({ behavior: "smooth" });
                            }
                            skipDuplicateCheckRef.current = true;
                            updatedDataref.current = updatedData;
                            return [2 /*return*/];
                        }
                    }
                    _b.label = 9;
                case 9:
                    if (!(status === "APPROVE")) return [3 /*break*/, 12];
                    return [4 /*yield*/, resolvePublisherUpdateRequest(ky, id, {
                            resolution: "APPROVED",
                        })];
                case 10:
                    _b.sent();
                    return [4 /*yield*/, updatePublisher(ky, data.id, updatedData)];
                case 11:
                    _b.sent();
                    return [3 /*break*/, 14];
                case 12:
                    if (!(status === "REJECT")) return [3 /*break*/, 14];
                    return [4 /*yield*/, resolvePublisherUpdateRequest(ky, id, {
                            resolution: "REJECTED",
                        })];
                case 13:
                    _b.sent();
                    _b.label = 14;
                case 14:
                    skipDuplicateCheckRef.current = false;
                    setDuplicateWarning("");
                    history.push("/brpublishers/publisher-updates");
                    return [3 /*break*/, 16];
                case 15:
                    error_2 = _b.sent();
                    if (((_a = error_2.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                        setErrorMessage("Záznam není možné uložit. Záznam byl mezitím upraven. Prosím, načtěte jej a znovu upravte.");
                        setConfirmModalOpen(true);
                    }
                    else {
                        setErrorMessage(status === "APPROVE"
                            ? "Došlo k chybě při schvalení nakladatele."
                            : status === "REJECT"
                                ? "Došlo k chybě při zamítnutí nakladatele."
                                : "Došlo k chybě.");
                        setErrorModalOpen(true);
                    }
                    console.error("Error updating publisher:", error_2);
                    return [3 /*break*/, 16];
                case 16: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (publisherUpdatesData === null || publisherUpdatesData === void 0 ? void 0 : publisherUpdatesData.publisher) {
            getDuplicities(ky, publisherUpdatesData.publisher)
                .then(function (_a) {
                var publishers = _a.publishers;
                if (publishers && publishers.length > 0) {
                    setPotentialDuplicates(publishers.filter(function (pub) { return pub.id !== id; }));
                }
                else {
                    setPotentialDuplicates([]);
                }
            })
                .catch(function (error) {
                return console.error("Error fetching duplicates on load:", error);
            });
        }
    }, [id, publisherUpdatesData === null || publisherUpdatesData === void 0 ? void 0 : publisherUpdatesData.publisher]);
    var handleIco = function () { return __awaiter(void 0, void 0, void 0, function () {
        var companyNumber, publisher, error_3;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 4, , 5]);
                    companyNumber = getValues("companyNumber");
                    if (!companyNumber || companyNumber.trim() === "") {
                        setErrorMessage("IČO je povinné.");
                        setErrorModalOpen(true);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, lookupPublisherByCompanyNumber(ky, companyNumber)];
                case 1:
                    publisher = _f.sent();
                    setValue("name", publisher.name, { shouldValidate: true });
                    setValue("legalForm", (_a = publisher.legalForm) !== null && _a !== void 0 ? _a : getValues("legalForm"), {
                        shouldValidate: true,
                    });
                    setValue("taxNumber", (_b = publisher.taxNumber) !== null && _b !== void 0 ? _b : getValues("taxNumber"), {
                        shouldValidate: true,
                    });
                    setValue("contacts", (_d = (_c = publisher.contacts) !== null && _c !== void 0 ? _c : getValues("contacts")) !== null && _d !== void 0 ? _d : [], {
                        shouldValidate: true,
                    });
                    if (!((_e = publisher.mainAddress) === null || _e === void 0 ? void 0 : _e.addressCode)) return [3 /*break*/, 3];
                    return [4 /*yield*/, handleAddressSelection(publisher.mainAddress.addressCode, "mainAddress")];
                case 2:
                    _f.sent();
                    _f.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_3 = _f.sent();
                    console.error("Error fetching Ares data:", error_3);
                    setErrorMessage("Došlo k chybě při hledáni IČO. Chyba ze serveru: " + error_3);
                    setErrorModalOpen(true);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "Schv\u00E1len\u00ED / Zam\u00EDtnut\u00ED aktualizace nakladatele", footer: React.createElement(PaneFooter, { renderStart: React.createElement(React.Fragment, null,
                    React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: function () {
                            handleSubmit(function (data) { return onSubmit(data, "APPROVE"); })();
                        } }, "Schv\u00E1lit"),
                    React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: function () {
                            handleSubmit(function (data) { return onSubmit(data, "REJECT"); })();
                        } }, "Zam\u00EDtnout")), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () {
                        history.goBack();
                    } }, "Zru\u0161it") }) },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } }),
            React.createElement(ConfirmationModal, { open: confirmModalOpen, heading: "Chyba", message: errorMessage, onConfirm: function () { return window.location.reload(); }, onCancel: function () { return setConfirmModalOpen(false); }, confirmLabel: "Znovu na\u010D\u00EDst", cancelLabel: "Zav\u0159\u00EDt", buttonStyle: "primary" }),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { onSubmit: function (e) { return e.preventDefault(); } },
                React.createElement(AccordionSet, null,
                    (potentialDuplicates === null || potentialDuplicates === void 0 ? void 0 : potentialDuplicates.length) > 0 ? (React.createElement(Accordion, { id: "duplicates-table", label: formatMessage({
                            id: "ui-br-publishers.publishers.accordion.label.potentialDuplicates",
                        }) },
                        duplicateWarning && (React.createElement("div", { style: { color: "red", marginBottom: "1rem" } }, duplicateWarning)),
                        React.createElement(DuplicateTable, { duplicates: potentialDuplicates }))) : null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "companyNumber", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(React.Fragment, null,
                                            React.createElement(RecekTextField, __assign({ label: "I\u010CO", required: true }, field, { error: (_b = errors.companyNumber) === null || _b === void 0 ? void 0 : _b.message })),
                                            React.createElement(Button, { buttonStyle: "primary", onClick: handleIco, style: {
                                                    marginLeft: "16px",
                                                    marginBottom: errors.companyNumber
                                                        ? "2.6rem"
                                                        : "1rem",
                                                } }, "Na\u010D\u00EDst dle I\u010CO z rejst\u0159\u00EDku")));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "I\u010CO, st\u00E1vaj\u00EDc\u00ED", name: "companyNumber", onChange: handleInputChange, value: publisherValues.companyNumber, disabled: true }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "name", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "N\u00E1zev" }, field, { required: true, error: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "N\u00E1zev, st\u00E1vaj\u00EDc\u00ED", name: "name", onChange: handleInputChange, value: publisherValues.name, disabled: true }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "nameSuffix", render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.field.label.nameSuffix",
                                            }) }, field)));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "".concat(formatMessage({
                                        id: "ui-br-publishers.publishers.general.field.label.nameSuffix",
                                    }), ", ").concat(formatMessage({
                                        id: "ui-br-publishers.general.current",
                                    }).toLowerCase()), name: "nameSuffix", onChange: handleInputChange, value: publisherValues.nameSuffix, disabled: true }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "taxNumber", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "\u010C\u00EDslo DI\u010C" }, field, { required: true, error: (_b = errors.taxNumber) === null || _b === void 0 ? void 0 : _b.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "\u010C\u00EDslo DI\u010C, st\u00E1vaj\u00EDc\u00ED", name: "taxNumber", onChange: handleInputChange, value: publisherValues.taxNumber, disabled: true }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "birthDate", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(Datepicker, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.datePicker.label.birthDate",
                                            }) }, field, { error: (_b = errors.birthDate) === null || _b === void 0 ? void 0 : _b.message, exclude: function (day) { return day.isAfter(moment()); }, disabled: getValues("legalForm") !== "NATURAL", backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY", placeholder: "DD/MM/RRRR" })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "birthDate", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(Datepicker, __assign({ label: formatMessage({
                                                id: "ui-br-publishers.publishers.general.datePicker.label.birthDate.current",
                                            }) }, field, { error: (_b = errors.birthDate) === null || _b === void 0 ? void 0 : _b.message, exclude: function (day) { return day.isAfter(moment()); }, disabled: true, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY", placeholder: "DD/MM/RRRR" })));
                                    } })))),
                    React.createElement(Accordion, { label: "Adresa", open: accordionStatus.address, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.street", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Ulice", error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "Ulice, st\u00E1vaj\u00EDc\u00ED", name: "street", onChange: function () { }, value: (_a = publisherValues.mainAddress) === null || _a === void 0 ? void 0 : _a.street, disabled: true }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.postalCode", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "PS\u010C", error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.postalCode) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "PS\u010C, st\u00E1vaj\u00EDc\u00ED", name: "postalCode", onChange: function () { }, value: (_b = publisherValues.mainAddress) === null || _b === void 0 ? void 0 : _b.postalCode, disabled: true }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "mainAddress.municipality", render: function (_a) {
                                        var _b, _c;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Obec", error: (_c = (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.municipality) === null || _c === void 0 ? void 0 : _c.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "Obec, st\u00E1vaj\u00EDc\u00ED", name: "municipality", onChange: function () { }, value: (_c = publisherValues.mainAddress) === null || _c === void 0 ? void 0 : _c.municipality, disabled: true }))),
                        React.createElement(Checkbox, { label: "Li\u0161\u00ED se doru\u010Dovac\u00ED adresa?", checked: showMailingAddress, onChange: function (e) {
                                var checked = e.target.checked;
                                setShowMailingAddress(checked);
                                if (!checked) {
                                    setValue("mailingAddress", undefined);
                                }
                                else {
                                    setValue("mailingAddress", {
                                        street: "",
                                        postalCode: "",
                                        municipality: "",
                                        region: { id: "", name: "" },
                                    });
                                }
                            } }),
                        showMailingAddress && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.street", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: "Ulice", error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) === null || _c === void 0 ? void 0 : _c.message })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "Ulice, st\u00E1vaj\u00EDc\u00ED", name: "street", onChange: function () { }, value: (_d = publisherValues.mailingAddress) === null || _d === void 0 ? void 0 : _d.street, disabled: true }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.postalCode", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: "PS\u010C", error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.postalCode) === null || _c === void 0 ? void 0 : _c.message })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "PS\u010C, st\u00E1vaj\u00EDc\u00ED", name: "postalCode", onChange: function () { }, value: (_e = publisherValues.mailingAddress) === null || _e === void 0 ? void 0 : _e.postalCode, disabled: true }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Controller, { control: control, name: "mailingAddress.municipality", render: function (_a) {
                                            var _b, _c;
                                            var field = _a.field;
                                            return (React.createElement(RecekTextField, __assign({}, field, { label: "Obec", error: (_c = (_b = errors.mailingAddress) === null || _b === void 0 ? void 0 : _b.municipality) === null || _c === void 0 ? void 0 : _c.message })));
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "Obec, st\u00E1vaj\u00EDc\u00ED", name: "municipality", onChange: function () { }, value: (_f = publisherValues.mailingAddress) === null || _f === void 0 ? void 0 : _f.municipality, disabled: true })))))),
                    React.createElement(Accordion, { label: "Kontakty", open: accordionStatus.contacts, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { contacts: !accordionStatus.contacts }));
                        } }, (_g = getValues("contacts")) === null || _g === void 0 ? void 0 :
                        _g.map(function (contact, index) { return (React.createElement(Row, { key: index },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "contacts.".concat(index, ".type"), render: function (_a) {
                                        var _b, _c, _d, _e, _f;
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Typ kontaktu", required: true, error: typeof ((_c = (_b = errors.contacts) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.type) === "object"
                                                ? (_f = (_e = (_d = errors.contacts) === null || _d === void 0 ? void 0 : _d[index]) === null || _e === void 0 ? void 0 : _e.type) === null || _f === void 0 ? void 0 : _f.message
                                                : undefined, dataOptions: [
                                                { value: "EMAIL", label: "E-mail" },
                                                { value: "WEB", label: "Web" },
                                                { value: "PHONE", label: "Telefon" },
                                                { value: "DATA_BOX", label: "Datová schránka" },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "contacts.".concat(index, ".value"), render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Kontakt", required: true, error: (_d = (_c = (_b = errors.contacts) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeContact(index); }, style: { marginTop: "25px" } }, "Odstranit")))); }),
                        React.createElement(Button, { onClick: addContact }, "P\u0159idat kontakt"),
                        publisherValues.contacts ? React.createElement("h3", null, "St\u00E1vaj\u00EDc\u00ED") : React.createElement(React.Fragment, null), (_h = publisherValues.contacts) === null || _h === void 0 ? void 0 :
                        _h.map(function (contact, index) {
                            var _a, _b, _c;
                            return (React.createElement(Row, { key: index },
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Select, { label: "Typ kontaktu", name: "type", value: contact.type, disabled: true, onChange: function () { } },
                                        React.createElement("option", { value: "EMAIL" }, "E-mail"),
                                        React.createElement("option", { value: "WEB" }, "Web"),
                                        React.createElement("option", { value: "PHONE" }, "Telefon"),
                                        React.createElement("option", { value: "DATA_BOX" }, "Datov\u00E1 schr\u00E1nka"))),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(RecekTextField, { label: "Kontakt", name: "value", disabled: true, value: contact.value, onChange: function () { }, error: (_c = (_b = (_a = errors.contacts) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.message })),
                                React.createElement(Col, { xs: 2 },
                                    React.createElement(Button, { buttonStyle: "danger", disabled: true, onClick: function () { }, style: { marginTop: "25px" } }, "Odstranit"))));
                        })),
                    React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames }));
                        } },
                        alternativeNameFields.map(function (field, index) { return (React.createElement(Row, { key: field.id },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".name"), defaultValue: field.name, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Alternativn\u00ED n\u00E1zev", required: true, error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nameSuffix"), defaultValue: field.nameSuffix, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "P\u0159\u00EDpona n\u00E1zvu", error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.nameSuffix) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".qualifier"), defaultValue: field.qualifier, render: function (_a) {
                                        var _b, _c, _d;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({}, field, { label: "Kvalifik\u00E1tor", error: (_d = (_c = (_b = errors.alternativeNames) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.qualifier) === null || _d === void 0 ? void 0 : _d.message })));
                                    } })),
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".type"), defaultValue: field.type, render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Select, __assign({}, field, { label: "Typ", dataOptions: [
                                                { value: "PREVIOUS", label: "Předchozí název" },
                                                { value: "IMPRINT", label: "Imprint" },
                                                { value: "ABBREVIATION", label: "Zkratka" },
                                                {
                                                    value: "ORGANIZATION_UNIT",
                                                    label: "Organizační jednotka",
                                                },
                                                {
                                                    value: "FOREIGN_LANGUAGE",
                                                    label: "Cizojazyčný ekvivalent",
                                                },
                                            ] })));
                                    } })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Controller, { control: control, name: "alternativeNames.".concat(index, ".nonPublic"), defaultValue: field.nonPublic, render: function (_a) {
                                        var field = _a.field;
                                        return (React.createElement(Checkbox, { checked: field.value, onChange: function (e) { return field.onChange(e.target.checked); }, label: "Neve\u0159ejn\u00E9" }));
                                    } })),
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeNameAtIndex(index); }, style: { marginTop: "10px" } }, "Odstranit alternativn\u00ED n\u00E1zev")))); }),
                        React.createElement(Button, { onClick: function () {
                                return appendAlternativeName({
                                    name: "",
                                    nameSuffix: "",
                                    qualifier: "",
                                    type: undefined,
                                    nonPublic: false,
                                }, { shouldFocus: false });
                            } }, "P\u0159idat alternativn\u00ED n\u00E1zev"),
                        publisherValues.alternativeNames ? React.createElement("h3", null, "St\u00E1vaj\u00EDc\u00ED") : React.createElement(React.Fragment, null), (_j = publisherValues.alternativeNames) === null || _j === void 0 ? void 0 :
                        _j.map(function (alternativeName, index) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                            return (React.createElement(Row, { key: index },
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(RecekTextField, { label: "Alternativn\u00ED n\u00E1zev", name: "name", disabled: true, value: alternativeName.name, onChange: function () { }, required: true, error: ((_a = errors.alternativeNames) === null || _a === void 0 ? void 0 : _a.message) &&
                                            ((_c = (_b = errors.alternativeNames[index]) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.message) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(RecekTextField, { label: "P\u0159\u00EDpona n\u00E1zvu", name: "nameSuffix", disabled: true, value: alternativeName.nameSuffix || "", onChange: function () { }, error: ((_d = errors.alternativeNames) === null || _d === void 0 ? void 0 : _d.message) &&
                                            ((_f = (_e = errors.alternativeNames[index]) === null || _e === void 0 ? void 0 : _e.nameSuffix) === null || _f === void 0 ? void 0 : _f.message) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(RecekTextField, { label: "Kvalifik\u00E1tor", name: "qualifier", disabled: true, value: alternativeName.qualifier || "", onChange: function () { }, error: ((_g = errors.alternativeNames) === null || _g === void 0 ? void 0 : _g.message) &&
                                            ((_j = (_h = errors.alternativeNames[index]) === null || _h === void 0 ? void 0 : _h.qualifier) === null || _j === void 0 ? void 0 : _j.message) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Select, { label: "Typ", name: "type", disabled: true, value: alternativeName.type || "", onChange: function () { } },
                                        React.createElement("option", { value: "" }, "-"),
                                        React.createElement("option", { value: "PREVIOUS" }, "P\u0159edchoz\u00ED n\u00E1zev"),
                                        React.createElement("option", { value: "IMPRINT" }, "Imprint"),
                                        React.createElement("option", { value: "ABBREVIATION" }, "Zkratka"),
                                        React.createElement("option", { value: "ORGANIZATION_UNIT" }, "Organiza\u010Dn\u00ED jednotka"),
                                        React.createElement("option", { value: "FOREIGN_LANGUAGE" }, "Cizojazy\u010Dn\u00FD ekvivalent"))),
                                React.createElement(Col, { xs: 2 },
                                    React.createElement(Checkbox, { label: "Neve\u0159ejn\u00E9", checked: alternativeName.nonPublic, disabled: true, onChange: function () { } }))));
                        })),
                    React.createElement(Accordion, { label: "Garanti", id: "guarantors", open: accordionStatus.guarantors, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { guarantors: !accordionStatus.guarantors }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                guarantorFields.map(function (guarantor, index) {
                                    var _a;
                                    return (React.createElement("div", { key: guarantor.id },
                                        React.createElement(Row, null,
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".titlesBefore"), render: function (_a) {
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: "Tituly p\u0159ed jm\u00E9nem" }, field, { disabled: hasPublisherPermission(RoleEnum.PUBLISHER_GUARANTOR) })));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".firstName"), render: function (_a) {
                                                        var _b, _c, _d;
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: "Jm\u00E9no" }, field, { required: true, disabled: hasPublisherPermission(RoleEnum.PUBLISHER_GUARANTOR), error: (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.firstName) === null || _d === void 0 ? void 0 : _d.message })));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".lastName"), render: function (_a) {
                                                        var _b, _c, _d;
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: "P\u0159\u00EDjmen\u00ED" }, field, { required: true, disabled: hasPublisherPermission(RoleEnum.PUBLISHER_GUARANTOR), error: (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.lastName) === null || _d === void 0 ? void 0 : _d.message })));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".titlesAfter"), render: function (_a) {
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: "Tituly za jm\u00E9nem" }, field, { disabled: hasPublisherPermission(RoleEnum.PUBLISHER_GUARANTOR) })));
                                                    } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".email"), render: function (_a) {
                                                        var _b, _c, _d;
                                                        var field = _a.field;
                                                        return (React.createElement(RecekTextField, __assign({ label: "Email" }, field, { required: true, disabled: hasPublisherPermission(RoleEnum.PUBLISHER_GUARANTOR), error: (_d = (_c = (_b = errors.guarantors) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.message })));
                                                    } })),
                                            React.createElement(Col, { xs: 2 },
                                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeGuarantorAtIndex(index); }, style: { marginTop: "25px" } }, "Smazat garanta")),
                                            React.createElement(Col, { xs: 12 },
                                                React.createElement(Row, null,
                                                    React.createElement("h3", null, "Kontakty")), (_a = watch("guarantors.".concat(index, ".contacts"))) === null || _a === void 0 ? void 0 :
                                                _a.map(function (contact, contactIndex) { return (React.createElement(Row, { key: contactIndex, style: { marginBottom: "10px" } },
                                                    React.createElement(Col, { xs: 4 },
                                                        React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".contacts.").concat(contactIndex, ".type"), render: function (_a) {
                                                                var field = _a.field;
                                                                return (React.createElement(Select, __assign({ label: "Typ kontaktu" }, field),
                                                                    React.createElement("option", { value: "EMAIL" }, "E-mail"),
                                                                    React.createElement("option", { value: "WEB" }, "Web"),
                                                                    React.createElement("option", { value: "PHONE" }, "Telefon"),
                                                                    React.createElement("option", { value: "DATA_BOX" }, "Datov\u00E1 schr\u00E1nka")));
                                                            } })),
                                                    React.createElement(Col, { xs: 6 },
                                                        React.createElement(Controller, { control: control, name: "guarantors.".concat(index, ".contacts.").concat(contactIndex, ".value"), render: function (_a) {
                                                                var field = _a.field;
                                                                return (React.createElement(RecekTextField, __assign({ label: "Kontakt" }, field)));
                                                            } })),
                                                    React.createElement(Col, { xs: 2 },
                                                        React.createElement(Button, { buttonStyle: "danger", onClick: function () {
                                                                var currentContacts = getValues("guarantors.".concat(index, ".contacts")) || [];
                                                                var updatedContacts = currentContacts.filter(function (_, i) { return i !== contactIndex; });
                                                                setValue("guarantors.".concat(index, ".contacts"), updatedContacts);
                                                            }, style: { marginTop: "25px" } }, "Odstranit")))); }),
                                                React.createElement(Button, { type: "button", onClick: function () {
                                                        var currentContacts = getValues("guarantors.".concat(index, ".contacts")) || [];
                                                        setValue("guarantors.".concat(index, ".contacts"), __spreadArray(__spreadArray([], currentContacts, true), [
                                                            { type: "EMAIL", value: "" },
                                                        ], false));
                                                    } }, "P\u0159idat kontakt")))));
                                }),
                                React.createElement(Button, { type: "button", onClick: function () {
                                        return appendGuarantor({
                                            id: "",
                                            firstName: "",
                                            lastName: "",
                                            email: "",
                                            contacts: [],
                                            user: {
                                                sourceId: "",
                                                firstName: "",
                                                lastName: "",
                                                email: "",
                                                roles: [],
                                            },
                                        });
                                    }, style: { marginTop: "20px" } }, "P\u0159idat garanta"),
                                publisherValues.guarantors ? React.createElement("h3", null, "St\u00E1vaj\u00EDc\u00ED") : React.createElement(React.Fragment, null),
                                (publisherValues.guarantors || []).map(function (guarantor, index) {
                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                    return (React.createElement("div", { key: index },
                                        React.createElement(Row, null,
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(RecekTextField, { label: "Tituly p\u0159ed jm\u00E9nem", name: "titlesBefore", disabled: true, value: guarantor.titlesBefore || "", onChange: function () { } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(RecekTextField, { label: "Jm\u00E9no", name: "firstName", disabled: true, value: guarantor.firstName || "", required: true, onChange: function () { }, error: ((_a = errors.guarantors) === null || _a === void 0 ? void 0 : _a.message) &&
                                                        ((_c = (_b = errors.guarantors[index]) === null || _b === void 0 ? void 0 : _b.firstName) === null || _c === void 0 ? void 0 : _c.message) })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(RecekTextField, { label: "P\u0159\u00EDjmen\u00ED", name: "lastName", disabled: true, value: guarantor.lastName || "", required: true, onChange: function () { }, error: ((_d = errors.guarantors) === null || _d === void 0 ? void 0 : _d.message) &&
                                                        ((_f = (_e = errors.guarantors[index]) === null || _e === void 0 ? void 0 : _e.lastName) === null || _f === void 0 ? void 0 : _f.message) })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(RecekTextField, { label: "Tituly za jm\u00E9nem", name: "titlesAfter", disabled: true, value: guarantor.titlesAfter || "", onChange: function () { } })),
                                            React.createElement(Col, { xs: 12, md: 4 },
                                                React.createElement(RecekTextField, { label: "Email", name: "email", disabled: true, required: true, value: guarantor.email || "", onChange: function () { }, error: ((_g = errors.guarantors) === null || _g === void 0 ? void 0 : _g.message) &&
                                                        ((_j = (_h = errors.guarantors[index]) === null || _h === void 0 ? void 0 : _h.email) === null || _j === void 0 ? void 0 : _j.message) })),
                                            React.createElement(Col, { xs: 12 },
                                                React.createElement(Row, null,
                                                    React.createElement("h3", null, "Kontakty")), (_k = guarantor.contacts) === null || _k === void 0 ? void 0 :
                                                _k.map(function (contact, contactIndex) { return (React.createElement(Row, { key: contactIndex, style: { marginBottom: "10px" } },
                                                    React.createElement(Col, { xs: 4 },
                                                        React.createElement(Select, { label: "Typ kontaktu", name: "type", value: contact.type, disabled: true, onChange: function () { } },
                                                            React.createElement("option", { value: "EMAIL" }, "E-mail"),
                                                            React.createElement("option", { value: "WEB" }, "Web"),
                                                            React.createElement("option", { value: "PHONE" }, "Telefon"),
                                                            React.createElement("option", { value: "DATA_BOX" }, "Datov\u00E1 schr\u00E1nka"))),
                                                    React.createElement(Col, { xs: 6 },
                                                        React.createElement(RecekTextField, { label: "Kontakt", name: "value", disabled: true, value: contact.value, onChange: function () { } })))); })))));
                                })))))))));
};
export default PublisherEdit;
