var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, Accordion, IconButton, AccordionSet, Icon, Selection, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import UserDetail from "./UserDetail";
import { fetchUserById, fetchUserOrganizations, fetchUsers, } from "../../api/usersService";
import { fetchOrganizationData, fetchOrganizations, } from "../../api/organizationService";
import RecekTextField from "../../components/RecekTextField";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { RecekPaneSet } from "../../components/RecekPaneSet";
var PAGE_SIZE = 20;
var UsersList = function () {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var formatMessage = useIntl().formatMessage;
    var _b = useState({}), userOrganizations = _b[0], setUserOrganizations = _b[1];
    var _c = useState(""), searchFilter = _c[0], setSearchFilter = _c[1];
    var _d = useState(""), organizationFilter = _d[0], setOrganizationFilter = _d[1];
    // const [archivedFilter, setArchivedFilter] = useState<boolean>(false);
    var _e = useState(null), selectedUser = _e[0], setSelectedUser = _e[1];
    var _f = useState({
        search: "",
        organization: "",
        // archived: false,
    }), filters = _f[0], setFilters = _f[1];
    var _g = useState(""), selectedOrg = _g[0], setSelectedOrg = _g[1]; // Holds selected user ID
    var _h = useState(false), orgLoading = _h[0], setOrgLoading = _h[1]; // Loading state for users
    var _j = useState(""), orgQuery = _j[0], setOrgQuery = _j[1]; // Holds the current input text for user search
    var _k = useState(0), pageOffset = _k[0], setPageOffset = _k[1];
    var _l = useState(0), totalRecords = _l[0], setTotalRecords = _l[1];
    var _m = useState(""), name = _m[0], setName = _m[1];
    var _o = useState([]), organizations = _o[0], setOrganizations = _o[1];
    var debouncedSetUserQuery = useCallback(debounce(function (inputValue) {
        setOrgQuery(inputValue);
    }, 300), []);
    // Handle input changes in the Selection component for users
    var handleOrganizationInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOrgLoading(true);
                    setName(inputValue);
                    return [4 /*yield*/, debouncedSetUserQuery(inputValue)];
                case 1:
                    _a.sent(); // Use inputValue for debouncing
                    setOrgLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleOrganizationSelection = function (selectedValue) {
        setSelectedOrg(selectedValue);
        setOrganizationFilter(selectedValue);
    };
    var _p = useState(true), filterPaneIsVisible = _p[0], setFilterPaneIsVisible = _p[1];
    useEffect(function () {
        var fetchFilteredOrganizations = function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetchedUsers, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!name) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchOrganizations(ky, { name: name })];
                    case 1:
                        fetchedUsers = _a.sent();
                        setOrganizations(fetchedUsers.organizations);
                        return [3 /*break*/, 3];
                    case 2:
                        // Clear user list if no filters are set
                        setOrganizations([]);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error("Error fetching organizations:", error_1);
                        // Clear user list in case of error
                        setOrganizations([]);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchFilteredOrganizations();
    }, [name]);
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var userId = queryParams.get("detail");
        setFilters({
            search: queryParams.get("search") || "",
            organization: queryParams.get("organization") || "",
        });
        setSearchFilter(queryParams.get("search") || "");
        setOrganizationFilter(queryParams.get("organization") || "");
        setSelectedOrg(queryParams.get("organization") || "");
        if (queryParams.get("organization")) {
            var fetchFilteredOrganization = function () { return __awaiter(void 0, void 0, void 0, function () {
                var fetchedOrganization, error_2;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, fetchOrganizationData((_a = queryParams.get("organization")) !== null && _a !== void 0 ? _a : "", ky)];
                        case 1:
                            fetchedOrganization = _b.sent();
                            setOrganizations([fetchedOrganization]);
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _b.sent();
                            console.error("Error fetching organizations:", error_2);
                            // Clear user list in case of error
                            setOrganizations([]);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            fetchFilteredOrganization();
        }
        if (userId) {
            fetchUserById(ky, userId).then(function (user) {
                if (user) {
                    setSelectedUser(user);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.search)
            params.set("search", filters.search);
        if (filters.organization)
            params.set("organization", filters.organization);
        // if (filters.archived !== undefined) params.set('archived', filters.archived.toString());
        history.push({ search: params.toString() });
    };
    var handleClearFilters = function () {
        setSearchFilter("");
        setSelectedOrg("");
        // setArchivedFilter(false);
        setFilters({
            search: "",
            organization: "",
            // archived: false,
        });
        history.push({ search: "" });
    };
    var isAnyFilterActive = function () { return !!filters.search || !!filters.organization; };
    var _q = useQuery(["users", filters, pageOffset], function () {
        return fetchUsers(ky, __assign(__assign({}, filters), { offset: pageOffset, limit: PAGE_SIZE }));
    }, {
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _q.data, isLoading = _q.isLoading, isError = _q.isError, refetch = _q.refetch, isFetched = _q.isFetched;
    var usersData = (_a = data === null || data === void 0 ? void 0 : data.users) !== null && _a !== void 0 ? _a : [];
    useEffect(function () {
        var fetchOrganizationsForUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
            var organizationsMap, userIdsToFetch, _i, userIdsToFetch_1, userId, organizations_1, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        organizationsMap = {};
                        userIdsToFetch = usersData
                            .filter(function (user) { return !userOrganizations[user.id]; })
                            .map(function (user) { return user.id; });
                        _i = 0, userIdsToFetch_1 = userIdsToFetch;
                        _a.label = 1;
                    case 1:
                        if (!(_i < userIdsToFetch_1.length)) return [3 /*break*/, 6];
                        userId = userIdsToFetch_1[_i];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, fetchUserOrganizations(ky, userId)];
                    case 3:
                        organizations_1 = _a.sent();
                        organizationsMap[userId] =
                            organizations_1
                                .map(function (org) { return org.organization.name; })
                                .join(", ") || "N/A";
                        return [3 /*break*/, 5];
                    case 4:
                        error_3 = _a.sent();
                        console.error("Error fetching organizations:", error_3);
                        organizationsMap[userId] = "N/A"; // Fallback on error
                        return [3 /*break*/, 5];
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6:
                        // Merge with existing organizations only if there are new entries
                        if (Object.keys(organizationsMap).length > 0) {
                            setUserOrganizations(function (prev) { return (__assign(__assign({}, prev), organizationsMap)); });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        if (usersData.length > 0) {
            fetchOrganizationsForUsers();
        }
    }, [usersData, ky]); // Problematic dependency array
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: "Příjmení",
            key: "lastName",
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete("detail");
                currentParams.set("detail", item.id);
                history.push({
                    search: currentParams.toString(),
                });
                setSelectedUser(item);
            },
        },
        {
            name: "Jméno",
            key: "firstName",
        },
        {
            name: "E-mail",
            key: "email",
        },
        {
            name: "Organizace",
            key: "id",
            cellRenderer: function (item) {
                var orgName = userOrganizations[item.id];
                return orgName || "N/A";
            },
        },
    ];
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return usersData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isFetched; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.successfulMutations = function () { return []; };
    source.fetchMore = function () { return fetchMoreData; };
    return (React.createElement(RecekPaneSet, null,
        filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1ni", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Skrýt filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(NavComponent, null),
                React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters },
                    React.createElement(Icon, { icon: "times-circle-solid" }, formatMessage({
                        id: "ui-br-users.users.button.content.resetAll",
                    }))),
                React.createElement(AccordionSet, null,
                    React.createElement("form", { onSubmit: function (event) {
                            event.preventDefault();
                            var payload = __assign(__assign({}, filters), { search: searchFilter, organization: organizationFilter });
                            setPageOffset(0);
                            setFilters(payload);
                            updateUrlWithFilters(payload);
                        } },
                        React.createElement(Accordion, { label: formatMessage({
                                id: "ui-br-users.usersList.accordion.label.search",
                            }), separator: false },
                            React.createElement(RecekTextField, { placeholder: formatMessage({
                                    id: "ui-br-users.usersList.textField.placeholder.search",
                                }), value: searchFilter, onChange: function (e) { return setSearchFilter(e.target.value); } })),
                        React.createElement(Accordion, { label: formatMessage({
                                id: "ui-br-users.users.detail.accordion.label.organizations",
                            }), separator: false },
                            React.createElement(Selection, { name: "orgSelection", value: selectedOrg, disabled: orgLoading, onChange: handleOrganizationSelection, asyncFilter: true, onFilter: handleOrganizationInput, dataOptions: organizations
                                    ? organizations.map(function (org) { return ({
                                        value: org.id,
                                        label: org.name,
                                    }); })
                                    : [] })),
                        React.createElement(Button, { type: "submit", marginBottom0: true }, formatMessage({
                            id: "ui-br-users.button.apply",
                        }))))))),
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: formatMessage({
                id: "ui-br-users.usersList.pane.title.usersList",
            }), paneSub: formatMessage({ id: "ui-br-users.table.subtitle.records-found" }, { count: totalRecords }), actionMenu: function (_a) {
                var onToggle = _a.onToggle;
                return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" }, hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                        onToggle();
                        history.push("/brusers/users/create");
                    } }, "Nov\u00FD"))));
            }, firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
            React.createElement(TableComponent, { data: usersData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, onNeedMoreData: function (offset) {
                    fetchMoreData(offset);
                }, searchTerm: "", filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
        selectedUser ? (React.createElement(Pane, { id: "detailPane", defaultWidth: "35%", paneTitle: "Detail u\u017Eivatele", onClose: function () {
                setSelectedUser(null);
                var queryParams = new URLSearchParams(location.search);
                queryParams.delete("detail");
                history.replace({ search: queryParams.toString() });
            }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                        return history.push("/brusers/users/edit/".concat(selectedUser.id));
                    }, fullWidth: true }, "Zm\u011Bnit")),
                hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                        return history.push("/brusers/users/reset/".concat(selectedUser.id));
                    }, fullWidth: true }, "Resetovat Heslo")))); } },
            React.createElement(UserDetail, { user: selectedUser }))) : null));
};
export default UsersList;
