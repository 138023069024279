import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Redirect } from "react-router";
import React from "react";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import PublisherEdit from "../publishers/PublisherEdit";
import PublisherRegistrationsList from "./PublisherRegistrationsList";
function PublisherRegistration() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var activeRoles = useActiveRoles(useOkapiKy());
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: PublisherRegistrationsList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/approval/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherEdit, { status: "APPROVE" })) : (React.createElement(Redirect, { to: "".concat(path) }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/restore/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherEdit, { status: "RESTORE" })) : (React.createElement(Redirect, { to: "".concat(path) }));
            } })));
}
export default PublisherRegistration;
