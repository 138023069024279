import React from "react";
import { Button, ButtonGroup, Layout } from "@folio/stripes/components";
import { useHistory, useLocation } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { useIntl } from "react-intl";
import { hasRole, useActiveRoles } from "../utils/permissionsUtils";
import { RoleEnum } from "../types/RoleEnum";
var NavigationComponent = function () {
    var history = useHistory();
    var location = useLocation();
    var okapiKy = useOkapiKy();
    var activeRoles = useActiveRoles(okapiKy);
    var intl = useIntl();
    var routes = [
        {
            path: "/brusers/my-profile",
            label: "Můj profil",
            requiredRole: RoleEnum.NONE,
        },
        {
            path: "/brusers/organizations",
            label: "Organizace",
            requiredRole: RoleEnum.AGENCY_WORKER,
        },
        {
            path: "/brusers/users",
            label: "Uživatelé",
            requiredRole: RoleEnum.AGENCY_WORKER,
        },
        {
            path: "/brusers/admin/environment-variables",
            label: intl.formatMessage({ id: "ui-br-users.admin.title" }),
            requiredRole: RoleEnum.SUPERUSER,
        },
    ];
    var currentPath = location.pathname;
    return (React.createElement(Layout, { className: "padding-start-gutter padding-end-gutter" },
        React.createElement("style", null, "\n          .nav-container-outer {\n            container-type: inline-size;\n          }\n          .horizontal-layout {\n            display: block;\n          }\n          .vertical-layout {\n            display: none;\n          }\n          @container (max-width: 460px) {\n            .horizontal-layout {\n              display: none;\n            }\n            .vertical-layout {\n              display: block;\n            }\n          }\n        "),
        React.createElement("div", { className: "nav-container-outer" },
            React.createElement("div", { className: "horizontal-layout" },
                React.createElement(ButtonGroup, { style: { marginBottom: "1rem" }, fullWidth: true }, routes.map(function (route) {
                    var isActive = currentPath.startsWith(route.path);
                    return (hasRole(activeRoles, route.requiredRole) && (React.createElement(Button, { key: route.path, onClick: function () { return history.push(route.path); }, style: {
                            backgroundColor: isActive ? "#1960a4" : "",
                            color: isActive ? "#fff" : "",
                            fontWeight: isActive ? "bold" : "",
                        } }, route.label)));
                }))),
            React.createElement("div", { className: "vertical-layout" },
                React.createElement("div", { style: {
                        backgroundColor: "#f5f5f5",
                        padding: "1rem",
                        borderRadius: "4px",
                    } },
                    React.createElement("div", { style: { display: "flex", flexDirection: "column", gap: "1rem" } }, routes.map(function (route) {
                        var isActive = currentPath === route.path;
                        return (hasRole(activeRoles, route.requiredRole) && (React.createElement(Button, { key: route.path, onClick: function () { return history.push(route.path); }, buttonStyle: isActive ? "primary" : "default", fullWidth: true, marginBottom0: true, style: { justifyContent: "flex-start" } }, route.label)));
                    })))))));
};
export default NavigationComponent;
