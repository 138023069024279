import React, { useMemo, useState } from "react";
import { Row, Col, Button } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import CustomAccordionSet from "../../../components/CustomAccordionSet";
import { SourceAccordionContent } from "./SourceAccordionContent";
import { ArchivedAccordionContent } from "./ArchivedAccordionContent";
import { ExtendedInformationAccordion } from "../../../components/ExtendedInformationAccordion";
var OrganizationDetail = function (_a) {
    var organization = _a.organization;
    var formatMessage = useIntl().formatMessage;
    var _b = useState(true), isAllExpanded = _b[0], setIsAllExpanded = _b[1];
    var toggleAccordionExpansion = function () {
        setIsAllExpanded(function (prevExpanded) { return !prevExpanded; });
    };
    var handleAccordionExpandAllChange = function (expanded) {
        setIsAllExpanded(expanded);
    };
    var accordionItems = useMemo(function () { return [
        {
            id: "source",
            label: "Zdroje",
            content: React.createElement(SourceAccordionContent, { source: organization.source }),
        },
        {
            id: "archived",
            label: "Archivováno",
            content: React.createElement(ArchivedAccordionContent, { archived: organization.archived }),
        },
        {
            id: "extendedInformation",
            label: formatMessage({
                id: "ui-br-users.accordions.label.extendedInformation",
            }),
            content: React.createElement(ExtendedInformationAccordion, { id: organization.id }),
        },
    ]; }, [
        formatMessage,
        organization.archived,
        organization.id,
        organization.source,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, organization.name),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleAccordionExpansion, buttonStyle: "default" }, isAllExpanded
                    ? formatMessage({ id: "ui-br-publishers.button.collapse.all" })
                    : formatMessage({ id: "ui-br-publishers.button.expand.all" })))),
        React.createElement(CustomAccordionSet, { accordions: accordionItems, expandAll: isAllExpanded, onToggleExpandAll: handleAccordionExpandAllChange })));
};
export default OrganizationDetail;
