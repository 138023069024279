import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, IconButton, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { useIntl } from "react-intl";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import PublisherDetail from "../publishers/PublisherDetail"; // Adjusted import path
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { fetchPublisherUpdateRequests } from "../../api/publisherUpdateRequestService";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { formatDate } from "../../utils/dateTimeUtil";
import { DataListPane } from "../../components/DataListPane";
var PAGE_SIZE = 20;
var PublisherUpdatesList = function () {
    var _a;
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var formatMessage = useIntl().formatMessage;
    var _b = useState(0), totalRecords = _b[0], setTotalRecords = _b[1];
    var _c = useState(0), pageOffset = _c[0], setPageOffset = _c[1];
    var _d = useState(null), selectedPublisherUpdate = _d[0], setSelectedPublisherUpdate = _d[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var updateId = queryParams.get("detail");
        if (updateId) {
            fetchPublisherUpdateRequests(ky, {
                offset: 0,
                limit: PAGE_SIZE,
                status: "SUBMITTED",
            }).then(function (response) {
                var publisher = response.publisherUpdateRequests.find(function (p) { return p.id === updateId; });
                if (publisher) {
                    setSelectedPublisherUpdate(publisher);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var _e = useState(true), filterPaneIsVisible = _e[0], setFilterPaneIsVisible = _e[1];
    var isAnyFilterActive = function () {
        return true;
    };
    var _f = useQuery(["publisherUpdates", pageOffset], function () {
        return fetchPublisherUpdateRequests(ky, {
            offset: pageOffset,
            limit: PAGE_SIZE,
            status: "SUBMITTED",
        });
    }, {
        enabled: isAnyFilterActive(),
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _f.data, isLoading = _f.isLoading, isError = _f.isError, refetch = _f.refetch;
    var publisherUpdatesData = (_a = data === null || data === void 0 ? void 0 : data.publisherUpdateRequests) !== null && _a !== void 0 ? _a : [];
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.name",
            }),
            key: "name",
            cellRenderer: function (item) { return item.publisher.name; },
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedPublisherUpdate(item);
            },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.nameSuffix",
            }),
            key: "nameSuffix",
            cellRenderer: function (item) { return item.publisher.nameSuffix; },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.qualifier",
            }),
            key: "qualifier",
            cellRenderer: function (item) { return item.publisher.qualifier; },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.companyNumber",
            }),
            key: "companyNumber",
            cellRenderer: function (item) {
                return item.publisher.companyNumber;
            },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.birthDate",
            }),
            key: "birthDate",
            cellRenderer: function (item) {
                return item.publisher.birthDate ? formatDate(item.publisher.birthDate) : "N/A";
            },
        },
    ];
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return publisherUpdatesData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = function () { return fetchMoreData; };
    return (React.createElement(RecekPaneSet, null,
        filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(NavComponent, null)))),
        React.createElement(DataListPane, { paneId: "contentPane", paneTitle: formatMessage({
                id: "ui-br-publishers.publisher.update.request.pane.title",
            }), paneSub: formatMessage({ id: "ui-br-publishers.publishers.table.subtitle.records-found" }, { count: publisherUpdatesData.length }), filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane },
            React.createElement(TableComponent, { data: publisherUpdatesData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, filterPaneIsVisible: filterPaneIsVisible, onNeedMoreData: function (offset) { return fetchMoreData(offset); }, toggleFilterPane: toggleFilterPane, source: source, pagingType: "prev-next" })),
        selectedPublisherUpdate ? (React.createElement(Pane, { id: "detailPane", defaultWidth: "35%", paneTitle: "Detail Aktualizace nakladatele", onClose: function () {
                setSelectedPublisherUpdate(null);
                var queryParams = new URLSearchParams(location.search);
                queryParams.delete("detail");
                history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
            }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" }, hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                    return history.push("/brpublishers/publisher-updates/approval/".concat(selectedPublisherUpdate.id));
                }, fullWidth: true }, "Schv\u00E1lit / Zam\u00EDtnout")))); } }, selectedPublisherUpdate ? (React.createElement(PublisherDetail, { publisher: selectedPublisherUpdate.publisher, data: selectedPublisherUpdate === null || selectedPublisherUpdate === void 0 ? void 0 : selectedPublisherUpdate.data })) : (React.createElement("div", null, "No details available")))) : null));
};
export default PublisherUpdatesList;
