import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Redirect } from "react-router";
import React from "react";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import PublisherUpdatesEdit from "./PublisherUpdatesEdit";
import PublisherUpdatesList from "./PublisherUpdatesList";
function PublisherUpdates() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var activeRoles = useActiveRoles(useOkapiKy());
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: PublisherUpdatesList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/approval/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(PublisherUpdatesEdit, null)) : (React.createElement(Redirect, { to: "".concat(path) }));
            } })));
}
export default PublisherUpdates;
