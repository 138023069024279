import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { useOkapiKy } from "@folio/stripes/core";
import {
  hasAtLeastOneRole,
  hasRole,
  useActiveRoles,
} from "./utils/permissionsUtils.tsx";
import { RoleEnum } from "./types/RoleEnum.ts";
import AresUpdates from "./routes/ares-updates/AresUpdates.tsx";
import MyPublisher from "./routes/my-publisher/MyPublisher.tsx";
import PublisherRegistrations from "./routes/publisher-registrations/PublisherRegistration.tsx";
import Publishers from "./routes/publishers/Publishers.tsx";
import PublisherUpdates from "./routes/publisher-updates/PublisherUpdates.tsx";
import Settings from "./settings";
import AdminEnvironmentVariables from "./routes/admin/AdminEnvironmentVariables.tsx";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

const BrPublishers = (props) => {
  const okapiKy = useOkapiKy();
  const activeRoles = useActiveRoles(okapiKy);

  const {
    showSettings,
    match: { path },
  } = props;

  if (showSettings) {
    return <Settings {...props} />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route
          path={`${path}/ares-updates`}
          exact
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <AresUpdates />
            ) : (
              <Redirect to={`${path}`} />
            )
          }
        />
        <Route
          path={`${path}/ares-updates/*`}
          exact
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <AresUpdates />
            ) : (
              <Redirect to={`${path}`} />
            )
          }
        />
        <Route
          path={`${path}/publisher-registrations`}
          exact
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <PublisherRegistrations />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        <Route
          path={`${path}/publisher-updates`}
          exact
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <PublisherUpdates />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        <Route
          path={`${path}/publisher-updates/*`}
          exact
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <PublisherUpdates />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        <Route
          path={`${path}/publishers`}
          exact
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <Publishers />
            ) : (
              <MyPublisher />
            )
          }
        />
        <Route
          path={`${path}/my-publisher`}
          exact
          render={() =>
            hasAtLeastOneRole(activeRoles, [
              RoleEnum.PUBLISHER_ADMIN,
              RoleEnum.PUBLISHER_GUARANTOR,
            ]) ? (
              <MyPublisher />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        <Route
          path={`${path}/my-publisher/*`}
          exact
          render={() =>
            hasAtLeastOneRole(activeRoles, [
              RoleEnum.PUBLISHER_ADMIN,
              RoleEnum.PUBLISHER_GUARANTOR,
            ]) ? (
              <MyPublisher />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        <Route
          path={`${path}/publisher-registrations/*`}
          exact
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <PublisherRegistrations />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        {/* Add a route for nested routes under /publishers */}
        <Route
          path={`${path}/publishers/*`}
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <Publishers />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        <Route
          path={`${path}/admin/environment-variables`}
          render={() =>
            hasRole(activeRoles, RoleEnum.SUPERUSER) ? (
              <AdminEnvironmentVariables />
            ) : (
              <Redirect to={`${path}/publishers`} />
            )
          }
        />
        {/* Default Redirect */}
        <Route
          render={() =>
            hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
              <Redirect to={`${path}/publishers`} />
            ) : (
              <Redirect to={`${path}/my-publisher`} />
            )
          }
        />
      </Switch>
    </QueryClientProvider>
  );
};

export default BrPublishers;
