var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, Row, Col, PaneFooter, AccordionSet, Accordion, Checkbox, Selection, ErrorModal, Loading, ConfirmationModal, } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { fetchOrganizations } from "../../api/organizationService";
import { createUserEditSchema } from "../../utils/validationUtils";
import { assignUserToOrganization, fetchUserById, fetchUserOrganizations, manageUserOrganization, removeUserFromOrganization, updateUser, } from "../../api/usersService";
import RecekTextField from "../../components/RecekTextField";
import { RecekPaneSet } from "../../components/RecekPaneSet";
var UserEdit = function () {
    var _a;
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    var _b = useState(""), selectedOrganization = _b[0], setSelectedOrganization = _b[1];
    var _c = useState([]), removedOrganizations = _c[0], setRemovedOrganizations = _c[1];
    var _d = useState(false), confirmModalOpen = _d[0], setConfirmModalOpen = _d[1];
    var _e = useState(false), errorModalOpen = _e[0], setErrorModalOpen = _e[1];
    var _f = useState(""), errorMessage = _f[0], setErrorMessage = _f[1];
    var _g = useState([]), userOrganizations = _g[0], setUserOrganizations = _g[1];
    var _h = useForm({
        resolver: zodResolver(createUserEditSchema(intl)),
        defaultValues: {
            email: "",
            firstName: "",
            lastName: "",
            shouldChangePassword: true,
            version: 0,
        },
        mode: "onBlur",
    }), handleSubmit = _h.handleSubmit, reset = _h.reset, control = _h.control, errors = _h.formState.errors;
    var _j = useState(true), expandAll = _j[0], setExpandAll = _j[1];
    var _k = useState({
        userOrganizations: true,
        organization: true,
        profile: true,
        role: true,
    }), accordionStatus = _k[0], setAccordionStatus = _k[1];
    var _l = useQuery(["user", id], function () { return fetchUserById(ky, id); }, {
        enabled: !!id,
    }), userData = _l.data, isUserLoading = _l.isLoading;
    var _m = useQuery(["organizations", id], function () { return fetchUserOrganizations(ky, id); }, {
        enabled: !!id,
    }), userOrganizationsData = _m.data, isOrgLoading = _m.isLoading;
    useEffect(function () {
        var _a;
        if (!isUserLoading && !isOrgLoading && userData && userOrganizationsData) {
            reset({
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                shouldChangePassword: (_a = userData.shouldChangePassword) !== null && _a !== void 0 ? _a : true,
                version: userData.version,
            });
            setUserOrganizations(userOrganizationsData);
        }
    }, [isUserLoading, isOrgLoading, userData, userOrganizationsData, reset]);
    var _o = useState({ name: "" }), filters = _o[0], setFilters = _o[1];
    var _p = useQuery(["organizations", filters], function () { return fetchOrganizations(ky, filters); }, {
        refetchOnWindowFocus: false,
        enabled: filters.name !== "",
    }), organizations = _p.data, orgLoading = _p.isLoading, isFetching = _p.isFetching;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _i, userOrganizations_1, org, _a, userOrganizations_2, org, _loop_1, _b, removedOrganizations_1, orgUuid, error_1;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 16, , 17]);
                    return [4 /*yield*/, updateUser(ky, id, data)];
                case 1:
                    _d.sent();
                    _i = 0, userOrganizations_1 = userOrganizations;
                    _d.label = 2;
                case 2:
                    if (!(_i < userOrganizations_1.length)) return [3 /*break*/, 5];
                    org = userOrganizations_1[_i];
                    return [4 /*yield*/, assignUserToOrganization(ky, id, org.organization.id, org.roles)];
                case 3:
                    _d.sent();
                    _d.label = 4;
                case 4:
                    _i++;
                    return [3 /*break*/, 2];
                case 5:
                    _a = 0, userOrganizations_2 = userOrganizations;
                    _d.label = 6;
                case 6:
                    if (!(_a < userOrganizations_2.length)) return [3 /*break*/, 11];
                    org = userOrganizations_2[_a];
                    if (!org.archived) return [3 /*break*/, 8];
                    return [4 /*yield*/, manageUserOrganization(ky, id, org.organization.id, "archive")];
                case 7:
                    _d.sent();
                    return [3 /*break*/, 10];
                case 8: return [4 /*yield*/, manageUserOrganization(ky, id, org.organization.id, "restore")];
                case 9:
                    _d.sent();
                    _d.label = 10;
                case 10:
                    _a++;
                    return [3 /*break*/, 6];
                case 11:
                    _loop_1 = function (orgUuid) {
                        return __generator(this, function (_e) {
                            switch (_e.label) {
                                case 0:
                                    if (!(userOrganizationsData === null || userOrganizationsData === void 0 ? void 0 : userOrganizationsData.some(function (org) { return org.organization.id === orgUuid; }))) return [3 /*break*/, 2];
                                    return [4 /*yield*/, removeUserFromOrganization(ky, id, orgUuid)];
                                case 1:
                                    _e.sent();
                                    _e.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    };
                    _b = 0, removedOrganizations_1 = removedOrganizations;
                    _d.label = 12;
                case 12:
                    if (!(_b < removedOrganizations_1.length)) return [3 /*break*/, 15];
                    orgUuid = removedOrganizations_1[_b];
                    return [5 /*yield**/, _loop_1(orgUuid)];
                case 13:
                    _d.sent();
                    _d.label = 14;
                case 14:
                    _b++;
                    return [3 /*break*/, 12];
                case 15:
                    history.goBack();
                    return [3 /*break*/, 17];
                case 16:
                    error_1 = _d.sent();
                    if (((_c = error_1.response) === null || _c === void 0 ? void 0 : _c.status) === 409) {
                        setErrorMessage("Záznam není možné uložit. Záznam byl mezitím upraven. Prosím, načtěte jej a znovu upravte.");
                        setConfirmModalOpen(true);
                    }
                    else {
                        setErrorMessage("Došlo k chybě při aktualizaci uživatele nebo organizací.");
                        setErrorModalOpen(true);
                    }
                    console.error("Error updating user:", error_1);
                    console.error("Error updating organizations:", error_1);
                    return [3 /*break*/, 17];
                case 17: return [2 /*return*/];
            }
        });
    }); };
    var getArchiveButtonLabel = function (isArchived) { return isArchived
        ? formatMessage({ id: "ui-br-users.organization.label.restore" })
        : formatMessage({ id: "ui-br-users.organization.label.archive" }); };
    var handleArchivesButtonChanged = function (index, archiveOrganization) {
        var updatedOrgs = __spreadArray([], userOrganizations, true);
        updatedOrgs[index] = __assign(__assign({}, updatedOrgs[index]), { archived: archiveOrganization });
        setUserOrganizations(updatedOrgs);
    };
    var handleRoleChange = function (_a) {
        var orgIndex = _a.orgIndex, role = _a.role, isChecked = _a.isChecked;
        setUserOrganizations(function (prevOrgs) {
            return prevOrgs.map(function (org, index) {
                if (index !== orgIndex)
                    return org;
                var currentRoles = org.roles || [];
                if (isChecked && !currentRoles.includes(role)) {
                    return __assign(__assign({}, org), { roles: __spreadArray(__spreadArray([], currentRoles, true), [role], false) });
                }
                if (!isChecked) {
                    return __assign(__assign({}, org), { roles: currentRoles.filter(function (r) { return r !== role; }) });
                }
                return org;
            });
        });
    };
    var expandAllSections = function () {
        setAccordionStatus({
            userOrganizations: true,
            organization: true,
            profile: true,
            role: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            userOrganizations: false,
            organization: false,
            profile: false,
            role: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    if (isUserLoading || isOrgLoading) {
        return React.createElement(Loading, null);
    }
    return (React.createElement(RecekPaneSet, null,
        React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: "\u00DAprava u\u017Eivatele", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit(onSubmit) }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
            React.createElement(ConfirmationModal, { open: confirmModalOpen, heading: "Chyba", message: errorMessage, onConfirm: function () { return window.location.reload(); }, onCancel: function () { return setConfirmModalOpen(false); }, confirmLabel: "Znovu na\u010D\u00EDst", cancelLabel: "Zav\u0159\u00EDt", buttonStyle: "primary" }),
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "U\u017Eivatelsk\u00E9 informace", open: accordionStatus.profile, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { profile: !accordionStatus.profile }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "firstName", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "Jm\u00E9no", required: true }, field, { error: (_b = errors.firstName) === null || _b === void 0 ? void 0 : _b.message })));
                                    } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "lastName", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "P\u0159\u00EDjmen\u00ED", required: true }, field, { error: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message })));
                                    } }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Controller, { control: control, name: "email", render: function (_a) {
                                        var _b;
                                        var field = _a.field;
                                        return (React.createElement(RecekTextField, __assign({ label: "Email", required: true }, field, { error: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message })));
                                    } })))),
                    React.createElement(Accordion, { label: "Organizace u\u017Eivatele", open: accordionStatus.userOrganizations, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { userOrganizations: !accordionStatus.userOrganizations }));
                        } }, userOrganizations.map(function (org, index) {
                        var _a, _b, _c;
                        return (React.createElement(Row, { key: org.organization.id },
                            React.createElement(Col, { xs: 3 },
                                React.createElement("strong", { style: {
                                        textDecoration: org.archived ? "line-through" : "none",
                                    } }, org.organization.name)),
                            React.createElement(Col, { xs: 3 },
                                React.createElement(Checkbox, { label: "Spr\u00E1vce nakladatele", name: "publisherAdmin", checked: (_a = org.roles) === null || _a === void 0 ? void 0 : _a.includes("PublisherAdmin"), onChange: function (e) {
                                        return handleRoleChange({
                                            orgIndex: index,
                                            role: "PublisherAdmin",
                                            isChecked: e.target.checked,
                                        });
                                    } }),
                                React.createElement(Checkbox, { label: "Garant nakladatele", name: "publisherGuarantor", checked: (_b = org.roles) === null || _b === void 0 ? void 0 : _b.includes("PublisherGuarantor"), onChange: function (e) {
                                        return handleRoleChange({
                                            orgIndex: index,
                                            role: "PublisherGuarantor",
                                            isChecked: e.target.checked,
                                        });
                                    } }),
                                React.createElement(Checkbox, { label: "Pracovn\u00EDk agentury", name: "agencyWorker", checked: (_c = org.roles) === null || _c === void 0 ? void 0 : _c.includes("AgencyWorker"), onChange: function (e) {
                                        return handleRoleChange({
                                            orgIndex: index,
                                            role: "AgencyWorker",
                                            isChecked: e.target.checked,
                                        });
                                    } })),
                            React.createElement(Col, { xs: 3 },
                                React.createElement(Button, { buttonStyle: "warning", onClick: function () {
                                        handleArchivesButtonChanged(index, !org.archived);
                                    } }, getArchiveButtonLabel(org.archived))),
                            React.createElement(Col, { xs: 3 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () {
                                        var removedOrgId = userOrganizations[index].organization.id;
                                        if (removedOrgId) {
                                            setRemovedOrganizations(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                                                removedOrgId,
                                            ], false); });
                                        }
                                        setUserOrganizations(userOrganizations.filter(function (_, i) { return i !== index; }));
                                    } }, "Odstranit"))));
                    })),
                    React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { organization: !accordionStatus.organization }));
                        } },
                        React.createElement(Col, { xs: 6 },
                            React.createElement(Selection, { asyncFilter: true, disabled: orgLoading, label: formatMessage({
                                    id: "ui-br-users.label.organization",
                                }), loading: isFetching, name: "organization", onChange: function (value) { return setSelectedOrganization(value); }, onFilter: function (value) {
                                    setFilters({ name: value });
                                }, value: selectedOrganization, dataOptions: organizations
                                    ? ((_a = organizations === null || organizations === void 0 ? void 0 : organizations.organizations) !== null && _a !== void 0 ? _a : []).map(function (org) { return ({
                                        value: org.id,
                                        label: org.name,
                                    }); })
                                    : [] })),
                        React.createElement(Button, { onClick: function () {
                                var selectedOrg = organizations === null || organizations === void 0 ? void 0 : organizations.organizations.find(function (org) { return org.id === selectedOrganization; });
                                if (selectedOrg) {
                                    var exists = userOrganizations.some(function (org) { return org.organization.id === selectedOrg.id; });
                                    if (!exists) {
                                        setUserOrganizations(__spreadArray(__spreadArray([], userOrganizations, true), [
                                            {
                                                user: userData, // Use non-null assertion to guarantee a User
                                                organization: selectedOrg,
                                                roles: [],
                                                archived: false,
                                            },
                                        ], false));
                                    }
                                }
                            } }, "P\u0159idat organizaci")))))));
};
export default UserEdit;
