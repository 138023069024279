var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, Accordion, Checkbox, IconButton, AccordionSet, Icon, ConfirmationModal, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { useIntl } from "react-intl";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import OrganizationDetail from "./OrganizationDetail";
import { archiveOrganization, fetchOrganizationData, fetchOrganizations, restoreOrganization, } from "../../api/organizationService";
import RecekTextField from "../../components/RecekTextField";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { RecekPaneSet } from "../../components/RecekPaneSet";
var PAGE_SIZE = 20;
var OrganizationList = function () {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(useOkapiKy());
    var formatMessage = useIntl().formatMessage;
    var queryClient = useQueryClient();
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = useState(null), modalAction = _c[0], setModalAction = _c[1];
    var _d = useState(null), organizationToProcess = _d[0], setOrganizationToProcess = _d[1];
    var _e = useState(null), message = _e[0], setMessage = _e[1];
    var _f = useState(0), pageOffset = _f[0], setPageOffset = _f[1];
    var _g = useState(""), nameFilter = _g[0], setNameFilter = _g[1];
    var _h = useState(false), archivedFilter = _h[0], setArchivedFilter = _h[1];
    var _j = useState(null), selectedOrganization = _j[0], setSelectedOrganization = _j[1];
    var _k = useState({
        name: "",
        archived: false,
    }), filters = _k[0], setFilters = _k[1];
    var _l = useState(true), filterPaneIsVisible = _l[0], setFilterPaneIsVisible = _l[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var organizationId = queryParams.get("detail");
        setFilters({
            name: queryParams.get("name") || "",
            archived: queryParams.get("archived") === "true",
        });
        setNameFilter(queryParams.get("name") || "");
        setArchivedFilter(queryParams.get("archived") === "true");
        if (organizationId) {
            fetchOrganizationData(organizationId, ky).then(function (organization) {
                if (organization) {
                    setSelectedOrganization(organization);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.name)
            params.set("name", filters.name);
        if (filters.archived !== undefined)
            params.set("archived", filters.archived.toString());
        history.push({ search: params.toString() });
    };
    var handleClearFilters = function () {
        setNameFilter("");
        setArchivedFilter(false);
        setFilters({
            name: "",
            archived: false,
        });
        history.push({ search: "" });
    };
    var isAnyFilterActive = function () {
        return !!filters.name || filters.archived;
    };
    var _m = useQuery(["organizations", filters, pageOffset], function () {
        return fetchOrganizations(ky, __assign(__assign({}, filters), { offset: pageOffset, limit: PAGE_SIZE }));
    }), data = _m.data, isLoading = _m.isLoading, isError = _m.isError, refetch = _m.refetch, isFetched = _m.isFetched;
    var totalRecords = (data !== null && data !== void 0 ? data : { totalRecords: 0 }).totalRecords;
    var organizationData = (_a = data === null || data === void 0 ? void 0 : data.organizations) !== null && _a !== void 0 ? _a : [];
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var openModal = function (organization, action) {
        setOrganizationToProcess(organization);
        setModalAction(action);
        setMessage(null);
        setIsModalOpen(true);
    };
    var handleArchiveClick = function (organization) {
        openModal(organization, "archive");
    };
    var handleRestoreClick = function (organization) {
        openModal(organization, "restore");
    };
    var columns = [
        {
            name: "Jméno",
            key: "name",
            clickable: true,
            onClick: function (item) {
                var _a;
                var currentParams = new URLSearchParams(location.search);
                currentParams.set("detail", (_a = item.id) !== null && _a !== void 0 ? _a : "");
                history.push({
                    search: currentParams.toString(),
                });
                setSelectedOrganization(item);
            },
        },
        {
            name: "Zdroj",
            key: "source",
        },
        {
            name: "Archivováno",
            key: "archived",
            cellRenderer: function (item) { return (item.archived ? "Ano" : "Ne"); }, // Custom renderer for archived
        },
    ];
    var hasRoleAndNotExternal = function (role) {
        return (hasRole(activeRoles, role) && (selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.source) === undefined);
    };
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return organizationData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isFetched; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.successfulMutations = function () { return []; };
    source.fetchMore = function () { return fetchMoreData; };
    return (React.createElement(React.Fragment, null,
        React.createElement(RecekPaneSet, null,
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", id: "menuPane", paneTitle: "Navigace & filtrov\u00E1ni", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters },
                        React.createElement(Icon, { icon: "times-circle-solid" }, formatMessage({
                            id: "ui-br-users.users.button.content.resetAll",
                        }))),
                    React.createElement("form", { onSubmit: function (e) {
                            e.preventDefault();
                            var payload = __assign(__assign({}, filters), { name: nameFilter });
                            setPageOffset(0);
                            setFilters(payload);
                            updateUrlWithFilters(__assign(__assign({}, payload), { archived: archivedFilter }));
                        } },
                        React.createElement(AccordionSet, null,
                            React.createElement(Accordion, { label: formatMessage({
                                    id: "ui-br-users.users.field.label.firstName",
                                }), separator: false },
                                React.createElement(RecekTextField, { placeholder: formatMessage({
                                        id: "ui-br-users.organizationList.textField.placeholder.name",
                                    }), value: nameFilter, onChange: function (e) { return setNameFilter(e.target.value); } })),
                            React.createElement(Accordion, { label: formatMessage({
                                    id: "ui-br-users.users.organization.checkbox.label.archived",
                                }), id: "archived-filter", separator: false },
                                React.createElement(Checkbox, { label: formatMessage({
                                        id: "ui-br-users.users.organization.checkbox.label.archived",
                                    }), checked: archivedFilter, onChange: function () {
                                        setArchivedFilter(!archivedFilter);
                                    } })),
                            React.createElement(Button, { type: "submit", marginBottom0: true }, formatMessage({
                                id: "ui-br-users.button.apply",
                            }))))))),
            React.createElement(Pane, { defaultWidth: "fill", id: "contentPane", paneTitle: formatMessage({
                    id: "ui-br-users.organizationList.pane.title.organizationsList",
                }), paneSub: formatMessage({ id: "ui-br-users.table.subtitle.records-found" }, { count: totalRecords }), actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" }, hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            onToggle();
                            history.push("/brusers/organizations/create");
                        } }, "Nov\u00FD"))));
                }, firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement(TableComponent, { data: organizationData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, onNeedMoreData: function (offset) {
                        fetchMoreData(offset);
                    }, searchTerm: "", filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedOrganization ? (React.createElement(Pane, { id: "detailPane", defaultWidth: "35%", paneTitle: "Detail organizace", onClose: function () {
                    setSelectedOrganization(null);
                    var queryParams = new URLSearchParams(location.search);
                    // Remove only the detail parameter
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() });
                }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                    hasRoleAndNotExternal(RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return history.push("/brusers/organizations/edit/".concat(selectedOrganization.id));
                        }, fullWidth: true }, "Zm\u011Bnit")),
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) &&
                        !(selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.source) &&
                        (selectedOrganization.archived ? (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return handleRestoreClick(selectedOrganization); }, fullWidth: true }, "Obnovit")) : (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return handleArchiveClick(selectedOrganization); }, fullWidth: true }, "Archivovat"))))); } },
                React.createElement(OrganizationDetail, { organization: selectedOrganization }))) : null),
        React.createElement(ConfirmationModal, { open: isModalOpen, heading: message
                ? "Výsledek operace"
                : modalAction === "archive"
                    ? "Potvrďte archivaci"
                    : "Potvrďte obnovení", message: message ||
                (modalAction === "archive"
                    ? "Opravdu chcete archivovat organizaci \"".concat(organizationToProcess === null || organizationToProcess === void 0 ? void 0 : organizationToProcess.name, "\"?")
                    : "Opravdu chcete obnovit organizaci \"".concat(organizationToProcess === null || organizationToProcess === void 0 ? void 0 : organizationToProcess.name, "\"?")), onConfirm: message
                ? function () { return setIsModalOpen(false); }
                : function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!organizationToProcess) return [3 /*break*/, 7];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 6, , 7]);
                                if (!(modalAction === "archive")) return [3 /*break*/, 3];
                                return [4 /*yield*/, archiveOrganization(ky, organizationToProcess.id)];
                            case 2:
                                _a.sent();
                                refetch();
                                setSelectedOrganization(__assign(__assign({}, selectedOrganization), { archived: true }));
                                setMessage("Organizace byla úspěšně archivována.");
                                return [3 /*break*/, 5];
                            case 3: return [4 /*yield*/, restoreOrganization(ky, organizationToProcess.id)];
                            case 4:
                                _a.sent();
                                refetch();
                                setSelectedOrganization(__assign(__assign({}, selectedOrganization), { archived: false }));
                                setMessage("Organizace byla úspěšně obnovena.");
                                _a.label = 5;
                            case 5:
                                queryClient.invalidateQueries("organizations");
                                return [3 /*break*/, 7];
                            case 6:
                                error_1 = _a.sent();
                                console.error(modalAction === "archive"
                                    ? "Error archiving organization:"
                                    : "Error restoring organization:", error_1);
                                setMessage(modalAction === "archive"
                                    ? "Archivace se nezdařila."
                                    : "Obnovení se nezdařilo.");
                                return [3 /*break*/, 7];
                            case 7: return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () { return setIsModalOpen(false); }, confirmLabel: message ? "OK" : modalAction === "archive" ? "Archivovat" : "Obnovit", cancelLabel: !message && "Zrušit", buttonStyle: "primary" })));
};
export default OrganizationList;
