var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Row, Col, Button } from "@folio/stripes/components";
import CustomAccordionSet from "../../../components/CustomAccordionSet";
import { RoleEnum } from "../../../types/RoleEnum";
import { ExtendedInformationAccordion } from "../../../components/ExtendedInformationAccordion";
import { GeneralSection } from "./GeneralSection";
import { AlternativeNamesSection } from "./AlternativeNamesSection";
import { AddressSection } from "./AddressSection";
import { ContactsSection } from "./ContactsSection";
import { GuarantorsSection } from "./GuarantorsSection";
import { CommentsSection } from "./CommentsSection";
import { TimestampsSection } from "./TimestampsSection";
import { WorkSpecSection } from "./WorkSpecSection";
var mergePublisherData = function (publisher, data) { return (__assign(__assign(__assign({}, publisher), data), { mailingAddress: __assign(__assign({}, publisher === null || publisher === void 0 ? void 0 : publisher.mailingAddress), data === null || data === void 0 ? void 0 : data.mailingAddress), mainAddress: __assign(__assign({}, publisher.mainAddress), ((data === null || data === void 0 ? void 0 : data.mainAddress) || {})), contacts: (data === null || data === void 0 ? void 0 : data.contacts) !== undefined ? data.contacts : publisher.contacts, guarantors: (data === null || data === void 0 ? void 0 : data.guarantors) !== undefined ? data.guarantors : publisher.guarantors, alternativeNames: (data === null || data === void 0 ? void 0 : data.alternativeNames) !== undefined
        ? data.alternativeNames
        : publisher.alternativeNames })); };
var PublisherDetail = function (_a) {
    var _b, _c, _d;
    var publisher = _a.publisher, data = _a.data;
    var _e = useState(true), areAccordionsExpanded = _e[0], setAreAccordionsExpanded = _e[1];
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    var registrationIntent = publisher.registrationIntent;
    var mergedPublisher = mergePublisherData(publisher, data);
    var workSpec = mergedPublisher.workSpecificationJsonData
        ? JSON.parse(mergedPublisher.workSpecificationJsonData)
        : undefined;
    var hasMailingAddress = !!(((_b = mergedPublisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) ||
        ((_c = mergedPublisher.mailingAddress) === null || _c === void 0 ? void 0 : _c.postalCode) ||
        ((_d = mergedPublisher.mailingAddress) === null || _d === void 0 ? void 0 : _d.municipality));
    var accordionSections = useMemo(function () {
        var _a;
        var sections = [
            {
                id: "general",
                label: intl.formatMessage({
                    id: "ui-br-publishers.publishers.accordion.label.general",
                }),
                content: React.createElement(GeneralSection, { publisher: mergedPublisher }),
            },
            {
                id: "alternativeNames",
                label: intl.formatMessage({
                    id: "ui-br-publishers.publishers.accordion.label.alternativeNames",
                }),
                content: (React.createElement(AlternativeNamesSection, { alternativeNames: mergedPublisher.alternativeNames })),
            },
            {
                id: "address",
                label: "Adresa",
                content: (React.createElement(AddressSection, { mainAddress: mergedPublisher.mainAddress, mailingAddress: mergedPublisher.mailingAddress, hasMailingAddress: hasMailingAddress, displayAddressPublishConsent: mergedPublisher.legalForm === "NATURAL" })),
            },
            {
                id: "contacts",
                label: intl.formatMessage({
                    id: "ui-br-publishers.publishers.accordion.label.contacts",
                }),
                content: React.createElement(ContactsSection, { contacts: mergedPublisher.contacts }),
            },
            {
                id: "guarantors",
                label: intl.formatMessage({
                    id: "ui-br-publishers.publishers.accordion.label.guarantors",
                }),
                content: React.createElement(GuarantorsSection, { guarantors: mergedPublisher.guarantors }),
            },
            {
                id: "comments",
                label: intl.formatMessage({
                    id: "ui-br-publishers.publishers.accordion.label.comments",
                }),
                requiredRole: RoleEnum.AGENCY_WORKER,
                content: (React.createElement(CommentsSection, { internalComment: mergedPublisher.internalComment })),
            },
            {
                id: "timestamps",
                label: "Časy",
                content: (React.createElement(TimestampsSection, { createTime: mergedPublisher.createTime, activationTime: mergedPublisher.activationTime, dataConfirmationTime: mergedPublisher.dataConfirmationTime, closeTime: mergedPublisher.closeTime, status: mergedPublisher.status })),
            },
        ];
        if (registrationIntent === "ONE_OFF") {
            sections.push({
                id: "workSpec",
                label: formatMessage({
                    id: "stripes-core.publishers.accordion.label.workSpec",
                }),
                content: React.createElement(WorkSpecSection, { workSpec: workSpec }),
            });
        }
        sections.push({
            id: "extendedInformation",
            label: formatMessage({
                id: "ui-br-publishers.accordions.label.extendedInformation",
            }),
            content: React.createElement(ExtendedInformationAccordion, { id: (_a = data === null || data === void 0 ? void 0 : data.id) !== null && _a !== void 0 ? _a : publisher.id }),
        });
        return sections;
    }, [
        intl,
        mergedPublisher,
        hasMailingAddress,
        registrationIntent,
        formatMessage,
        data === null || data === void 0 ? void 0 : data.id,
        publisher.id,
        workSpec,
    ]);
    var handleToggleAllAccordions = function (expanded) {
        setAreAccordionsExpanded(expanded);
    };
    var toggleAccordionsExpansion = function () {
        setAreAccordionsExpanded(function (prev) { return !prev; });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            mergedPublisher.name,
            mergedPublisher.nameSuffix ? ", ".concat(mergedPublisher.nameSuffix) : ""),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                React.createElement(Button, { onClick: toggleAccordionsExpansion, buttonStyle: "default" }, areAccordionsExpanded ? (React.createElement(FormattedMessage, { id: "ui-br-publishers.button.collapse.all" })) : (React.createElement(FormattedMessage, { id: "ui-br-publishers.button.expand.all" }))))),
        React.createElement(CustomAccordionSet, { accordions: accordionSections, expandAll: areAccordionsExpanded, onToggleExpandAll: handleToggleAllAccordions })));
};
export default PublisherDetail;
