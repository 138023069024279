var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, Datepicker, Accordion, Checkbox, IconButton, AccordionSet, Icon, ConfirmationModal, MessageBanner, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import { FormattedMessage, useIntl } from "react-intl";
import { isEqual } from "lodash";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import { fetchPublisherData, searchPublishers, updatePublisherAction, } from "../../api/publisherService";
import PublisherDetail from "./PublisherDetail";
import RecekTextField from "../../components/RecekTextField";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { hasStatus } from "../../utils/appUtils";
import { RecekPaneSet } from "../../components/RecekPaneSet";
import { formatDate } from "../../utils/dateTimeUtil";
import { fetchPublisherUpdateRequests } from "../../api/publisherUpdateRequestService";
var PAGE_SIZE = 20;
var DEFAULT_STATUS = ["ACTIVE"];
var DEFAULT_FILTERS = {
    search: "",
    name: "",
    companyNumber: "",
    startDate: undefined,
    endDate: undefined,
    status: DEFAULT_STATUS,
};
var PublisherList = function () {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var intl = useIntl();
    var formatMessage = intl.formatMessage;
    var isFirstLoad = useRef(true);
    var _b = useState(false), showConfirmModal = _b[0], setShowConfirmModal = _b[1];
    var _c = useState(null), confirmAction = _c[0], setConfirmAction = _c[1];
    var _d = useState(""), modalMessage = _d[0], setModalMessage = _d[1];
    var _e = useState(null), messageBanner = _e[0], setMessageBanner = _e[1];
    var _f = useState(false), messageBannerShow = _f[0], setMessageBannerShow = _f[1];
    var _g = useState(0), pageOffset = _g[0], setPageOffset = _g[1];
    var _h = useState(0), totalRecords = _h[0], setTotalRecords = _h[1];
    var _j = useState(null), confirmMessage = _j[0], setConfirmMessage = _j[1];
    var _k = useState(""), nameFilter = _k[0], setNameFilter = _k[1];
    var _l = useState(""), searchFilter = _l[0], setSearchFilter = _l[1];
    var _m = useState(""), icoFilter = _m[0], setIcoFilter = _m[1];
    var _o = useState(undefined), startDateFilter = _o[0], setStartDateFilter = _o[1];
    var _p = useState(undefined), endDateFilter = _p[0], setEndDateFilter = _p[1];
    var _q = useState(null), selectedPublisher = _q[0], setSelectedPublisher = _q[1];
    var _r = useState(DEFAULT_FILTERS), filters = _r[0], setFilters = _r[1];
    var _s = useState(DEFAULT_STATUS), statusFilters = _s[0], setStatusFilters = _s[1];
    var _t = useState(true), filterPaneIsVisible = _t[0], setFilterPaneIsVisible = _t[1];
    useEffect(function () {
        if (isFirstLoad.current && location.search === "") {
            var params = new URLSearchParams();
            params.set("status", "ACTIVE");
            history.replace({ search: params.toString() });
        }
        isFirstLoad.current = false;
        var queryParams = new URLSearchParams(location.search);
        var publisherId = queryParams.get("detail");
        var search = queryParams.get("search") || "";
        var name = queryParams.get("name") || "";
        var companyNumber = queryParams.get("companyNumber") || "";
        var startDate = queryParams.get("startDate") || undefined;
        var endDate = queryParams.get("endDate") || undefined;
        var status = queryParams.has("status")
            ? queryParams.getAll("status")
            : [];
        setFilters({
            search: search,
            name: name,
            companyNumber: companyNumber,
            startDate: startDate,
            endDate: endDate,
            status: status,
        });
        setStatusFilters(status);
        setSearchFilter(search);
        setNameFilter(name);
        setIcoFilter(companyNumber);
        setStartDateFilter(startDate);
        setEndDateFilter(endDate);
        if (publisherId) {
            fetchPublisherData(publisherId, ky).then(function (publisher) {
                if (publisher) {
                    setSelectedPublisher(publisher);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.search)
            params.set("search", filters.search);
        if (filters.name)
            params.set("name", filters.name);
        if (filters.companyNumber)
            params.set("companyNumber", filters.companyNumber);
        if (filters.startDate)
            params.set("startDate", filters.startDate);
        if (filters.endDate)
            params.set("endDate", filters.endDate);
        filters.status.forEach(function (status) { return params.append("status", status); });
        history.push({ search: params.toString() });
    };
    var handleStatusChange = function (status) {
        setStatusFilters(function (prevStatuses) {
            var newStatus = prevStatuses.includes(status)
                ? prevStatuses.filter(function (s) { return s !== status; })
                : __spreadArray(__spreadArray([], prevStatuses, true), [status], false);
            return newStatus;
        });
    };
    var handleClearFilters = function () {
        setSearchFilter("");
        setNameFilter("");
        setIcoFilter("");
        setStartDateFilter(undefined);
        setEndDateFilter(undefined);
        setFilters(DEFAULT_FILTERS);
        setStatusFilters(DEFAULT_STATUS);
        history.push({ search: "?status=ACTIVE" });
    };
    var hasDefaultFilters = function () { return isEqual(filters, DEFAULT_FILTERS); };
    var _u = useQuery(["publishers", filters, pageOffset], function () {
        return searchPublishers(ky, __assign(__assign({}, filters), { offset: pageOffset, limit: PAGE_SIZE }));
    }, {
        onSuccess: function (data) {
            setTotalRecords(data.totalRecords);
        },
    }), data = _u.data, isLoading = _u.isLoading, isError = _u.isError, refetch = _u.refetch;
    var publishersData = (_a = data === null || data === void 0 ? void 0 : data.publishers) !== null && _a !== void 0 ? _a : [];
    var fetchMoreData = function (offset) {
        setPageOffset(offset);
        refetch();
    };
    var onChangeButton = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchPublisherUpdateRequests(ky, {
                            status: "SUBMITTED",
                            publisherId: selectedPublisher.id,
                        })];
                case 1:
                    data_1 = _a.sent();
                    if (data_1.totalRecords > 0) {
                        setMessageBanner({
                            type: "warning",
                            message: formatMessage({
                                id: "ui-br-publishers.publishers.update.request.exists.message",
                            }),
                        });
                        setMessageBannerShow(true);
                    }
                    else {
                        history.push("/brpublishers/publishers/edit/".concat(selectedPublisher.id));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching publisher update requests:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.name",
            }),
            key: "name",
            clickable: true,
            onClick: function (item) {
                // Append filters to the URL when navigating to detail
                var currentParams = new URLSearchParams(location.search);
                // Remove any existing 'detail' query parameter
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedPublisher(item);
            },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.nameSuffix",
            }),
            key: "nameSuffix",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.qualifier",
            }),
            key: "qualifier",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.companyNumber",
            }),
            key: "companyNumber",
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.birthDate",
            }),
            key: "birthDate",
            cellRenderer: function (item) {
                return item.birthDate ? formatDate(item.birthDate) : "";
            },
        },
        {
            name: formatMessage({
                id: "ui-br-publishers.publishers.table.columns.status",
            }),
            key: "status",
            formatter: function (item) {
                return formatMessage({
                    id: "ui-br-publishers.status.".concat(item.status.toLowerCase()),
                });
            },
        },
    ];
    var refetchPublisher = function () { return __awaiter(void 0, void 0, void 0, function () {
        var publisherId, publisher, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    publisherId = new URLSearchParams(location.search).get("detail");
                    if (!publisherId)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchPublisherData(publisherId, ky)];
                case 2:
                    publisher = _a.sent();
                    if (publisher) {
                        setSelectedPublisher(publisher);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error("Error fetching publisher data:", error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCloseClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedPublisher) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updatePublisherAction(ky, selectedPublisher.id, "closing")];
                case 2:
                    _a.sent();
                    refetch();
                    setSelectedPublisher(null);
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error("Error closing publisher:", error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleConfirmClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedPublisher) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updatePublisherAction(ky, selectedPublisher.id, "confirmData")];
                case 2:
                    _a.sent();
                    refetch();
                    refetchPublisher();
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    console.error("Error confirming publisher:", error_4);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var openConfirmModal = function (action, message) {
        setConfirmAction(function () { return action; });
        setModalMessage(message);
        setConfirmMessage(null);
        setShowConfirmModal(true);
    };
    var source = new StripesConnectedSource({}, ky);
    source.update = function () { };
    source.records = function () { return publishersData; };
    source.resultCount = function () { return totalRecords; };
    source.totalCount = function () { return totalRecords; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return !isLoading; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.successfulMutations = function () { return []; };
    source.fetchMore = function () { return fetchMoreData; };
    return (React.createElement(React.Fragment, null,
        React.createElement(RecekPaneSet, null,
            filterPaneIsVisible && (React.createElement(Pane, { id: "menuPane", defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1ni", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: hasDefaultFilters(), marginBottom0: true, onClick: handleClearFilters },
                        React.createElement(Icon, { icon: "times-circle-solid" }, "Resetovat v\u0161echno")),
                    React.createElement("form", { onSubmit: function (e) {
                            e.preventDefault();
                            var payload = __assign(__assign({}, filters), { search: searchFilter, name: nameFilter, companyNumber: icoFilter, startDate: startDateFilter, endDate: endDateFilter, status: statusFilters });
                            setPageOffset(0);
                            setFilters(payload);
                            updateUrlWithFilters(payload);
                        } },
                        React.createElement(AccordionSet, null,
                            React.createElement(Accordion, { label: React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.accordion.title.search" }), separator: false },
                                React.createElement(RecekTextField, { placeholder: formatMessage({
                                        id: "ui-br-publishers.publishers.filter.field.placeholder.search",
                                    }), value: searchFilter, onChange: function (e) { return setSearchFilter(e.target.value); } })),
                            React.createElement(Accordion, { label: React.createElement(FormattedMessage, { id: "ui-br-publishers.publishers.accordion.title.name.company.number" }), separator: false },
                                React.createElement(RecekTextField, { placeholder: formatMessage({
                                        id: "ui-br-publishers.publishers.filter.field.placeholder.name",
                                    }), value: nameFilter, onChange: function (e) { return setNameFilter(e.target.value); } }),
                                React.createElement(RecekTextField, { placeholder: formatMessage({
                                        id: "ui-br-publishers.publishers.filter.field.placeholder.company.number",
                                    }), value: icoFilter, onChange: function (e) { return setIcoFilter(e.target.value); } })),
                            React.createElement(Accordion, { label: formatMessage({
                                    id: "ui-br-publishers.publishers.accordion.title.birthdate",
                                }), separator: false },
                                React.createElement("div", null,
                                    React.createElement(Datepicker, { id: "start-date", value: startDateFilter || "", onChange: function (e, formatted, dateString) {
                                            return setStartDateFilter(dateString || undefined);
                                        }, fullWidth: true, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY", label: "".concat(formatMessage({
                                            id: "ui-br-publishers.publishers.filter.field.label.from",
                                        }), ":") })),
                                React.createElement("div", null,
                                    React.createElement(Datepicker, { id: "end-date", value: endDateFilter || "", onChange: function (e, formatted, dateString) {
                                            return setEndDateFilter(dateString || undefined);
                                        }, fullWidth: true, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY", label: "".concat(formatMessage({
                                            id: "ui-br-publishers.publishers.filter.field.label.to",
                                        }), ":") }))),
                            React.createElement(Accordion, { label: formatMessage({
                                    id: "ui-br-publishers.publishers.accordion.title.status",
                                }), id: "status-filter", separator: false }, [
                                "ACTIVE",
                                "REQUEST",
                                "CLOSING",
                                "CLOSED",
                                "REJECTED",
                            ].map(function (status) { return (React.createElement(Checkbox, { key: status, label: React.createElement(FormattedMessage, { id: "ui-br-publishers.status.".concat(status.toLowerCase()) }), checked: statusFilters.includes(status), onChange: function () { return handleStatusChange(status); } })); })),
                            React.createElement(Button, { type: "submit", marginBottom0: true },
                                React.createElement(FormattedMessage, { id: "ui-br-publishers.button.apply" }))))))),
            React.createElement(Pane, { id: "contentPane", defaultWidth: "fill", paneTitle: React.createElement(React.Fragment, null,
                    React.createElement("span", null, "Seznam nakladatel\u016F")), paneSub: formatMessage({ id: "ui-br-publishers.publishers.table.subtitle.records-found" }, { count: totalRecords }), actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" }, hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            onToggle();
                            history.push("/brpublishers/publishers/create");
                        } }, "Nov\u00FD"))));
                }, firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement(TableComponent, { data: publishersData, columns: columns, pageSize: PAGE_SIZE, totalCount: totalRecords, onNeedMoreData: function (offset) {
                        fetchMoreData(offset);
                    }, searchTerm: nameFilter, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source, pagingType: "prev-next" })),
            selectedPublisher ? (React.createElement(Pane, { id: "detailPane", defaultWidth: "35%", paneTitle: "Detail nakladatele", onClose: function () {
                    setSelectedPublisher(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() });
                }, dismissible: true, actionMenu: function () { return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return openConfirmModal(handleConfirmClick, "Opravdu chcete potvrdit platnost údajů?");
                        }, fullWidth: true }, "Potvrdit platnost \u00FAdaj\u016F")),
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return onChangeButton(); }, fullWidth: true },
                        React.createElement(FormattedMessage, { id: "stripes-core.button.edit" }))),
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) &&
                        hasStatus("ACTIVE", selectedPublisher) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return openConfirmModal(handleCloseClick, "Opravdu chcete označit tohoto nakladatele jako 'V likvidaci'?");
                        }, fullWidth: true }, "V likvidaci")),
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) &&
                        hasStatus(["ACTIVE", "CLOSING"], selectedPublisher) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return history.push("/brpublishers/publishers/close/".concat(selectedPublisher.id), { from: window.location.href });
                        }, fullWidth: true }, "Ukon\u010Dit")),
                    hasRole(activeRoles, RoleEnum.AGENCY_WORKER) &&
                        hasStatus(["CLOSED", "CLOSING"], selectedPublisher) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                            return history.push("/brpublishers/publishers/restore/".concat(selectedPublisher.id));
                        }, fullWidth: true }, "Obnovit")))); } },
                messageBannerShow && (React.createElement(MessageBanner, { dismissible: true, autoFocusDismissButton: true, onExit: function () { return setMessageBannerShow(false); }, show: messageBannerShow, type: messageBanner.type }, messageBanner.message)),
                React.createElement(PublisherDetail, { publisher: selectedPublisher }))) : null),
        showConfirmModal && (React.createElement(ConfirmationModal, { open: showConfirmModal, heading: confirmMessage ? "Výsledek akce" : "Potvrzení akce", message: confirmMessage || modalMessage, onConfirm: confirmMessage
                ? function () { return setShowConfirmModal(false); }
                : function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!confirmAction) return [3 /*break*/, 4];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, confirmAction()];
                            case 2:
                                _a.sent();
                                setConfirmMessage("Akce byla úspěšně dokončena.");
                                return [3 /*break*/, 4];
                            case 3:
                                error_5 = _a.sent();
                                console.error("Error performing action:", error_5);
                                setConfirmMessage("Akce se nezdařila.");
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () { return setShowConfirmModal(false); }, confirmLabel: confirmMessage ? "OK" : "Ano", cancelLabel: !confirmMessage && "Ne", buttonStyle: "primary" }))));
};
export default PublisherList;
