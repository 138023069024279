export var ErrorType;
(function (ErrorType) {
    ErrorType["INTERNAL"] = "-1";
    ErrorType["FOLIO_EXTERNAL_OR_UNDEFINED"] = "-2";
    ErrorType["EXTERNAL_OR_UNDEFINED"] = "-3";
    ErrorType["UNKNOWN"] = "-4";
    ErrorType["AUTHENTICATION_EMAIL_NOT_VALID"] = "-5";
    ErrorType["AUTHENTICATION_PASSWORD_NOT_VALID"] = "-6";
    ErrorType["AUTHENTICATION_USER_DOES_NOT_BELONG_TO_ORGANIZATION"] = "-7";
    ErrorType["VALIDATION_ERROR"] = "-8";
})(ErrorType || (ErrorType = {}));
