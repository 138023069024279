import React from "react";
import { Row, Col } from "@folio/stripes/components";
import { useIntl } from "react-intl";
import RenderField from "../../../components/RenderField";
export var CommentsSection = function (_a) {
    var internalComment = _a.internalComment;
    var formatMessage = useIntl().formatMessage;
    return (React.createElement(Row, null,
        React.createElement(Col, { xs: 12 },
            React.createElement(RenderField, { label: formatMessage({
                    id: "ui-br-publishers.publishers.comments.textArea.label.internalComment",
                }), value: internalComment }))));
};
