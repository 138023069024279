export var altNamesTranslations = {
    AgencyWorker: { id: "ui-br-users.role.agencyWorker" },
    PublisherAdmin: { id: "ui-br-users.role.publisherAdmin" },
    PublisherGuarantor: { id: "ui-br-users.role.publisherGuarantor" },
    Superuser: {
        id: "ui-br-users.role.superuser",
    },
};
export var getRolesTranslations = function (intl, key) {
    var translation = altNamesTranslations[key];
    if (!translation)
        return key;
    return intl.formatMessage(translation);
};
